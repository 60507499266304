export const UserAction = {
    RESTART_SRM_PROCESS: 'RestartSrmProcess',
    DROPDOWN_FILTER: 'DropdownFilter',
    DEPLOYMENT_FLYOUT: 'DeploymentFlyout',
    EXTERNAL_LINK: 'ExternalLink',
    STAGE_TOGGLE: 'StageToggle',
    SEARCH_BOX: 'SearchBox',
    USER_PROFILE: 'UserProfile',
};

export const ActionResult = {
    SUCCESS: 'Success',
    FAILED: 'Failed',
};

export const FilterTypes = {
    DEPLOYMENT_TYPE: 'DeploymentType',
    SERVICE_NAME: 'Service',
    ENVIRONMENT_NAME: 'Environment',
};

export const ToggleMode = {
    EXPAND: 'Expand',
    COLLAPSE: 'Collapse',
    TOGGLE: 'Toggle',
};

export const SearchValues = {
    ARTIFACT: 'Artifact',
};
