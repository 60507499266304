import { User } from 'oidc-client';
import { Dispatch, SetStateAction, createContext } from 'react';
import { Guid } from '../../Common/models/Guid';
import { UserProfile } from '../../Common/models/UserProfile';

export interface IAuthContext {
    login?: Function;
    logout?: Function;
    user?: User;
    isUserAuthorized?: boolean;
    authorizedServiceIds?: Guid[];
    userProfile?: UserProfile;
}

export interface IAuthContextView {
    authContext: IAuthContext;
    setDbUpdated?: Dispatch<SetStateAction<boolean>>;
    isUpdated?: boolean;
}

export const AuthContext = createContext<IAuthContextView>({
    authContext: {},
});
