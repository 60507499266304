import {
    BaseButton,
    Button,
    DefaultButton,
    FontSizes,
    IButtonStyles,
} from '@fluentui/react';
import React from 'react';

interface IDefaultHubButtonProps {
    buttonText?: string;
    icon?: string;
    disabled?: boolean;
    borderColor?: string;
    fontColor?: string;
    onClick?: React.MouseEventHandler<
        | MouseEvent
        | HTMLDivElement
        | HTMLAnchorElement
        | HTMLButtonElement
        | BaseButton
        | Button
        | HTMLSpanElement
    >;
}

export const DefaultHubButton: React.FC<IDefaultHubButtonProps> = (
    props: IDefaultHubButtonProps
) => {
    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            maxWidth: '180px',
            borderRadius: '5px',
            fontSize: FontSizes.size14,
            paddingLeft: '5px',
            paddingRight: '5px',
            marginBottom: '10px',
            borderWidth: '2px',
            color: props.fontColor,
            borderColor: props.borderColor,
        },
    };

    return (
        <div className={'DefaultHubButton-root'}>
            <DefaultButton
                text={props.buttonText}
                iconProps={{ iconName: props.icon }}
                onClick={props.onClick}
                styles={buttonStyles}
                disabled={props.disabled !== undefined ? props.disabled : false}
            />
        </div>
    );
};
