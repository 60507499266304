import React, { useContext, useEffect, useState } from 'react';
import { IStackTokens, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { CancelToken } from 'axios';
import { useCallback } from 'react';
import { usePromise } from '../../../Common/hooks/usePromise';
import { ErrorPage } from '../../../HubLayout/components/ErrorPage';
import { PageTitle } from '../../../HubLayout/components/PageLayout/PageTitle';
import { getBuildInstanceDetails } from '../../api/AGSSApi';
import { useParams } from 'react-router-dom';
import { useAsState } from '../../../Common/hooks/useAsState';
import { BuildDetailsRouteParams } from '../../../Common/util/RouteUtils';
import { ServiceType } from '../../../ABSM/models/ServiceType';
import { PageBreadcrumbTrail } from '../../../HubLayout/components/PageLayout/PageBreadcrumbTrail';
import { LinkItem } from '../../../HubLayout/models/LinkItem';
import { buildADOBreadcrumbTrail } from '../../../HubLayout/util/PageLayoutUtil';
import { ArtifactList } from '../Artifact/ArtifactList';
import { BuildInstanceDetails } from './Display/BuildInstanceDetails';
import { PageContent } from '../../../HubLayout/components/PageLayout/PageContent';
import { BuildMetrics } from './Display/BuildMetrics';
import {
    sectionStyle,
    spinnerStyles,
    stackStyles,
} from '../../util/StyleUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { emitPageLoadMetric } from '../../../Common/util/metricsUtil';
import { BackButton } from '../../../HubLayout/components/Utilities/BackButton';
import { useIsMobile } from '../../../Common/hooks/useIsMobile';
import { MobileBuildInstanceDetailsPage } from './Display/Mobile/MobileBuildInstanceDetailsPage';

export interface IBuildInstanceDetailsProps {
    serviceType?: ServiceType;
}

export const BuildInstanceDetailsPage: React.FC<IBuildInstanceDetailsProps> = (
    props: IBuildInstanceDetailsProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const routeParams = useParams<BuildDetailsRouteParams>();
    const params = useAsState<BuildDetailsRouteParams>(routeParams); // fixes timing bug on routeParam change
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const isMobile = useIsMobile();

    const getData = useCallback(
        async (cancelToken?: CancelToken) => {
            if (params.buildInstanceCorrelationId) {
                return await getBuildInstanceDetails(
                    params.buildInstanceCorrelationId,
                    cancelToken
                );
            }
        },
        [params.buildInstanceCorrelationId]
    );

    // Tokens definition
    const stackTokens: IStackTokens = { childrenGap: 20 };
    const sectionStackTokens: IStackTokens = { childrenGap: 10 };

    const [data, error, isLoaded] = usePromise(getData, true);

    //ado breadcrumb trail
    var breadcrumbItems: LinkItem[] = [];
    if (isLoaded && data) {
        breadcrumbItems = buildADOBreadcrumbTrail(
            data.accountName ? data.accountName : '',
            data.projectName ? data.projectName : '',
            data.repoName ? data.repoName : ''
        );
    }

    // metric to measure inital page load time
    useEffect(() => {
        var emittedSuccessfully = emitPageLoadMetric(
            'BuildInstanceDetails',
            process.env.REACT_APP_BUILD_VERSION,
            isFirstLoad
        );

        setIsFirstLoad(emittedSuccessfully);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return error ? (
        <ErrorPage error={error} />
    ) : (
        <div className="BuildInstanceDetailsPage-root">
            {isMobile ? (
                <div>
                    <MobileBuildInstanceDetailsPage
                        data={data}
                        isLoaded={isLoaded}
                    />

                    {!isLoaded && (
                        <div>
                            <Spinner
                                styles={spinnerStyles}
                                size={SpinnerSize.large}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <PageTitle
                        title={
                            'Build Instance Details' +
                            (data?.buildNumber
                                ? ' (Build Number: ' + data?.buildNumber + ')'
                                : '')
                        }
                        subTitle="Build details, artifacts list, and status log"
                    />
                    {isLoaded && (
                        <Stack tokens={sectionStackTokens}>
                            <Stack
                                horizontal
                                tokens={sectionStackTokens}
                                className={stackStyles.centeredStack}
                            >
                                <BackButton />
                                <PageBreadcrumbTrail
                                    inputItems={breadcrumbItems}
                                    label="ADO Repository"
                                />
                            </Stack>
                            <PageContent>
                                <Stack tokens={stackTokens}>
                                    <BuildMetrics data={data} />
                                    <BuildInstanceDetails build={data} />
                                    <Stack
                                        horizontal
                                        tokens={sectionStackTokens}
                                        className={stackStyles.paddedStack}
                                        style={sectionStyle(theme)}
                                    >
                                        <ArtifactList
                                            list={data?.artifacts || []}
                                            isLoaded={isLoaded}
                                            showTitle={true}
                                            backgroundColor={
                                                sectionStyle(theme)
                                                    .backgroundColor
                                            }
                                        />
                                    </Stack>
                                </Stack>
                            </PageContent>
                        </Stack>
                    )}
                    {!isLoaded && (
                        <div>
                            <Spinner
                                styles={spinnerStyles}
                                size={SpinnerSize.large}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
