import { useState } from 'react';
import { AxiosInstance } from 'axios';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import {
    isRedirectUrl,
    validUser,
    setAxiosHeader,
} from '../../HubLayout/util/authUtils';
import { TokenRefreshError } from '../errors/TokenRefreshError';
import { useHistory } from 'react-router-dom';

/**
 * Custom hook to handle authentication for a given scope
 */
export const useAuthentication = (
    config: UserManagerSettings,
    axiosList: AxiosInstance[],
    usePopup?: boolean
): [User | undefined, Error | undefined, Function, Function] => {
    const [oidcUserManager, setOidcUserManager] = useState<UserManager>();
    const [oidcUser, setOidcUser] = useState<User>();
    const [authError, setAuthError] = useState<Error>();
    const history = useHistory();

    const initUserManager = () => {
        if (oidcUserManager) {
            return oidcUserManager;
        } else {
            const manager = new UserManager(config);

            manager.events.addUserLoaded((user) => {
                saveUser(user);
            });
            manager.events.addSilentRenewError((error: Error) => {
                saveError(new TokenRefreshError());
            });
            manager.clearStaleState();

            setOidcUserManager(manager);
            return manager;
        }
    };
    const userManager = initUserManager();

    const getUser = async (signinArgs?: any) => {
        await userManager.clearStaleState();

        try {
            // Get the user if they're already in the cache
            const user = await userManager.getUser();
            if (validUser(user)) {
                saveUser(user);
            } else {
                // Handle redirect if we're returning from signin redirect
                //   Multiple redirect logins can happen after each other - only the pending one will be caught here,
                //   because prior logins already have a valid user in the cache and won't reach this check.
                if (isRedirectUrl(window.location)) {
                    await handleRedirect();
                }
                // Else we need to sign in
                else {
                    await signIn(user, signinArgs);
                }
            }
        } catch (e) {
            saveError(e);
        }
    };

    const handleRedirect = async () => {
        // POST to <server>/connect/token
        var user = await userManager.signinCallback();
        cleanUrl();
        history.replace(user.state); // go to the page we redirected from
    };

    const signIn = async (currentUser: User | null, signinArgs?: any) => {
        await userManager.clearStaleState();

        // Try using refresh token to sign in silently
        if (currentUser?.refresh_token) {
            try {
                const user = await userManager.signinSilent();
                if (user) {
                    return;
                }
            } catch {
                // Sign in silent failed likely due to invalid refresh token, proceed to interactive sign in
            }
        }

        // Else start signin process
        if (usePopup) {
            await userManager.signinPopup(signinArgs);
        } else {
            // When redirecting, pass in current location so we can go back to it
            signinArgs = { ...signinArgs, state: history.location.pathname };
            await userManager.signinRedirect(signinArgs);
        }
    };

    const saveUser = (user: User | null) => {
        if (user) {
            axiosList.forEach((axios) => {
                setAxiosHeader(axios, user);
            });
            setAuthError(undefined);
            setOidcUser(user);
        } else {
            saveError(new Error('User could not be authenticated.'));
        }
    };

    const saveError = (error: Error) => {
        setAuthError(error);
        setOidcUser(undefined);
        if (window.location.href.includes('?error')) {
            cleanUrl();
        }
    };

    const resetUser = async () => {
        await userManager.removeUser();
        setOidcUser(undefined);
        setAuthError(undefined);
    };

    const cleanUrl = () => {
        // Clean up auth params (eg ?code=...) from URL
        window.history.replaceState(null, document.title, '/#');
    };

    return [oidcUser, authError, getUser, resetUser];
};
