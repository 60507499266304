import { Stack } from '@fluentui/react';
import React, { useContext } from 'react';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import {
    getStatusIconProperties,
    CompletionStatusTracker,
} from '../../../../models/Timeline';
import { stackStyles } from '../../../../util/StyleUtils';
import { CompletionIndicatorIcon } from './CompletionIndicatorIcon';
import { StatusTracker } from './StatusTracker';

interface ICompletionStatusDisplayProps {
    completionIndicator: string;
    completionStatusTracker: CompletionStatusTracker | undefined;
    addPadding?: boolean;
}

export const CompletionStatusDisplay: React.FC<ICompletionStatusDisplayProps> =
    (props: ICompletionStatusDisplayProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);

        return (
            <div className="CompletionStatusDisplay-root">
                <Stack
                    horizontal
                    className={
                        props.addPadding
                            ? stackStyles.paddedAndCenteredStack
                            : stackStyles.centeredStack
                    }
                >
                    <CompletionIndicatorIcon
                        completionIndicator={props.completionIndicator}
                        theme={theme}
                        showText={true}
                    />
                    <StatusTracker
                        completionStatusTracker={props.completionStatusTracker}
                        textColor={
                            getStatusIconProperties(
                                props.completionIndicator,
                                theme
                            ).iconColor || ''
                        }
                    />
                </Stack>
            </div>
        );
    };
