import React, { useContext } from 'react';
import { Artifact } from '../../../models/Artifact';
import {
    CommandButton,
    DefaultEffects,
    IconButton,
    IStackStyles,
    mergeStyleSets,
    Stack,
    Text,
} from '@fluentui/react';
import { CompletionStatusDisplay } from '../../MultistageReleaseData/Display/Status/CompletionStatusDisplay';
import { Ellipsis } from '../../../../HubLayout/components/Utilities/Ellipsis';
import { ArtifactMetrics } from '../ArtifactMetrics';
import { DataLabel } from '../../../../HubLayout/components/Utilities/DataLabel';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { MobileReplicationInfo } from './MobileReplicationInfo';
import { dateLocaleStringWithTimeZone } from '../../../../Common/util/DateUtils';
import { ReplicateFullBuildButton } from '../ReplicateFullBuildButton';
import { ValidateArtifactButton } from '../ValidateArtifactButton';
import { DownloadManifestButton } from '../DownloadManifestButton';

interface IMobileArtifactsDetailsPageProps {
    artifact: Artifact | undefined;
    addReplication: () => Promise<void>;
}

export const MobileArtifactsDetailsPage: React.FC<IMobileArtifactsDetailsPageProps> =
    (props: IMobileArtifactsDetailsPageProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);
        const stackTokens = { childrenGap: 10 };

        const statusEllipsisStackStyles: IStackStyles = {
            root: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 8px',
            },
        };

        const styles = mergeStyleSets({
            metadataPanel: {
                backgroundColor:
                    themeContext.themeName === 'dark'
                        ? theme.palette.neutralLighter
                        : theme.palette.themeLighterAlt,
                boxShadow: DefaultEffects.elevation4,
                padding: 5,
                marginBottom: 10,
                color: theme.palette.neutralSecondary,
                selectors: {
                    table: {
                        width: '100%',
                    },
                    td: {
                        padding: 4,
                    },
                },
            },
            label: {
                width: 120,
                fontWeight: 600,
                color: theme.palette.neutralPrimary,
            },
        });

        const renderValidateArtifactButton = (
            item: any,
            dismissMenu: (ev?: any, dismissAll?: boolean) => void
        ) => {
            return <ValidateArtifactButton artifact={item} isMenuItem={true} />;
        };

        const renderReplicateBuildButton = (
            item: any,
            dismissMenu: (ev?: any, dismissAll?: boolean) => void
        ) => {
            return (
                <ReplicateFullBuildButton
                    addReplication={props.addReplication}
                    artifact={item}
                    isMenuItem={true}
                />
            );
        };

        return (
            <div className="MobileArtifactsDetailsPage-root">
                <Stack
                    horizontal
                    tokens={stackTokens}
                    styles={statusEllipsisStackStyles}
                >
                    {/* Status */}
                    {props.artifact && props.artifact.currentReplication && (
                        <CompletionStatusDisplay
                            completionIndicator={
                                props.artifact.completionIndicator
                            }
                            completionStatusTracker={
                                props.artifact.currentReplication
                                    .completionStatusTracker
                            }
                        />
                    )}

                    {/* Actions */}
                    <Stack
                        horizontal
                        tokens={stackTokens}
                        styles={{
                            root: {
                                display: 'flex',
                                alignItems: 'center',
                            },
                        }}
                    >
                        <DownloadManifestButton
                            artifact={props.artifact}
                            isMenuItem={true}
                        />
                        <Ellipsis
                            menuItems={[
                                {
                                    key: 'activateArtifact',
                                    text: 'Activate Artifact',
                                    iconProps: { iconName: 'ReceiptCheck' },
                                    onRender: renderValidateArtifactButton,
                                },
                                {
                                    key: 'replicateBuild',
                                    text: 'Replicate Build',
                                    iconProps: { iconName: 'Sync' },
                                    onRender: renderReplicateBuildButton,
                                },
                            ]}
                        />
                    </Stack>
                </Stack>

                {/* Document Cards */}
                {props.artifact && (
                    <div
                        style={{
                            margin: '12px 8px',
                        }}
                    >
                        <ArtifactMetrics data={props.artifact} />
                    </div>
                )}

                {/* Metadata table */}
                {props.artifact && (
                    <div className={styles.metadataPanel}>
                        <DataLabel
                            label={'Artifact Id'}
                            value={props.artifact.artifactId}
                        />
                        <DataLabel
                            label={'Build Number'}
                            value={props.artifact.buildNumber}
                        />
                        <DataLabel
                            label={'Release'}
                            value={props.artifact.releaseName}
                        />
                        <DataLabel
                            label={'Created On'}
                            value={dateLocaleStringWithTimeZone(
                                props.artifact.createdOn
                            )}
                        />
                    </div>
                )}

                {/* Replication List */}
                <Stack
                    tokens={stackTokens}
                    style={{
                        border: 'solid 1px ' + theme.palette.neutralLighterAlt,
                        background: theme.palette.neutralLighterAlt,
                        padding: '10px',
                        margin: '10px 0px',
                    }}
                >
                    <Text variant="medium">Replications</Text>
                    {props.artifact?.replications.map((replication) => {
                        return (
                            <div>
                                <div
                                    style={{
                                        border:
                                            'solid 1px ' +
                                            theme.palette.neutralLight,
                                    }}
                                >
                                    <MobileReplicationInfo
                                        replication={replication}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Stack>
            </div>
        );
    };
