import { createTheme } from '@fluentui/react';

export const defaultTheme = createTheme({
    // palette: {
    //  //This space intentionally left blank.
    //  //This will fall back to using the default light theme colors.
    // },
    semanticColors: {
        infoBackground: '#c7e0f4',
        bodyBackground: '#ffffff',
        bodyDivider: '#f3f2f1',
    },
});
