import { LinkItem } from '../models/LinkItem';
import { Service } from '../../ABSM/models/Service';
import { ServiceType } from '../../ABSM/models/ServiceType';
import { PageType } from '../models/Page';
import { getAdoRepoUrl } from '../../AGSS/util/BuildUtils';
import {
    getAdoAccountUrl,
    getAdoProjectUrl,
} from '../../Common/util/RouteUtils';

export const buildADOBreadcrumbTrail = (
    account: string,
    project: string,
    repoName: string
): LinkItem[] => {
    const items: LinkItem[] = [];
    //account
    items.push({
        text: account,
        href: getAdoAccountUrl(account),
        iconName: PageType.Account,
    });
    //project
    items.push({
        text: project,
        href: getAdoProjectUrl(account, project, ''),
        iconName: PageType.Project,
    });
    if (repoName) {
        //repoName
        items.push({
            text: repoName,
            href: getAdoRepoUrl(account, project, repoName),
            iconName: PageType.Repo,
        });
    }
    return items;
};

export const buildServiceOwnershipBreadcrumbTrail = (
    service: Service | undefined
): LinkItem[] => {
    const items: LinkItem[] = [];
    if (service) {
        //Division
        items.push({
            text: service.divisionName,
            href: '#/Divisions/' + service.divisionId,
            serviceType: 'Division',
        });
        //Organization
        if (service.organizationId && service.organizationName) {
            items.push({
                text: service.organizationName,
                href: '#/Organizations/' + service.organizationId,
                serviceType: 'Organization',
            });
        }
        //Service
        if (service.serviceId && service.serviceName) {
            items.push({
                text: service.serviceName,
                href: '#/Services/' + service.serviceId,
                serviceType: 'Service',
            });
        }
        //Component
        if (service.componentId && service.componentName) {
            items.push({
                text: service.componentName,
                href: '#/Components/' + service.componentId,
                serviceType: 'Component',
            });
        }
    }
    return items;
};

export const buildFeatureCards = (
    serviceType: ServiceType,
    serviceId: string
): LinkItem[] => {
    const organizationsPage = {
        text: 'Organizations',
        href: '/' + serviceType + 's/' + serviceId + '/Organizations',
        iconName: PageType.Organization,
    };
    const servicesPage = {
        text: 'Services',
        href: '/' + serviceType + 's/' + serviceId + '/Services',
        iconName: PageType.Service,
    };
    const componentsPage = {
        text: 'Components',
        href: '/' + serviceType + 's/' + serviceId + '/Components',
        iconName: PageType.Component,
    };
    const releasesPage = {
        text: 'Releases',
        href: '/' + serviceType + 's/' + serviceId + '/Releases',
        iconName: PageType.Release,
    };
    const buildsPage = {
        text: 'Builds',
        href: '/' + serviceType + 's/' + serviceId + '/Builds',
        iconName: PageType.Build,
    };
    const artifactsPage = {
        text: 'Artifacts',
        href: '/' + serviceType + 's/' + serviceId + '/Artifacts',
        iconName: PageType.ArtifactList,
    };
    // Division
    if (serviceType === 'Division') {
        return [
            organizationsPage,
            releasesPage,
            buildsPage,
            artifactsPage,
            //incidentsPage,
        ];
    }
    // Organization
    if (serviceType === 'Organization') {
        return [
            servicesPage,
            releasesPage,
            buildsPage,
            artifactsPage,
            //incidentsPage,
        ];
    }
    // Service
    if (serviceType === 'Service') {
        return [
            componentsPage,
            releasesPage,
            buildsPage,
            artifactsPage,
            // incidentsPage,
        ];
    }
    // Component
    return [releasesPage, buildsPage, artifactsPage];
};
