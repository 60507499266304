import {
    Checkbox,
    Dropdown,
    IDropdownOption,
    IconButton,
    Modal,
    Spinner,
    Stack,
    TextField,
} from '@fluentui/react';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { DateTimeBox } from '../../../HubLayout/components/Utilities/DateTimeBox';
import { Banner } from '../../../Common/models/Banner';
import { CancelToken } from 'axios';
import { setBannerData } from '../../../Common/api/ABHub/BannerApi';
import { usePromise } from '../../../Common/hooks/usePromise';
import { DefaultHubButton } from '../../../HubLayout/components/Utilities/DefaultHubButton';
import { AuthContext } from '../../../HubLayout/models/AuthContext';

interface IBannerAddModalProps {
    banner?: Banner;
    editMode?: boolean;
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    refreshBannerList?: () => void;
}

export const BannerAddModal: React.FC<IBannerAddModalProps> = (
    props: IBannerAddModalProps
) => {
    const stackTokens = { childrenGap: 10 };
    const authContext = useContext(AuthContext);

    const [startDate, setStartDate] = useState<Date>(
        props.banner?.startDate ?? new Date()
    );
    const [endDate, setEndDate] = useState<Date>(
        props.banner?.endDate ?? new Date()
    );
    const [isLoading, setIsLoading] = useState(false);

    const [selectedBannerIndex, setSelectedBannerIndex] = useState<number>(
        props.banner?.typeInt ?? 0
    );
    const [banner, setBanner] = useState<Banner>();

    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined
    );
    const options: IDropdownOption[] = [
        { key: 0, text: 'General' },
        { key: 1, text: 'Warning' },
        { key: 2, text: 'Error' },
    ];

    const setBannerCallback = useCallback(
        async (cancelToken?: CancelToken) => {
            setIsLoading(true);
            if (banner && startDate && endDate) {
                const bannerData: Banner = {
                    id: banner.id,
                    title: banner.title,
                    message: banner.message,
                    isActive: banner.isActive,
                    createdBy:
                        authContext.authContext.user?.profile.upn?.split(
                            '@'
                        )[0] ?? '',
                    createdDate: new Date(Date.now()),
                    lastUpdatedBy: props.editMode
                        ? authContext.authContext.user?.profile.upn?.split(
                              '@'
                          )[0] ?? ''
                        : undefined,
                    lastUpdatedDate: props.editMode
                        ? new Date(Date.now())
                        : undefined,
                    startDate: startDate,
                    endDate: endDate,
                    type: banner.type,
                };

                const response = await setBannerData(bannerData, cancelToken);

                return response;
            }
        },
        [banner, startDate, endDate]
    );

    const [result, error, isLoaded, start] = usePromise(
        setBannerCallback,
        false
    );

    useEffect(() => {
        if (result) {
            setIsLoading(false);
            props.setIsModalOpen(false);

            if (props.refreshBannerList) {
                props.refreshBannerList();
            }
        }

        if (error) {
            setErrorMessage(error.message);
            setIsLoading(false);
        }
    }, [result, error]);

    useEffect(() => {
        if (props.editMode && props.banner) {
            setBanner(props.banner);
            setStartDate(new Date(props.banner.startDate));
            setEndDate(new Date(props.banner.endDate));
            setSelectedBannerIndex(props.banner.typeInt ?? 0);
        }
    }, [props.banner, props.editMode]);

    const handleClick = () => {
        props.setIsModalOpen(!props.isModalOpen);
    };

    return (
        <div className="AddBannerModal-root">
            <Modal
                titleAriaId={props.editMode ? 'Edit Banner' : 'Add New Banner'}
                isOpen={props.isModalOpen}
            >
                <Stack
                    horizontal
                    tokens={stackTokens}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px 20px',
                    }}
                >
                    <h1>{props.editMode ? 'Edit Banner' : 'Add New Banner'}</h1>
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={handleClick}
                    />
                </Stack>

                <Stack tokens={stackTokens} style={{ padding: '20px' }}>
                    <TextField
                        label="Title"
                        required
                        defaultValue={props.banner?.title ?? ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setBanner((prev) => ({
                                    ...prev!,
                                    title: newValue,
                                }));
                            }
                        }}
                    />
                    <TextField
                        label="Message"
                        required
                        defaultValue={props.banner?.message ?? ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setBanner((prev) => ({
                                    ...prev!,
                                    message: newValue,
                                }));
                            }
                        }}
                        multiline
                        autoAdjustHeight
                    />

                    <DateTimeBox
                        dateLabel="Start Date"
                        timeLabel="Start Time (UTC)"
                        setDate={setStartDate}
                        value={startDate}
                    />
                    <DateTimeBox
                        dateLabel="End Date"
                        timeLabel="End Time (UTC)"
                        setDate={setEndDate}
                        value={endDate}
                    />

                    <Dropdown
                        placeholder="Select an option"
                        label="Banner Type"
                        options={options}
                        required
                        selectedKey={
                            selectedBannerIndex
                                ? options[selectedBannerIndex]?.key
                                : 0
                        }
                        onChange={(event, option, index) => {
                            setSelectedBannerIndex(index ?? 0);
                            setBanner((prev) => ({
                                ...prev!,
                                type: option?.key as number,
                                typeInt: option?.key as number,
                                typeString: option?.text as string,
                            }));
                        }}
                    />

                    <Checkbox
                        label="Is Active"
                        defaultChecked={banner?.isActive}
                        onChange={(event, isChecked) => {
                            setBanner((prev) => ({
                                ...prev!,
                                isActive: isChecked!,
                            }));
                        }}
                    />
                    {!isLoading ? (
                        <Stack tokens={stackTokens}>
                            <DefaultHubButton
                                buttonText="Submit"
                                onClick={() => {
                                    start();
                                }}
                            />

                            <p style={{ color: 'red' }}>{errorMessage}</p>
                        </Stack>
                    ) : (
                        <Spinner />
                    )}
                </Stack>
            </Modal>
        </div>
    );
};
