import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useBoolean } from '@uifabric/react-hooks';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IButtonStyles,
    IconButton,
    IIconStyles,
    ITooltipProps,
    PrimaryButton,
    TooltipHost,
} from '@fluentui/react';
import { usePromise } from '../hooks/usePromise';
import { buttonStyles } from '../util/CommonStyles';
import { ButtonState } from '../models/ButtonState';
import { CancelToken } from 'axios';
import { ThemeContext } from '../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../util/localStorageUtils';

interface IIconActionButtonProps {
    onClick: (cancelToken?: CancelToken) => Promise<any>;
    initialButtonText: string;
    initialButtonIcon: string;
    showConfirmationPopup: boolean;
    modalText?: string;
    setDisabledOnSuccess?: boolean;
    buttonStyle?: Partial<IButtonStyles>;
    setDisabled?: boolean;
    toolTipText?: string;
    toolTipTextColor?: string;
    iconName: string;
    differentiator?: string;
}

export const IconActionButton: React.FC<IIconActionButtonProps> = (
    props: IIconActionButtonProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [buttonState, setButtonState] = useState<ButtonState>(
        ButtonState.Initialized
    );

    const disabled =
        props.setDisabled != null
            ? props.setDisabled
            : buttonState === ButtonState.Pending ||
              (buttonState === ButtonState.Succeeded &&
                  props.setDisabledOnSuccess &&
                  props.setDisabledOnSuccess === true);

    const modalProps = {
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
    };
    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: props.initialButtonText,
        subText: props.modalText || '',
    };

    const getButtonIconName = (): string => {
        switch (buttonState as ButtonState) {
            case ButtonState.Initialized:
                return props.iconName;
            case ButtonState.Pending:
                return 'Clock';
            case ButtonState.Succeeded:
                return 'Completed';
            case ButtonState.Failed:
                return 'Error';
        }
    };

    const getButtonIconTextColor = (): string => {
        if (disabled && buttonState !== ButtonState.Succeeded) {
            return theme.semanticColors.disabledText;
        }
        switch (buttonState as ButtonState) {
            case ButtonState.Initialized:
                return theme.palette.black;
            case ButtonState.Pending:
                return theme.palette.themePrimary;
            case ButtonState.Succeeded:
                return theme.semanticColors.successIcon;
            case ButtonState.Failed:
                return theme.semanticColors.errorIcon;
        }
    };

    const iconStyles: Partial<IIconStyles> = {
        root: {
            color: getButtonIconTextColor(),
        },
    };

    const tooltipProps: ITooltipProps =
        props.toolTipText != undefined
            ? {
                  onRenderContent: () =>
                      props.toolTipText != '' ? (
                          <div
                              style={{
                                  color: props.toolTipTextColor,
                              }}
                          >
                              {props.toolTipText}
                          </div>
                      ) : null,
              }
            : {
                  onRenderContent: () =>
                      error ? (
                          <div
                              style={{
                                  color: theme.semanticColors.errorText,
                              }}
                          >
                              {error.message}
                          </div>
                      ) : null,
              };

    const [, error, isLoaded, callApi] = usePromise(props.onClick, false);

    const intializeAction = () => {
        setButtonState(ButtonState.Pending);
        if (props.showConfirmationPopup) {
            toggleHideDialog();
        } else {
            activateAction();
        }
    };

    const activateAction = () => {
        toggleHideDialog();

        // Run the apiCall
        callApi();
    };

    const cancelDialog = () => {
        toggleHideDialog();
        setButtonState(ButtonState.Initialized);
    };

    useEffect(() => {
        if (isLoaded) {
            setButtonState(ButtonState.Succeeded);
            // wait for 10 seconds then change back to initialized state
            if (!props.setDisabledOnSuccess) {
                setTimeout(() => {
                    setButtonState(ButtonState.Initialized);
                }, 1000);
            }
        }
    }, [isLoaded, props.setDisabledOnSuccess]);

    useEffect(() => {
        if (error) {
            setButtonState(ButtonState.Failed);
        }
    }, [error]);

    return (
        <div className="IconActionButton-root">
            <TooltipHost tooltipProps={tooltipProps} id="errorTooltip">
                <IconButton
                    text={props.initialButtonText}
                    disabled={disabled}
                    onClick={intializeAction}
                    iconProps={{
                        iconName: getButtonIconName(),
                        styles: iconStyles,
                    }}
                />
            </TooltipHost>

            {props.showConfirmationPopup && (
                <Dialog
                    hidden={hideDialog}
                    onDismiss={cancelDialog}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={activateAction} text="Yes" />
                        <DefaultButton onClick={cancelDialog} text="No" />
                    </DialogFooter>
                </Dialog>
            )}
        </div>
    );
};

IconActionButton.defaultProps = {
    buttonStyle: buttonStyles,
    toolTipTextColor: '#a4262c', //error color
};
