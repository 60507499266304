import { useRef, useEffect } from 'react';

export default function useDocumentTitle(
    title: string,
    prevailOnUnmount: boolean = false
) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        []
    );
}
