import React from 'react';
import {
    IPersonaSharedProps,
    Persona,
    PersonaInitialsColor,
    PersonaSize,
} from '@fluentui/react';

interface IPagePersonaProps {
    initial?: string;
}

export const PagePersona: React.FC<IPagePersonaProps> = (
    props: IPagePersonaProps
) => {
    const personaJustInitials: IPersonaSharedProps = {
        imageInitials: props.initial || 'S',
    };

    const getInitialsColor = (): PersonaInitialsColor => {
        if (props.initial) {
            switch (props.initial) {
                case 'D':
                    return PersonaInitialsColor.orange;
                case 'O':
                    return PersonaInitialsColor.gold;
                case 'C':
                    return PersonaInitialsColor.magenta;
            }
        }
        return PersonaInitialsColor.purple;
    };

    return (
        <div>
            <Persona
                {...personaJustInitials}
                initialsColor={getInitialsColor()}
                initialsTextColor={'white'}
                size={PersonaSize.size32}
                imageAlt="service type icon"
            />
        </div>
    );
};
