import React, { useContext, useEffect, useState } from 'react';
import {
    CompoundButton,
    DefaultButton,
    DirectionalHint,
    IButtonStyles,
    IContextualMenuItem,
    IContextualMenuProps,
} from '@fluentui/react';
import { AuthContext } from '../../models/AuthContext';
import { UserPersona } from './Display/UserPersona';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../Common/api/ABHub/ABHubGlobalConfig';

export const UserProfile: React.FC = () => {
    const [imageUrl, setImageUrl] = useState<string | undefined>();

    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const { authContext } = useContext(AuthContext);
    const username = authContext.user?.profile.upn ?? '';

    //if we have authenticated the user, get the user profile photo
    useEffect(() => {
        if (authContext.isUserAuthorized && username && !imageUrl) {
            //getPhoto(username).then((response) => {
            //    setImageUrl(URL.createObjectURL(response));
            //});
        }
    }, [authContext.isUserAuthorized, username, imageUrl]);

    const userProfileButtonStyles: Partial<IButtonStyles> = {
        menuIcon: {
            display: 'none',
        },
        root: {
            paddingTop: '0px',
            paddingRight: '5px',
            paddingBottom: '0px',
            paddingLeft: '5px',
            maxHeight: '40px',
            minHeight: '40px',
            border: 'none',
        },
    };

    const loginButtonStyles: Partial<IButtonStyles> = {
        rootHovered: {
            backgroundColor: theme.palette.neutralQuaternaryAlt,
        },
    };

    const handleUserLogin = () => {
        if (authContext.login) {
            authContext.login();
        }
    };

    const handleUserLogout = () => {
        if (authContext.logout) {
            authContext.logout();
        }
    };

    const oppositeTheme =
        themeContext.themeName && themeContext.themeName === 'dark'
            ? 'default'
            : 'dark';

    var items: IContextualMenuItem[] = [];
    if (themeContext.themeName) {
        items.push({
            key: 'themeChange',
            text: 'Switch to ' + oppositeTheme + ' theme',
            iconProps: { iconName: 'Color' },
            onClick: () => {
                themeContext.setTheme && themeContext.setTheme(oppositeTheme);
            },
        });
    }

    // Privacy notice
    const privacyUrl = getConfig(GlobalConfigProperties.Privacy_url);
    if (privacyUrl) {
        items.push({
            key: 'privacy',
            text: 'Privacy + Terms',
            iconProps: { iconName: 'Shield' },
            onClick: () => {
                window.open(privacyUrl, '_blank');
            },
        });
    }

    if (authContext.logout) {
        items.push({
            key: 'logout',
            text: 'Logout',
            iconProps: { iconName: 'SignOut' },
            onClick: handleUserLogout,
        });
    }

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        directionalHint: DirectionalHint.bottomRightEdge,
        directionalHintFixed: true,
        items: items,
    };

    return authContext.isUserAuthorized ? (
        <>
            <CompoundButton
                menuProps={menuProps}
                styles={userProfileButtonStyles}
            >
                <UserPersona
                    imageUrl={imageUrl}
                    name={authContext.user?.profile.name}
                    username={username}
                />
            </CompoundButton>
        </>
    ) : authContext.login ? (
        <DefaultButton
            text="Login"
            onClick={handleUserLogin}
            styles={loginButtonStyles}
        />
    ) : null;
};
