import { DatePicker, IComboBox, Stack, TimePicker } from '@fluentui/react';
import React, {
    Dispatch,
    FormEvent,
    SetStateAction,
    useCallback,
    useState,
} from 'react';

interface IDateTimeBoxProps {
    dateLabel: string;
    timeLabel: string;
    setDate: Dispatch<SetStateAction<Date>>;
    value?: Date;
}

export const DateTimeBox: React.FC<IDateTimeBoxProps> = (
    props: IDateTimeBoxProps
) => {
    const [datePickerDate, setDatePickerDate] = useState<Date>();
    const [currentTime, setCurrentTime] = useState<Date>(
        props.value ?? new Date()
    );

    const onSelectDate = useCallback(
        (selectedDate: Date | undefined | null) => {
            var date = selectedDate ?? new Date();
            if (currentTime) {
                const snappedTime = snapTimeToUpdatedDateAnchor(
                    selectedDate!,
                    currentTime
                );
                setCurrentTime(snappedTime);
                date = snappedTime;
            }

            props.setDate(date);
            setDatePickerDate(date);
        },
        [currentTime]
    );

    const onTimePickerChange = useCallback(
        (_ev: FormEvent<IComboBox>, date: Date) => {
            setCurrentTime(date);
            props.setDate(date);
        },
        []
    );

    const snapTimeToUpdatedDateAnchor = (
        datePickerDate: Date,
        currentTime: Date
    ) => {
        let snappedTime = new Date(currentTime);

        if (currentTime && !isNaN(currentTime.valueOf())) {
            const startAnchor = new Date(datePickerDate);
            const endAnchor = new Date(startAnchor);
            endAnchor.setDate(startAnchor.getDate() + 1);
            if (currentTime < startAnchor || currentTime > endAnchor) {
                snappedTime = new Date(startAnchor);
                snappedTime.setHours(currentTime.getHours());
                snappedTime.setMinutes(currentTime.getMinutes());
                snappedTime.setSeconds(currentTime.getSeconds());
                snappedTime.setMilliseconds(currentTime.getMilliseconds());
            }
        }

        return snappedTime;
    };

    return (
        <div className="DateTimeBox-root">
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DatePicker
                    label={props.dateLabel}
                    placeholder="Select a date..."
                    value={props.value ?? datePickerDate}
                    isRequired
                    onSelectDate={onSelectDate}
                />
                <TimePicker
                    label={props.timeLabel}
                    placeholder="Select a time"
                    dateAnchor={props.value ?? datePickerDate}
                    value={currentTime}
                    required
                    onChange={onTimePickerChange}
                />
            </Stack>
        </div>
    );
};
