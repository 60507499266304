import { agpAxiosClient } from '../../Common/api/ABHub/ABHubTokenConfig';
import {
    Version,
    VersionResponse,
    AGCPPublishResponse,
} from '../models/AirGapMessage';
import { CancelToken } from 'axios';
import { RequestResult } from '../../AGCP/models/AirGapMessage';

import {
    getConfig,
    GlobalConfigProperties,
} from '../../Common/api/ABHub/ABHubGlobalConfig';
import { ReqeueDeadLetter } from '../models/ReqeueDeadLetter';

export const getVersion = async (
    cancelToken?: CancelToken
): Promise<Version[]> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.get<VersionResponse>(
        agssUrl + '/api/version',
        { cancelToken }
    );

    return response.data.versions;
};

export const publishCommand = async (
    message: any,
    eventType: string,
    environment: string,
    cancelToken?: CancelToken
): Promise<AGCPPublishResponse<boolean>> => {
    const response = await agpAxiosClient.post(
        `/API/publish/${eventType}/${environment}`,
        message,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return response.data;
};

export const reqeueDeadLetterMessages = async (
    queueName: string,
    messageCount: number,
    cancelToken?: CancelToken
): Promise<RequestResult<ReqeueDeadLetter>> => {
    if (messageCount > 1000) {
        messageCount = 1000;
    }

    const agcpUrl = getConfig(GlobalConfigProperties.AgcpUrl);

    const response = await agpAxiosClient.post<RequestResult<ReqeueDeadLetter>>(
        `${agcpUrl}/api/maintenance/requeuedeadletter`,
        {}, // No body data to send for this request
        {
            params: {
                queueName,
                messageCount,
            },
            cancelToken: cancelToken,
        }
    );

    return response.data;
};
