import React, {
    PropsWithChildren,
    Dispatch,
    SetStateAction,
    MutableRefObject,
} from 'react';
import { SearchBox, ISearchBoxStyles } from '@fluentui/react';
import { IIconProps } from '@fluentui/react';
import { CBFilter } from '../../HubLayout/components/CommandBar';
import { filterList } from '../util/tableUtils';

interface IFilterBoxProps extends PropsWithChildren<any> {
    items: any[];
    setItems: Dispatch<SetStateAction<any[]>>;
    filterFunc: (i: any, newValue: string) => boolean;
    filterValueRef: MutableRefObject<string | undefined>;
    prompt?: string;
}

export const FilterBox: React.FC<IFilterBoxProps> = (
    props: IFilterBoxProps
) => {
    const filterIcon: IIconProps = { iconName: 'Filter' };
    const filterStyles: Partial<ISearchBoxStyles> = {
        root: {
            margin: 'auto',
        },
    };

    const onChangeText = (
        event?: React.ChangeEvent<HTMLInputElement>,
        newValue?: string
    ) => {
        if (props.items && props.setItems) {
            props.filterValueRef.current = newValue;
            filterList(
                props.filterFunc,
                props.setItems,
                props.items,
                newValue || ''
            );
        }
    };

    return (
        <CBFilter
            filter={
                <SearchBox
                    styles={filterStyles}
                    placeholder={
                        props.prompt ? props.prompt : 'Find in this list'
                    }
                    iconProps={filterIcon}
                    onChange={onChangeText}
                />
            }
        />
    );
};
