import React, { useCallback, useContext } from 'react';
import { PageHeader } from '../../../HubLayout/components/PageLayout/PageHeader';
import {
    CommandButton,
    IconButton,
    IContextualMenuItem,
    IStackTokens,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
} from '@fluentui/react';
import { spinnerStyles } from '../../util/StyleUtils';
import { Release } from '../../models/Release';
import { CompletionStatusDisplay } from './Display/Status/CompletionStatusDisplay';
import { CapabilityContext } from '../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../Common/components/Capabilities/capability';
import { Ellipsis } from '../../../HubLayout/components/Utilities/Ellipsis';
import { ReleaseFlow } from './ReleaseFlow';
import { ReleasePipelineSource } from '../../models/ReleasePipelineSource';
import {
    getNewYamlReleaseUrl,
    getServiceTreeUrl,
} from '../../../Common/util/RouteUtils';
import { CancelToken } from 'axios';
import { getReleaseExport } from '../../api/AGSSApi';
import { usePromise } from '../../../Common/hooks/usePromise';

interface IMobileReleaseDetailsPageProps {
    pageTitle: string;
    release: Release | undefined;
    isLoaded: boolean;
}

export const MobileReleaseDetailsPage: React.FC<IMobileReleaseDetailsPageProps> =
    (props: IMobileReleaseDetailsPageProps) => {
        const capabilities = useContext(CapabilityContext);
        const stackTokens: IStackTokens = { childrenGap: 10 };

        const exportRelease = useCallback(
            async (cancelToken?: CancelToken) => {
                return await getReleaseExport(
                    [props.release?.releaseCorrelationId || ''],
                    cancelToken
                );
            },
            [props]
        );

        const [result, error, isLoaded, start] = usePromise(
            exportRelease,
            false
        );

        const renderExportReleaseButton = (
            item: any,
            dismissMenu: (ev?: any, dismissAll?: boolean) => void
        ) => {
            return capabilities.check(Capability.srmAdminRole) ? (
                <CommandButton
                    iconProps={item.iconProps}
                    text={item.text}
                    onClick={item.onClick}
                    styles={item.styles}
                />
            ) : (
                <></>
            );
        };

        const menuItems: IContextualMenuItem[] = [
            {
                key: 'exportRelease',
                text: 'Export Release',
                iconProps: { iconName: 'Download' },
                onRender: renderExportReleaseButton,
                onClick: () => {
                    start();
                },
            },
        ];

        return (
            <div className="MobileReleaseDetailsPage-root">
                <PageHeader title={props.pageTitle} />

                {props.isLoaded && (
                    <Stack tokens={stackTokens}>
                        {/* Release status and Action buttons */}
                        <Stack
                            tokens={stackTokens}
                            horizontal
                            style={{
                                padding: '0px 10px',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {props.release &&
                                props.release.completionIndicator && (
                                    <CompletionStatusDisplay
                                        completionIndicator={
                                            props.release.completionIndicator
                                        }
                                        completionStatusTracker={
                                            props.release
                                                .completionStatusTracker
                                        }
                                        addPadding
                                    />
                                )}

                            <Stack
                                tokens={{ childrenGap: 8 }}
                                horizontal
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton
                                    iconProps={{ iconName: 'Rocket' }}
                                    onClick={() => {
                                        window.open(
                                            ReleasePipelineSource.YAML
                                                ? getNewYamlReleaseUrl(
                                                      props.release
                                                          ?.accountName ||
                                                          props.release
                                                              ?.adoInstance ||
                                                          '',
                                                      props.release
                                                          ?.projectName || '',
                                                      '',
                                                      props.release
                                                          ?.releaseId || 0
                                                  )
                                                : props.release?.releaseWebUrl,
                                            '_blank'
                                        );
                                    }}
                                />

                                <IconButton
                                    iconProps={{ iconName: 'CRMServices' }}
                                    onClick={() => {
                                        window.open(
                                            getServiceTreeUrl(
                                                props.release?.serviceTreeId ||
                                                    ''
                                            ),
                                            '_blank'
                                        );
                                    }}
                                />

                                {capabilities.check(
                                    Capability.srmAdminRole
                                ) && (
                                    <div>
                                        <Ellipsis menuItems={menuItems} />
                                    </div>
                                )}
                            </Stack>
                        </Stack>

                        {/* Release Stages */}
                        <div>
                            <ReleaseFlow
                                release={props.release}
                                isLoaded={props.isLoaded}
                            />
                        </div>
                    </Stack>
                )}

                {!props.isLoaded && (
                    <div>
                        <Spinner
                            styles={spinnerStyles}
                            size={SpinnerSize.large}
                        />
                    </div>
                )}
            </div>
        );
    };
