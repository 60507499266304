import { agpAxiosClient } from './ABHubTokenConfig';

export const sendMetric = async (
    metric: number,
    metricName: string,
    metricNamespace: string,
    dimensions: object
): Promise<boolean> => {
    const metricData = {
        metric: metric,
        metricName: metricName,
        metricNamespace: metricNamespace,
        dimensions: dimensions,
    };

    const response = await agpAxiosClient.post('/api/metrics', metricData);

    return response.data;
};
