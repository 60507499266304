import {
    FontWeights,
    IButtonStyles,
    mergeStyles,
    mergeStyleSets,
    Theme,
} from '@fluentui/react';

//modal styles
export const commonModalStyles = (theme: Theme) =>
    mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            // tslint:disable-next-line:deprecation
            theme.fonts.xxLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0', color: theme.palette.neutralSecondary },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        footer: {
            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'center',
            padding: '0px 0px 10px 0px',
        },
    });

//button styles
export const buttonStyles: Partial<IButtonStyles> = {
    root: {
        textAlign: 'start',
        minWidth: 165,
        maxWidth: 165,
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: '5px',
        borderWidth: '2px',
    },
};

export const wideButtonStyles: Partial<IButtonStyles> = {
    root: {
        textAlign: 'start',
        minWidth: 200,
        maxWidth: 200,
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: '5px',
        borderWidth: '2px',
    },
};

//link styles
export const linkStyle = (theme: Theme) =>
    mergeStyles({
        color: theme.palette.neutralDark,
        textDecoration: 'none',
        selectors: {
            ':hover, :active, :active:hover': {
                textDecoration: 'underline',
                color: theme.palette.themePrimary,
            },
        },
    });

export const externalLinkStyle = (theme: Theme) =>
    mergeStyles(linkStyle(theme), { paddingLeft: '0.5em' });
