import { IMessageBarStyles, MessageBar, MessageBarType } from '@fluentui/react';
import { CancelToken } from 'axios';
import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useState,
} from 'react';
import { getLatestEgressDates } from '../../../AGSS/api/AGSSApi';
import { usePromise } from '../../../Common/hooks/usePromise';
import { Environments } from '../../../Common/models/CloudEnvironment';
import { dateLocaleStringWithTimeZone } from '../../../Common/util/DateUtils';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../models/ThemeContext';

interface IMessageBarProps extends PropsWithChildren<any> {}

export const EgressMessageBar: React.FC<IMessageBarProps> = (
    props: IMessageBarProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const getData = useCallback(async (cancelToken?: CancelToken) => {
        return await getLatestEgressDates();
    }, []);

    const [data, error, isLoaded] = usePromise(getData, true);

    const [showGeneralEgressMessage, setShowGeneralEgressMessage] =
        useState<boolean>(true);

    const messageBarStyles: Partial<IMessageBarStyles> = {
        innerText: {
            fontSize: theme.fonts.medium.fontSize,
            color: theme.palette.black,
        },
        icon: { color: theme.palette.black },
        dismissal: { color: theme.palette.black },
    };

    const dismissGeneralEgressMessage = () => {
        setShowGeneralEgressMessage(false);
    };

    const getMessageData = () => {
        let egressMessage: string[] = [];
        for (let i = 0; i < Environments.Length; i++) {
            if (data && data[i]) {
                let date = new Date(data[i].updatedDate);
                egressMessage.push(
                    'Most recent update from ' +
                        data[i].environmentId +
                        ' was on ' +
                        dateLocaleStringWithTimeZone(date) +
                        '.'
                );
            }
        }

        return egressMessage.map((line) => <div key={line}>{line}</div>);
    };

    return isLoaded && data != null && data.length > 0 ? (
        <div className="MessageBar-root" style={{ zIndex: 1 }}>
            {showGeneralEgressMessage && (
                <MessageBar
                    messageBarType={MessageBarType.info}
                    isMultiline={true}
                    onDismiss={dismissGeneralEgressMessage}
                    dismissButtonAriaLabel="Close"
                    styles={messageBarStyles}
                >
                    {getMessageData()}
                </MessageBar>
            )}
        </div>
    ) : (
        <div></div>
    );
};
