import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardPreview,
    DocumentCardTitle,
    DocumentCardType,
    IStackTokens,
    Stack,
} from '@fluentui/react';
import React, { useContext } from 'react';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { convertToUserFriendlyByteString } from '../../../Common/util/tableUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { Artifact } from '../../models/Artifact';
import {
    documentCardStyles,
    previewPropsUsingIcon,
} from '../../util/StyleUtils';

interface IArtifactMetricsProps {
    data: Artifact;
}

export const ArtifactMetrics: React.FC<IArtifactMetricsProps> = (
    props: IArtifactMetricsProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const stackTokens: IStackTokens = { childrenGap: 10 };

    const sectionStackTokens: IStackTokens = { childrenGap: 20 };
    return (
        <Stack className="ArtifactMetrics-root" tokens={stackTokens}>
            <Stack wrap horizontal tokens={sectionStackTokens}>
                <DocumentCard
                    aria-label="Total Files"
                    title="Total Files"
                    type={DocumentCardType.normal}
                    className={
                        documentCardStyles(theme, themeContext.themeName).card
                    }
                    style={{ width: '100%' }}
                >
                    <DocumentCardPreview
                        {...previewPropsUsingIcon(
                            'Documentation',
                            theme,
                            themeContext.themeName
                        )}
                    />
                    <DocumentCardDetails>
                        <DocumentCardTitle
                            title={
                                'Total Files: ' +
                                props.data.numReplicationPlanFiles
                            }
                            className={
                                documentCardStyles(
                                    theme,
                                    themeContext.themeName
                                ).title
                            }
                        />
                        <DocumentCardTitle
                            title={
                                'Unique Files: ' +
                                (props.data.numReplicationPlanFiles -
                                    props.data.numInnerDuplicates)
                            }
                            className={
                                documentCardStyles(
                                    theme,
                                    themeContext.themeName
                                ).subTitle
                            }
                        />
                    </DocumentCardDetails>
                </DocumentCard>
                <DocumentCard
                    aria-label="Total Files Replicated"
                    title="Total Files Replicated"
                    type={DocumentCardType.normal}
                    className={
                        documentCardStyles(theme, themeContext.themeName).card
                    }
                    style={{ width: '100%' }}
                >
                    <DocumentCardPreview
                        {...previewPropsUsingIcon(
                            'Documentation',
                            theme,
                            themeContext.themeName
                        )}
                    />
                    <DocumentCardDetails>
                        <DocumentCardTitle
                            title={
                                'Total Files Replicated: ' +
                                props.data.totalFilesTransferred
                            }
                            className={
                                documentCardStyles(
                                    theme,
                                    themeContext.themeName
                                ).title
                            }
                        />
                    </DocumentCardDetails>
                </DocumentCard>
                <DocumentCard
                    aria-label="Total Bytes"
                    title="Total Bytes"
                    type={DocumentCardType.normal}
                    className={
                        documentCardStyles(theme, themeContext.themeName).card
                    }
                    style={{ width: '100%' }}
                >
                    <DocumentCardPreview
                        {...previewPropsUsingIcon(
                            'PieDouble',
                            theme,
                            themeContext.themeName
                        )}
                    />
                    <DocumentCardDetails>
                        <DocumentCardTitle
                            title={
                                'Total Bytes: ' +
                                convertToUserFriendlyByteString(
                                    props.data.totalArtifactBytes
                                )
                            }
                            className={
                                documentCardStyles(
                                    theme,
                                    themeContext.themeName
                                ).title
                            }
                        />
                    </DocumentCardDetails>
                </DocumentCard>
                <DocumentCard
                    aria-label="Total Bytes Replicated"
                    title="Total Bytes Replicated"
                    type={DocumentCardType.normal}
                    className={
                        documentCardStyles(theme, themeContext.themeName).card
                    }
                    style={{ width: '100%' }}
                >
                    <DocumentCardPreview
                        {...previewPropsUsingIcon(
                            'PieDouble',
                            theme,
                            themeContext.themeName
                        )}
                    />
                    <DocumentCardDetails>
                        <DocumentCardTitle
                            title={
                                'Total Bytes Replicated: ' +
                                convertToUserFriendlyByteString(
                                    props.data.totalBytesReplicated
                                )
                            }
                            className={
                                documentCardStyles(
                                    theme,
                                    themeContext.themeName
                                ).title
                            }
                        />
                    </DocumentCardDetails>
                </DocumentCard>
            </Stack>
        </Stack>
    );
};
