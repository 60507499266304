import React, { useContext } from 'react';
import {
    DefaultEffects,
    Stack,
    Pivot,
    PivotItem,
    IconButton,
    IIconProps,
    FontWeights,
} from '@fluentui/react';
import { PageBrand } from './PageBrand';
import { routeKeys } from '../../../Common/util/RouteUtils';
import { useLocation, useHistory } from 'react-router-dom';
import { UserProfile } from '../UserPages/UserProfile';
import { Search } from '../Search/Search';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { useIsMobile } from '../../../Common/hooks/useIsMobile';
import { MobileSiteHeader } from './Mobile/MobileSiteHeader';

interface ISiteHeaderProps {
    showSearch?: boolean;
    openMobileNav?: () => void;
}

export const SiteHeader: React.FC<ISiteHeaderProps> = (
    props: ISiteHeaderProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const isMobile = useIsMobile();
    const location = useLocation();
    const history = useHistory();
    const docsIcon: IIconProps = {
        iconName: 'StatusCircleQuestionMark',
        styles: { root: { fontSize: '30px' } },
    };

    let homeItem = (
        <PivotItem
            headerText="Sovereign Release Manager"
            itemKey={routeKeys.Home}
            onRenderItemLink={() => {
                return <PageBrand />;
            }}
        />
    );

    return (
        <header
            className="SiteHeader-root"
            style={{
                boxShadow: DefaultEffects.elevation8,
                backgroundColor: theme.palette.white,
                position: 'relative',
                zIndex: 9999,
            }}
        >
            {isMobile ? (
                <MobileSiteHeader openNav={props.openMobileNav!} />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            marginRight: 'auto',
                        }}
                    >
                        <Stack horizontal>
                            <Pivot
                                headersOnly={true}
                                selectedKey={null}
                                onLinkClick={(
                                    item?: PivotItem,
                                    ev?: React.MouseEvent<
                                        HTMLElement,
                                        MouseEvent
                                    >
                                ) => {
                                    const itemKey = item
                                        ? item.props.itemKey || ''
                                        : '';
                                    if (location.pathname !== itemKey) {
                                        history.push(itemKey);
                                    }
                                }}
                            >
                                {homeItem}
                            </Pivot>
                        </Stack>
                    </div>

                    {props.showSearch && <Search />}

                    <div
                        style={{
                            margin: '2px 3px 0 20px',
                            paddingTop: '5px',
                        }}
                    >
                        <IconButton
                            iconProps={docsIcon}
                            title="Documentation"
                            href={process.env.REACT_APP_SRM_DOCS_URL}
                            target="_blank"
                            ariaLabel="Documentation"
                        />
                    </div>

                    <div
                        style={{
                            marginTop: '2px',
                            marginRight: '5px',
                        }}
                    >
                        <UserProfile />
                    </div>
                </div>
            )}
        </header>
    );
};
