import { PageType, PageLookup } from '../models/Page';
import { RecentPage } from '../models/RecentPage';
import { isRedirectUrl } from './authUtils';
import { routeKeys } from '../../Common/util/RouteUtils';

export const getItemInDocumentCookie = (name: string): string | undefined => {
    // grab item from document.cookie
    const cookies = document.cookie.split(';');
    for (var i in cookies) {
        const key = cookies[i].substring(0, cookies[i].indexOf('='));
        const value = cookies[i].substring(cookies[i].indexOf('=') + 1);
        if (key.trim() === name.trim()) {
            //found your item
            return value;
        }
    }
    return undefined;
};

export const getRecentPagesList = (): Map<string, Date> => {
    const recentPagesListString = getItemInDocumentCookie('recentPagesList');
    var recentPagesList = new Map<string, Date>();
    if (recentPagesListString) {
        try {
            recentPagesList = new Map<string, Date>(
                Object.entries(JSON.parse(recentPagesListString))
            );
        } catch (error) {}
    }
    return recentPagesList;
};

export const setRecentPagesListInCookie = (
    recentPagesList: Map<string, Date>
) => {
    //One year: max-age=31536000
    document.cookie =
        'recentPagesList=' +
        JSON.stringify(Object.fromEntries(recentPagesList.entries())) +
        ';path=/;max-age=31536000;' +
        ';sameSite=strict;secure=true';
};

export const removeUrlFromRecentPagesListCookie = (url: string) => {
    //get recentPagesList from document.cookie
    var recentPagesList = getRecentPagesList();
    //update recentPagesList
    if (recentPagesList.get(url)) {
        recentPagesList.delete(url);
    }
    //set cookie
    setRecentPagesListInCookie(recentPagesList);
};

export const addUrlToRecentPagesList = (location: Location) => {
    //get recentPagesList from document.cookie
    var recentPagesList = getRecentPagesList();
    //update recentPagesList
    if (!skipUrl(location)) {
        const url = removeTrailingSlash(location.hash);
        recentPagesList.set(url, new Date());
    }
    //remove old links
    while (recentPagesList.size > 50) {
        var toBeRemoved: string = '';
        var mostRecent: Date = new Date();
        for (let [key, value] of recentPagesList.entries()) {
            if (
                Date.parse(value.toLocaleString()) <
                Date.parse(mostRecent.toLocaleString())
            ) {
                toBeRemoved = key;
                mostRecent = value;
            }
        }
        recentPagesList.delete(toBeRemoved);
    }
    //set cookie
    setRecentPagesListInCookie(recentPagesList);
};

const removeTrailingSlash = (url: string): string => {
    //remove trailing / from urls
    if (url.charAt(url.length - 1) === '/') {
        return url.substring(0, url.length - 1);
    }
    return url;
};

const skipUrl = (location: Location): boolean => {
    // Skip homepage and unrelated URLs in recent pages list
    return (
        location.hash === '' ||
        location.hash === '#/' ||
        isRedirectUrl(location)
    );
};

/**
 * Converts the history list into an array of RecentPages with friendly names and icons for display.
 * As pages are added to the app, both this switch and the Page.ts lookup should be expanded.
 * @param recentPagesList
 */
export const turnListIntoRecentPages = (
    recentPagesList: Map<string, Date>
): RecentPage[] => {
    const items: RecentPage[] = [];
    for (const [key, value] of recentPagesList.entries()) {
        const pathname = key.substring(key.indexOf('/'));
        var name = '';
        var type = PageType.Default;
        if (PageLookup.get(pathname.trim())) {
            //one of the main paths
            name = PageLookup.get(pathname.trim())?.name || '';
            type = PageLookup.get(pathname.trim())?.type || PageType.Default;
        } else {
            //special path like : /ReleaseStatus/account/project/etc.
            const parts = pathname.split('/');
            if (pathname.startsWith(routeKeys.Features.Releases.Base)) {
                if (parts.length === 4) {
                    switch (parts[2]) {
                        case 'Release':
                            name = 'Release (' + parts[3] + ')';
                            type = PageType.Release;
                            break;
                        case 'Build':
                            name = 'Build (' + parts[3] + ')';
                            type = PageType.Build;
                            break;
                        case 'Artifact':
                            name = 'Artifact (' + parts[3] + ')';
                            type = PageType.Artifact;
                            break;
                    }
                } else if (parts.length === 5) {
                    name = 'Artifact Search (' + parts[4] + ')';
                    type = PageType.Search;
                }
            } else if (
                pathname.startsWith(routeKeys.Divisions) ||
                pathname.startsWith(routeKeys.Organizations) ||
                pathname.startsWith(routeKeys.Services) ||
                pathname.startsWith(routeKeys.Components)
            ) {
                if (parts.length === 3) {
                    switch (parts[1]) {
                        case 'Divisions':
                            name = 'Divisions (' + parts[2] + ')';
                            type = PageType.Division;
                            break;
                        case 'Organizations':
                            name = 'Organizations (' + parts[2] + ')';
                            type = PageType.Organization;
                            break;
                        case 'Services':
                            name = 'Services (' + parts[2] + ')';
                            type = PageType.Service;
                            break;
                        case 'Components':
                            name = 'Components (' + parts[2] + ')';
                            type = PageType.Component;
                            break;
                    }
                } else if (parts.length === 4) {
                    switch (parts[3]) {
                        case 'Organizations':
                            name = 'Organizations (' + parts[2] + ')';
                            type = PageType.Organization;
                            break;
                        case 'Services':
                            name = 'Services (' + parts[2] + ')';
                            type = PageType.Service;
                            break;
                        case 'Components':
                            name = 'Components (' + parts[2] + ')';
                            type = PageType.Component;
                            break;
                        case 'Releases':
                            name = 'Releases (' + parts[2] + ')';
                            type = PageType.Release;
                            break;
                        case 'Builds':
                            name = 'Builds (' + parts[2] + ')';
                            type = PageType.Build;
                            break;
                        case 'Artifacts':
                            name = 'Artifacts (' + parts[2] + ')';
                            type = PageType.Artifact;
                            break;
                    }
                } else if (parts.length === 5) {
                    switch (parts[3]) {
                        case 'Releases':
                            name = 'Release (' + parts[4] + ')';
                            type = PageType.Release;
                            break;
                        case 'Builds':
                            name = 'Build (' + parts[4] + ')';
                            type = PageType.Build;
                            break;
                        case 'Artifacts':
                            name = 'Artifact (' + parts[4] + ')';
                            type = PageType.Artifact;
                            break;
                    }
                } else if (parts.length === 7) {
                    switch (parts[5]) {
                        case 'Build':
                            name = 'Build (' + parts[6] + ')';
                            type = PageType.Build;
                            break;
                        case 'Deployment':
                            name = 'Deployment (' + parts[6] + ')';
                            type = PageType.Deployment;
                            break;
                    }
                }
            } else {
                // when debugging, un-comment this
                // console.log('Unaccounted for url:', key.trim());
            }
        }

        //add recent page
        const recentPage: RecentPage = {
            url: key.trim(),
            name: decodeURI(name),
            type: type,
            lastViewed: value.toLocaleString(),
        };
        if (recentPage.type !== PageType.Default) {
            //unaccounted for urls
            items.push(recentPage);
        }
    }

    return items;
};
