import React, { useContext } from 'react';
import { Icon, Link } from '@fluentui/react';
import { ThemeContext } from '../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../util/localStorageUtils';
import { externalLinkStyle, linkStyle } from '../util/CommonStyles';
import { emitUserActionMetric } from '../util/metricsUtil';
import {
    ActionResult,
    UserAction,
} from '../../Common/Enums/Metrics/MetricEnums';

interface ILinkProps {
    value: string;
    url: string;
    title: string;
    onClick?: () => void;
}

export const InternalLink: React.FC<ILinkProps> = (props: ILinkProps) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const handleClick = () => {
        // Call your custom function here
        emitUserActionMetric(
            UserAction.EXTERNAL_LINK,
            props.value,
            ActionResult.SUCCESS,
            process.env.REACT_APP_BUILD_VERSION
        );

        if (props.onClick) {
            props.onClick();
        }
    };
    return (
        <Link
            href={props.url}
            title={props.title}
            onClick={handleClick}
            className={'link ' + linkStyle(theme)}
        >
            {props.value}
        </Link>
    );
};

export const ExternalLink: React.FC<ILinkProps> = (props: ILinkProps) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const handleClick = () => {
        emitUserActionMetric(
            UserAction.EXTERNAL_LINK,
            props.value,
            ActionResult.SUCCESS,
            process.env.REACT_APP_BUILD_VERSION
        );
    };

    return (
        <Link
            href={props.url}
            target="_blank"
            title={props.title}
            className={'external link ' + externalLinkStyle(theme)}
            onClick={handleClick}
        >
            [{props.value + ' '} <Icon iconName="OpenInNewWindow" />]
        </Link>
    );
};
