import React, {
    useState,
    useEffect,
    useContext,
    Dispatch,
    SetStateAction,
} from 'react';
import {
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
    IStackStyles,
    IButtonStyles,
} from '@fluentui/react';
import { ServiceNameDropDown } from '../../../../Common/components/ServiceNameDropDown';
import { CloudNameDropDown } from '../../../../Common/components/CloudNameDropDown';
import { DeploymentTypeDropDown } from '../../../../Common/components/DeploymentTypeDropDown';
import { commonGridStyle } from '../../../../Common/util/tableUtils';
import { CapabilityContext } from '../../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../../Common/components/Capabilities/capability';

import { dateLocaleStringWithTimeZone } from '../../../../Common/util/DateUtils';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import {
    ExternalLink,
    InternalLink,
} from '../../../../Common/components/Links';
import { ServiceType } from '../../../../ABSM/models/ServiceType';
import { BuildSearchResult } from '../../../models/Build';
import { PageContent } from '../../../../HubLayout/components/PageLayout/PageContent';
import { PageTitle } from '../../../../HubLayout/components/PageLayout/PageTitle';
import { DefaultButton } from 'office-ui-fabric-react';
import { useIsMobile } from '../../../../Common/hooks/useIsMobile';
import { MobileBuildList } from './Mobile/MobileBuildList';
import { BuildColumns } from '../../../../HubLayout/components/BuildColumns';
import { getServiceTreeUrl } from '../../../../Common/util/RouteUtils';
import { CompletionStatusDisplay } from './Status/CompletionStatusDisplay';
import { MultiStageBuildSearch } from '../MultiStageBuildSearch';
import { ProjectNameDropDown } from '../../../../Common/components/ProjectNameDropDown';

interface IBuildListProps {
    list: BuildSearchResult[];
    isLoaded: boolean;
    hideButton?: boolean;
    pageNumber?: number;
    setSearchValue?: Dispatch<SetStateAction<string | undefined>>;
    setSelectedServices?: Dispatch<SetStateAction<string[]>>;
    setSelectedEnvironments?: Dispatch<SetStateAction<string[]>>;
    setSelectedDeploymentTypes?: Dispatch<SetStateAction<string[]>>;
    setSelectedProjects?: Dispatch<SetStateAction<string[]>>;
    setPageNumber?: Dispatch<SetStateAction<number>>;
    serviceType?: ServiceType;
}

export const BuildList: React.FC<IBuildListProps> = (
    props: IBuildListProps
) => {
    const [columnsList, setColumnsList] = useState<IColumn[]>([]);
    const [items, setItems] = useState<BuildSearchResult[]>([]); // current version of the list - filtered or sorted
    const isMobile = useIsMobile();

    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const stackStyles: Partial<IStackStyles> = {
        root: {
            alignItems: 'center',
            backgroundColor: theme.palette.neutralLighter,
        },
    };

    const loadMoreButtonStyles: IButtonStyles = {
        root: {
            display: props.hideButton ? 'none' : 'block',
            width: '100%',
            marginTop: '10px',
            background: theme.palette.neutralLighter,
            color: theme.palette.neutralPrimary,
        },
        rootHovered: {
            color: theme.palette.neutralPrimaryAlt,
            backgroundColor: theme.palette.neutralLight,
        },
    };

    // Use capabilities context
    const capabilities = useContext(CapabilityContext);

    // Only show ADO links in public cloud
    const includeExternalLink = capabilities.check(Capability.public);

    const columns: IColumn[] = BuildColumns;

    const renderBuildColumn = (
        item: BuildSearchResult,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[
            column?.fieldName as keyof BuildSearchResult
        ] as string;

        switch (column?.key) {
            case 'service':
                return (
                    <div>
                        {item.serviceTreeName || item.serviceTreeId}
                        <ExternalLink
                            value="Service Tree"
                            url={getServiceTreeUrl(item.serviceTreeId || '')}
                            title="Open Service Tree"
                        />
                    </div>
                );

            case 'completionIndicator':
                return (
                    <CompletionStatusDisplay
                        completionIndicator={item.completionIndicator || ''}
                        completionStatusTracker={item.completionStatusTracker}
                    />
                );

            case 'createdOn':
                return dateLocaleStringWithTimeZone(item.createdOn);

            default:
                return <span>{fieldContent}</span>;
        }
    };

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        // Default sort by latest timestamp
        //let releases = props.list;
        //releases.sort((s1, s2) => {
        //    if (s1.updatedOn && s2.updatedOn) {
        //        return new Date(dateLocaleStringWithTimeZone(s1.updatedOn)) >
        //            new Date(dateLocaleStringWithTimeZone(s2.updatedOn))
        //            ? -1
        //            : 1;
        //    }
        //    return 0;
        //});
        setColumnsList(columns);
        setItems(props.list);
        // needs columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.list, props.isLoaded]);

    const loadMore = () => {
        if (props.setPageNumber && props.pageNumber) {
            props.setPageNumber(props.pageNumber + 1);
        }
    };

    return (
        <div className="BuildList-root">
            <Stack>
                <PageTitle
                    title="Latest Builds"
                    subTitle={
                        props.isLoaded
                            ? items.length + ' latest builds'
                            : 'Loading...'
                    }
                />
            </Stack>
            {isMobile ? (
                <MobileBuildList
                    list={items}
                    isLoaded={props.isLoaded}
                    pageNumber={props.pageNumber}
                    hideButton={props.hideButton}
                    setSearchValue={props.setSearchValue}
                    setSelectedServices={props.setSelectedServices}
                    setSelectedProjects={props.setSelectedProjects}
                    setPageNumber={props.setPageNumber}
                />
            ) : (
                <div>
                    <PageContent>
                        <Stack horizontal styles={stackStyles}>
                            <MultiStageBuildSearch
                                setSearchValue={props.setSearchValue}
                                setPageNumber={props.setPageNumber}
                                isLoaded={props.isLoaded}
                            />
                            <ServiceNameDropDown
                                setSelectedServices={props.setSelectedServices}
                                setPageNumber={props.setPageNumber}
                                isLoaded={props.isLoaded}
                            />
                            {/*<ProjectNameDropDown*/}
                            {/*   setSelectedKeys={props.setSelectedProjects}*/}
                            {/*   setPageNumber={props.setPageNumber}*/}
                            {/*   isLoaded={props.isLoaded} /> */}
                        </Stack>

                        <ShimmeredDetailsList
                            className={commonGridStyle(theme)}
                            items={items}
                            columns={columnsList}
                            onRenderItemColumn={renderBuildColumn}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            enableShimmer={!props.isLoaded}
                            shimmerLines={10}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Builds"
                        />
                    </PageContent>

                    <DefaultButton
                        styles={loadMoreButtonStyles}
                        text={'Load More'}
                        onClick={loadMore}
                    />

                    {props.isLoaded &&
                        (props.list.length < 1 || items.length < 1) && (
                            <p>No data found.</p>
                        )}
                </div>
            )}
        </div>
    );
};
