import { AxiosInstance } from 'axios';
import { User } from 'oidc-client';

export const validUser = (user: User | undefined | null): boolean => {
    return !!user && !user.expired;
};

export const isRedirectUrl = (location: Location): boolean => {
    const searchParams = new URLSearchParams(location.search);
    const hashParams = new URLSearchParams(location.hash.replace('#', '?'));

    return Boolean(
        searchParams.get('code') ||
            searchParams.get('id_token') ||
            searchParams.get('session_state') ||
            hashParams.get('code') ||
            hashParams.get('id_token') ||
            hashParams.get('session_state')
    );
};

export const setAxiosHeader = (axios: AxiosInstance, user: User) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.id_token;
};

// User profile may include multiple UPNs if multiple IDPs have been used to sign in
export const getUpn = (
    user: User | undefined,
    domain: string = 'microsoft.com'
): string | undefined => {
    const upnClaim: string | string[] | undefined = user?.profile.upn;
    if (upnClaim instanceof Array) {
        return upnClaim.find((u) => u.endsWith(domain));
    } else if (upnClaim?.endsWith(domain)) {
        return upnClaim;
    }
};
