import { IconButton, Stack } from '@fluentui/react';
import React, { useContext } from 'react';
import { Deployment, DeploymentMetadataItem } from '../../../models/Deployment';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { useIsMobile } from '../../../../Common/hooks/useIsMobile';

interface IDeploymentMetadataProps {
    item: Deployment;
}

export const DeploymentMetadata: React.FC<IDeploymentMetadataProps> = (
    props: IDeploymentMetadataProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const isMobile = useIsMobile();

    const metadataFieldNameStyles = {
        width: '10%',
        paddingRight: '5px',
    };

    const metadataValueStyles = {
        display: 'flex',
        width: '90%',
        border: '1px gray solid',
        paddingLeft: '5px',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const metadataContainerStyles = {
        display: 'flex',
        paddingLeft: '16px',
        paddingBottom: '5px',
        paddingTop: '10px',
        alignItems: 'center',
    };

    const mobileMetadataContainerStyles: React.CSSProperties = {
        padding: '6px 0px',
    };

    const mobileMetadataValueStyles: React.CSSProperties = {
        whiteSpace: 'normal',
        wordBreak: 'break-all',
        display: 'inline-block',
    };

    const mobileMetadataValueContainerStyles: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
        border: '1px gray solid',
        justifyContent: 'space-between',
        margin: '6px 0px',
        padding: '6px',
    };

    const copyToClipboard = (metadata: DeploymentMetadataItem) => {
        navigator.clipboard.writeText(metadata.value);
    };

    return props.item.showMetadata ? (
        <div
            style={
                isMobile
                    ? {}
                    : {
                          backgroundColor: theme.palette.neutralLighter,
                          paddingLeft: '40px',
                      }
            }
        >
            {Array.from(props.item.deploymentMetadataItems.values()).map(
                (metadata) => {
                    return metadata?.value?.length > 0 ? (
                        <div
                            key={metadata.fieldName}
                            style={
                                isMobile
                                    ? mobileMetadataContainerStyles
                                    : metadataContainerStyles
                            }
                        >
                            <strong style={metadataFieldNameStyles}>
                                {metadata.fieldName}
                            </strong>
                            <div
                                style={
                                    isMobile
                                        ? mobileMetadataValueContainerStyles
                                        : metadataValueStyles
                                }
                            >
                                <div
                                    style={
                                        isMobile
                                            ? mobileMetadataValueStyles
                                            : undefined
                                    }
                                >
                                    {metadata.value}
                                </div>
                                <IconButton
                                    iconProps={{ iconName: 'Copy' }}
                                    onClick={() => copyToClipboard(metadata)}
                                    title={'Copy to clipboard'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div key={metadata?.fieldName}></div>
                    );
                }
            )}
        </div>
    ) : (
        <div></div>
    );
};
