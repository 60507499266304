import React, { useContext } from 'react';
import { ThemeContext } from '../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../Common/util/localStorageUtils';
import { Stack, Text } from '@fluentui/react';

interface IAdminComponentCompactProps {
    title: string;
}

export const AdminComponentCompact: React.FC<IAdminComponentCompactProps> = (
    props: IAdminComponentCompactProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    return (
        <div className="AdminCompactDetails-root">
            <Stack
                style={{
                    display: 'flex',
                }}
            >
                <Text variant="xLarge">{props.title}</Text>
            </Stack>
        </div>
    );
};
