import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    IStackTokens,
    Icon,
    Spinner,
    Stack,
    Text,
    TextField,
} from '@fluentui/react';
import { DefaultHubButton } from '../../../HubLayout/components/Utilities/DefaultHubButton';
import { postReleaseImport } from '../../../AGSS/api/AGSSApi';
import { CancelToken } from 'axios';
import { usePromise } from '../../../Common/hooks/usePromise';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getReleaseDetailsPath } from '../../../AGSS/util/ReleaseUtils';
import {
    GlobalConfigProperties,
    getConfig,
} from '../../../Common/api/ABHub/ABHubGlobalConfig';

interface IReleaseImporterProps {}

export const ReleaseImporter: React.FC<IReleaseImporterProps> = (
    props: IReleaseImporterProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const environment = getConfig(GlobalConfigProperties.Environment);

    const stackTokens: IStackTokens = { childrenGap: 10 };
    const [selectedFile, setSelectedFile] = useState<File | undefined>(
        undefined
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [validEnv, setValidEnv] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            // Update the selected file when the input value changes
            setSelectedFile(event.target.files[0]);

            // Perform additional logic here after the user selects a file
            console.log('File selected:', event.target.files[0].name);
        }
    };

    const openFilePicker = () => {
        // Trigger the file input click event
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const importRelease = useCallback(
        async (cancelToken?: CancelToken) => {
            console.log('Importing file');
            if (selectedFile) {
                console.log("Selected file isn't undefined");
                // Create a FormData object to store the file
                const formData = new FormData();
                formData.append('request', selectedFile);

                const result = await postReleaseImport(
                    selectedFile,
                    cancelToken
                );

                console.log(result);
                return result;
            } else {
                console.log('No file selected');
            }
        },
        [selectedFile]
    );

    const [result, error, isLoaded, start, cancel] = usePromise(
        importRelease,
        false
    );

    const handleImportClick = () => {
        setIsLoading(true);
        start();
    };

    const handleCancelClick = () => {
        setSelectedFile(undefined);
        cancel();
    };

    const parseLinesAndTabs = (value: string) =>
        value.replace(/(\\n)/g, '<br>').replace(/(\\t)/g, '&nbsp;');

    useEffect(() => {
        if (
            environment === 'Development' ||
            environment === 'Test' ||
            environment === 'public.sovmock'
        ) {
            setValidEnv(true);
        }
    }, [environment]);

    useEffect(() => {
        if (result || error) {
            setIsLoading(false);
            setSelectedFile(undefined);
        }
    }, [result, error]);

    return (
        <div className="ReleaseImporter-root">
            {validEnv && (
                <Stack tokens={stackTokens}>
                    {!selectedFile && (
                        <Stack>
                            <DefaultHubButton
                                buttonText="Select Import File"
                                icon="FileCode"
                                onClick={openFilePicker}
                            />

                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </Stack>
                    )}

                    {/* Display selected file name */}
                    {selectedFile && (
                        <Stack>
                            <p>Selected File: {selectedFile?.name}</p>
                            {!isLoading ? (
                                <Stack horizontal tokens={stackTokens}>
                                    <DefaultHubButton
                                        buttonText="Import Release"
                                        icon="CloudUpload"
                                        onClick={handleImportClick}
                                        disabled={!selectedFile}
                                    />

                                    <DefaultHubButton
                                        buttonText="Cancel"
                                        icon="Cancel"
                                        fontColor={
                                            theme.semanticColors.errorIcon
                                        }
                                        borderColor={
                                            theme.semanticColors.errorIcon
                                        }
                                        onClick={handleCancelClick}
                                    />
                                </Stack>
                            ) : (
                                <Spinner
                                    style={{
                                        width: '300px',
                                    }}
                                />
                            )}
                        </Stack>
                    )}

                    {(result || error) && !selectedFile && (
                        <Stack
                            horizontal
                            tokens={stackTokens}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Icon
                                iconName={
                                    result
                                        ? 'SkypeCircleCheck'
                                        : 'StatusErrorFull'
                                }
                                style={{
                                    color: result
                                        ? theme.semanticColors.successIcon
                                        : theme.semanticColors.errorIcon,
                                }}
                            />
                            {result ? (
                                <Text variant="medium">
                                    Release Imported Successfully
                                </Text>
                            ) : (
                                <Text variant="medium">
                                    Release Import Failed
                                </Text>
                            )}
                        </Stack>
                    )}

                    {result && !selectedFile && (
                        <Stack tokens={stackTokens}>
                            <DefaultHubButton
                                buttonText="View Release"
                                icon="View"
                                onClick={() => {
                                    window.location.href =
                                        '#' +
                                        getReleaseDetailsPath(
                                            result.data ?? ''
                                        );
                                }}
                            />

                            {showResult ? (
                                <Stack tokens={stackTokens}>
                                    <DefaultHubButton
                                        buttonText="Hide Import Result"
                                        icon="Hide"
                                        onClick={() => setShowResult(false)}
                                    />
                                    <TextField
                                        readOnly
                                        multiline
                                        type="text"
                                        resizable
                                        value={parseLinesAndTabs(
                                            result.message ?? ''
                                        )}
                                    />
                                </Stack>
                            ) : (
                                <DefaultHubButton
                                    buttonText="Show Import Result"
                                    icon="EntryView"
                                    onClick={() => setShowResult(true)}
                                />
                            )}
                        </Stack>
                    )}
                </Stack>
            )}
        </div>
    );
};
