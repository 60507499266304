import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
} from 'react';
import { CancelToken } from 'axios';
import { publishCommand } from '../../../AGCP/api/AGCPApi';
import { CapabilityContext } from '../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../Common/components/Capabilities/capability';
import { ActionButton } from '../../../Common/components/ActionButton';
import { dateLocaleStringWithTimeZone } from '../../../Common/util/DateUtils';
import { Artifact } from '../../models/Artifact';

interface IValidateArtifactProps {
    artifact?: Artifact;
    setCompletionMessage?: Dispatch<SetStateAction<string>>;
    isMenuItem?: boolean;
}

export const ValidateArtifactButton: React.FC<IValidateArtifactProps> = (
    props: IValidateArtifactProps
) => {
    // User must have Artifact.Activate role to see the button
    const capabilities = useContext(CapabilityContext);

    // Setup replicate build publish
    const publishPromise = useCallback(
        async (cancelToken?: CancelToken) => {
            // TODO: Actually build the message that is needed to send.
            if (props.artifact?.artifactId) {
                const artifactId = props.artifact.artifactId;

                const data = await publishCommand(
                    {
                        artifactId: artifactId,
                        environmentId: 'public',
                    },
                    'AGSS-ActivateArtifactHashesCommand',
                    'public', // Constant
                    cancelToken
                );

                if (data.response.succeeded && props.setCompletionMessage) {
                    const messageData = JSON.parse(data.message.data || '');
                    const artifactId = messageData
                        ? messageData['artifactId']
                        : '';
                    props.setCompletionMessage(
                        'Activate Files command sent for ' +
                            artifactId +
                            ' at ' +
                            dateLocaleStringWithTimeZone(
                                data.message.eventTime
                            ) +
                            '.'
                    );
                }
                if (!data.response.succeeded) {
                    if (data.response.message) {
                        throw new Error(data.response.message);
                    } else {
                        throw new Error(
                            'There was an error sending the activate files command.'
                        );
                    }
                }
            }
        },
        [props]
    );

    return capabilities.check(Capability.artifactActivateRole) ? (
        <div className="ValidateArtifactButton-root">
            <ActionButton
                onClick={publishPromise}
                initialButtonText={'Activate Files'}
                initialButtonIcon={'ReceiptCheck'}
                showConfirmationPopup={true}
                modalText={
                    'Are you sure you want to activate all the files in this artifact?'
                }
                isMenuItem={props.isMenuItem}
            />
        </div>
    ) : null;
};
