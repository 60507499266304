import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    Dispatch,
    SetStateAction,
} from 'react';
import {
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    IDetailsRowProps,
    IRenderFunction,
    DetailsRow,
    IconButton,
} from '@fluentui/react';
import { FilterBox } from '../../../../Common/components/FilterBox';
import {
    columnSort,
    commonGridStyle,
    filterList,
} from '../../../../Common/util/tableUtils';
import {
    dateLocaleStringWithTimeZone,
    calculateTimeDifference,
} from '../../../../Common/util/DateUtils';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { Deployment } from '../../../models/Deployment';
import { ServiceType } from '../../../../ABSM/models/ServiceType';
import { Guid } from '../../../../Common/models/Guid';
import { CompletionIndicatorIcon } from './Status/CompletionIndicatorIcon';
import { ReplicationStatusDisplay } from '../../Artifact/ReplicationStatusDisplay';
import { DeploymentMetadata } from './DeploymentMetadata';
import { AgrmContextButton } from '../../Deployment/AgrmContextButton';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../../Common/api/ABHub/ABHubGlobalConfig';
import { RestartAgrmProcessButton } from '../../Deployment/RestartAgrmProcessButton';
import { AgrmType } from '../../../api/AGRMApi';
import { Build } from '../../../models/Build';
import { emitUserActionMetric } from '../../../../Common/util/metricsUtil';
import {
    ActionResult,
    ToggleMode,
    UserAction,
} from '../../../../Common/Enums/Metrics/MetricEnums';

interface IDeploymentListProps {
    list: Deployment[];
    isLoaded: boolean;
    setSelectedDeployment: Dispatch<
        SetStateAction<Deployment | Build | undefined>
    >;
    showPanel: () => void;
    backgroundColor?: string;
    showFilterBox?: boolean;
    serviceId?: Guid;
    serviceType?: ServiceType;
}

export const DeploymentList: React.FC<IDeploymentListProps> = (
    props: IDeploymentListProps
) => {
    const [columnsList, setColumnsList] = useState<IColumn[]>([]);
    const [sortedItems, setSortedItems] = useState<Deployment[]>([]); //full sorted list
    const [items, setItems] = useState<Deployment[]>([]); // current version of the list - filtered or sorted

    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    //need this because the onColumnClick function would only use the filterValue
    //from when it was instantiated
    const filterValueRef = useRef<string>();

    const onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn
    ): void => {
        // Sort the entire list, then reapply the filter
        const sortedDeployments = columnSort(column, columns, props.list);
        setColumnsList(sortedDeployments.newColumns);
        setSortedItems(sortedDeployments.newItems);
        filterList(
            filterItems,
            setItems,
            sortedDeployments.newItems,
            filterValueRef.current || ''
        );
    };
    const onViewDetailsClick = (deployment: Deployment) => {
        props.setSelectedDeployment(deployment);
        props.showPanel();
        emitUserActionMetric(
            UserAction.DEPLOYMENT_FLYOUT,
            ToggleMode.TOGGLE,
            ActionResult.SUCCESS,
            process.env.REACT_APP_BUILD_VERSION
        );
    };

    const restartDeploymentFlag =
        getConfig(GlobalConfigProperties.RestartAgrmActive) === 'true'
            ? true
            : false;

    const columns: IColumn[] = [
        {
            key: 'showMetadata',
            name: '',
            minWidth: 20,
            maxWidth: 20,
        },
        {
            key: 'statusIndicator',
            name: '',
            minWidth: 20,
            maxWidth: 20,
        },
        {
            key: 'flightData',
            name: '',
            minWidth: 20,
            maxWidth: 20,
        },
        {
            key: 'displayNameParsed',
            name: 'Name',
            minWidth: 380,
            maxWidth: 380,
            isResizable: true,
            fieldName: 'displayNameParsed',
            onColumnClick: onColumnClick,
        },
        {
            key: 'deploymentType',
            name: 'Deployment Type',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            fieldName: 'deploymentType',
            onColumnClick: onColumnClick,
        },
        {
            key: 'timeStarted',
            name: 'Time Started',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'environment',
            name: 'Environment',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'attempts',
            name: '# Attempts',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'duration',
            name: 'Duration',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'statusAndError',
            name: 'Latest Status',
            minWidth: 180,
            maxWidth: 180,
            isResizable: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'restartDeployment',
            name: '',
            minWidth: 60,
            maxWidth: 60,
        },
        {
            key: 'moreEllipsis',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            onColumnClick: onColumnClick,
        },
    ];

    const toggleShowMetadata = (item: Deployment) => {
        item.showMetadata = !item.showMetadata;
        setItems(items.concat([]));
    };

    const renderDeploymentColumn = (
        item: Deployment,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[
            column?.fieldName as keyof Deployment
        ] as string;

        switch (column?.key) {
            case 'showMetadata':
                return (
                    item.deploymentMetadataItems.size > 0 && (
                        <IconButton
                            iconProps={{
                                iconName: item.showMetadata
                                    ? 'ChevronDownMed'
                                    : 'ChevronRightMed',
                            }}
                            onClick={() => toggleShowMetadata(item)}
                        />
                    )
                );

            case 'statusIndicator':
                return (
                    <CompletionIndicatorIcon
                        completionIndicator={item.completionIndicator}
                        completionIndicatorString={
                            item.completionIndicatorString
                        }
                        theme={theme}
                    />
                );
            case 'flightData':
                return (
                    item.deploymentMetadataItems.size > 0 && (
                        <IconButton
                            iconProps={{
                                iconName: 'AirplaneSolid',
                            }}
                            onClick={() => onViewDetailsClick(item)}
                        />
                    )
                );
            case 'displayNameParsed':
                return <div>{item.displayNameParsed}</div>;

            case 'statusAndError':
                return (
                    <ReplicationStatusDisplay
                        replication={item.currentReplication}
                    />
                );

            case 'restartDeployment':
                return (
                    restartDeploymentFlag && (
                        <RestartAgrmProcessButton
                            uniqueIdentifier={item.uniqueIdentifier}
                            highSideReset={
                                item?.currentReplication?.highSideReset
                            }
                            type={AgrmType.Deployment}
                        />
                    )
                );

            case 'moreEllipsis':
                return (
                    <AgrmContextButton
                        uniqueIdentifier={item.uniqueIdentifier}
                        type={AgrmType.Deployment}
                    />
                );

            case 'deploymentType':
                return (
                    <div>
                        {item.deploymentType ? item.deploymentType : 'Not Set'}
                    </div>
                );
            case 'timeStarted':
                if (item.currentReplication) {
                    const currentRep = item.currentReplication;
                    if (currentRep.createdOn) {
                        return dateLocaleStringWithTimeZone(
                            currentRep.createdOn
                        );
                    }
                }
                return '';
            case 'environment':
                return <div>{item.currentReplication?.environmentId}</div>;

            case 'attempts':
                return <div>{item.replications.length}</div>;

            case 'duration':
                if (item.currentReplication) {
                    const currentRep = item.currentReplication;

                    if (
                        currentRep.createdOn &&
                        currentRep.currentStatus?.updatedOn
                    ) {
                        return currentRep.completionIndicatorString !==
                            'Processing'
                            ? calculateTimeDifference(
                                  currentRep.createdOn,
                                  currentRep.currentStatus.updatedOn
                              )
                            : calculateTimeDifference(
                                  currentRep.createdOn,
                                  new Date().toUTCString()
                              );
                    }
                }

                return '';
            default:
                return <span>{fieldContent}</span>;
        }
    };

    const renderDeploymentRow: IRenderFunction<IDetailsRowProps> | undefined = (
        props?: IDetailsRowProps | undefined
    ) => {
        if (props) {
            const item = props.item as Deployment;

            return (
                <div>
                    <DetailsRow {...props} />
                    <DeploymentMetadata item={item} />
                </div>
            );
        }

        return <></>;
    };

    //filterFunction
    const filterItems = (i: Deployment, newValue: string) => {
        return (
            i.displayName.toLowerCase().includes(newValue) ||
            i.deploymentType.toLowerCase().includes(newValue) ||
            i.currentStatus?.name.toLowerCase().includes(newValue)
        );
    };

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        let deployments = props.list;
        setColumnsList(columns);
        setItems(deployments);
        setSortedItems(deployments);
        // needs columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.list, props.isLoaded]);

    // Remove the showMetadata column to prevent empty space next to deployment
    useEffect(() => {
        items.map((item) => {
            if (item.deploymentMetadataItems.size === 0) {
                setColumnsList(
                    columnsList.filter(
                        (column) => column.key !== 'showMetadata'
                    )
                );
            }
        });
    }, [items]);

    return (
        <div className="DeploymentList-root">
            {props.showFilterBox && (
                <FilterBox
                    style={{ backgroundColor: props.backgroundColor }}
                    items={sortedItems}
                    setItems={setItems}
                    filterFunc={filterItems}
                    filterValueRef={filterValueRef}
                />
            )}

            <ShimmeredDetailsList
                className={commonGridStyle(theme, props.backgroundColor)}
                items={items}
                columns={columnsList}
                onRenderItemColumn={renderDeploymentColumn}
                onRenderRow={renderDeploymentRow}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={!props.isLoaded}
                shimmerLines={10}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Deployments"
            />
            {props.isLoaded && !props.list.length && <p>No data found.</p>}
        </div>
    );
};
