import { User } from 'oidc-client';
import { decodeJwt, getClaimFromJwt, jwtHasClaim } from '../../util/tokenUtils';
import { validUser } from '../../../HubLayout/util/authUtils';
import { Capability, CapabilityState, getCapabilityState } from './capability';
import { UnauthorizedError } from '../../errors/UnauthorizedError';
import { AuthorizationFailureError } from '../../errors/AuthorizationFailureError';
import { Guid } from '../../models/Guid';

//convert true/false string to capability state
// Note the difference between a false value and the value missing entirely
export const convertTrueFalseValueToCapabilityState = (
    value: string | undefined
): CapabilityState => {
    if (value) {
        if (value === 'true') {
            return CapabilityState.available;
        } else if (value === 'false') {
            return CapabilityState.unavailable;
        }
    }
    return CapabilityState.unknown;
};

//determine claim value from user token
export const getTrueFalseClaimValue = (
    user: User | undefined,
    claimType: string
): CapabilityState => {
    if (user && validUser(user)) {
        const jwt = decodeJwt(user.id_token);
        const claimValue = getClaimFromJwt(jwt, claimType);
        return convertTrueFalseValueToCapabilityState(claimValue);
    }
    return CapabilityState.unknown;
};

// Determines if a claim with the specified value is present
export const getSpecificClaimValue = (
    user: User | undefined,
    claimType: string,
    claimValue: string
): CapabilityState => {
    if (user && validUser(user)) {
        const jwt = decodeJwt(user.id_token);
        return jwtHasClaim(jwt, claimType, claimValue)
            ? CapabilityState.available
            : CapabilityState.unavailable;
    }
    return CapabilityState.unknown;
};

// Check tenting for the provided user. Returns [isAuthorized, error].
// (Providing the user rather than reading from the CapabilityContext avoids a timing issue
// when this check is done before the context state is updated by AuthRequired.)
export const checkTenting = (
    user: User | undefined
): [boolean, Error | null] => {
    if (validUser(user)) {
        const state = { agpUser: user };
        const tents = [
            getCapabilityState(Capability.ex_tent, state),
            getCapabilityState(Capability.rx_tent, state),
        ];

        if (tents.some((t) => t === CapabilityState.available)) {
            // user is authorized so long as one tenting claim is available
            return [true, null];
        } else if (tents.some((t) => t === CapabilityState.unavailable)) {
            // user is not authorized
            return [false, new UnauthorizedError()];
        } else {
            // all claims are unknown, authorization cannot be determined
            return [false, new AuthorizationFailureError()];
        }
    } else {
        return [false, null];
    }
};

// Get authorized service tree ids from user token
export const getAuthorizedServiceIds = (
    isUserAuthorized: boolean,
    user: User | undefined
): Guid[] => {
    if (user && isUserAuthorized) {
        const jwt = decodeJwt(user.id_token);
        const claimValue = getClaimFromJwt(jwt, 'service_tree_ids');
        try {
            return claimValue.split(',');
        } catch (error) {
            //console.warn(claimValue, error);
            if (claimValue && claimValue[0]) {
                return claimValue[0].split(',');
            }
            return [];
        }
    }
    return [];
};
