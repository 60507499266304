import { DefaultEffects, mergeStyleSets } from '@fluentui/react';
import React, { useContext } from 'react';
import { dateLocaleStringWithTimeZone } from '../../../../Common/util/DateUtils';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { Build } from '../../../models/Build';

export interface IBuildInstanceDetailsProps {
    build: Build | undefined;
}

export const BuildInstanceDetails: React.FC<IBuildInstanceDetailsProps> = (
    props: IBuildInstanceDetailsProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const styles = mergeStyleSets({
        metadataPanel: {
            backgroundColor:
                themeContext.themeName === 'dark'
                    ? theme.palette.neutralLighter
                    : theme.palette.themeLighterAlt,
            boxShadow: DefaultEffects.elevation4,
            padding: 5,
            marginBottom: 10,
            color: theme.palette.neutralSecondary,
            selectors: {
                table: {
                    width: '100%',
                },
                td: {
                    padding: 4,
                },
            },
        },
        label: {
            width: 120,
            fontWeight: 600,
            color: theme.palette.neutralPrimary,
        },
    });

    const metadata = (label: string, value: any): JSX.Element => (
        <>
            <td className={styles.label}> {label}: </td>
            <td> {value} </td>
        </>
    );

    return (
        <div className="BuildInstanceDetails-root">
            {props.build && (
                <div className={styles.metadataPanel}>
                    <table>
                        <tbody>
                            <tr>
                                {metadata(
                                    'ADO Build Id',
                                    props.build.adoBuildId
                                )}
                                {metadata(
                                    'Artifact Alias',
                                    props.build.buildArtifactAlias
                                )}
                                {metadata('Branch', props.build.buildBranch)}
                            </tr>
                            <tr>
                                {metadata(
                                    'CVRP Manifest Relative Path',
                                    props.build.buildCvrpManifestRelativePath
                                )}
                                {props.build.currentReplication &&
                                    metadata(
                                        'Last Updated',
                                        dateLocaleStringWithTimeZone(
                                            props.build.currentReplication
                                                .lastUpdated
                                        )
                                    )}
                                {metadata(
                                    'Component Name',
                                    props.build.buildComponentName
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
