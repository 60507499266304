import React from 'react';

interface IJsonResultProps {
    data: any;
}

export const JsonResult: React.FC<IJsonResultProps> = (
    props: IJsonResultProps
) => {
    return (
        <div>
            {props.data && (
                <div
                    style={{
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                        marginTop: '10px',
                    }}
                >
                    <pre
                        style={{
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                        }}
                    >
                        {JSON.stringify(props.data, null, 2)}
                    </pre>
                </div>
            )}
        </div>
    );
};
