import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { IAuthContext, AuthContext } from '../../models/AuthContext';
import {
    agpAxiosClient,
    getAgpOidcConfig,
} from '../../../Common/api/ABHub/ABHubTokenConfig';
import { getGraphOidcConfig } from '../../../Common/api/GraphApi/GraphTokenConfig';
import { useAuthentication } from '../../../Common/hooks/useAuthentication';
// Imported Components
import { HubApp } from '../../../HubApp';
import { UnauthorizedPage } from './UnauthorizedPage';
import { LoadingPage } from './LoadingPage';
import { validUser } from '../../util/authUtils';
import { UserManager } from 'oidc-client';
import {
    CapabilityActionType,
    CapabilityDispatch,
} from '../../../Common/components/Capabilities/capabilityReducer';
import { getAuthorizedServiceIds } from '../../../Common/components/Capabilities/capabilityUtils';
import { CancelToken } from 'axios';
import {
    getUserProfileData,
    setUserProfileData,
} from '../../../Common/api/ABHub/UserProfileApi';
import { usePromise } from '../../../Common/hooks/usePromise';
import { sendMetric } from '../../../Common/api/ABHub/MetricsSendApi';

interface IAuthRequiredProps extends PropsWithChildren<any> {
    pageLoadTime: number;
}

export const AuthRequired: React.FC<IAuthRequiredProps> = (
    props: IAuthRequiredProps
) => {
    const [agpUser, agpError, getAgpUser, resetAgpUser] = useAuthentication(
        getAgpOidcConfig(),
        [agpAxiosClient]
    );

    const authErrorMessagesToIgnore = ['No matching state found in storage'];

    const capabilityDispatch = useContext(CapabilityDispatch);

    const isUserAuthorized: boolean = validUser(agpUser);
    const authError: Error | null = agpError || null;
    // For service-oriented update
    const authorizedServiceIds = getAuthorizedServiceIds(
        isUserAuthorized,
        agpUser
    );

    const [dbUpdated, setDbUpdated] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const getUserProfile = useCallback(
        async (cancelToken?: CancelToken) => {
            if (validUser(agpUser) && agpUser) {
                const user = await getUserProfileData(
                    agpUser?.profile?.upn?.split('@')[0] ?? '',
                    cancelToken
                );

                if (!user) {
                    const newUser = await setUserProfileData(
                        agpUser.profile?.upn?.split('@')[0] ?? '',
                        undefined,
                        cancelToken
                    );

                    return newUser;
                }

                return user;
            }
        },
        [agpUser]
    );

    const [user, error, isLoaded, start] = usePromise(getUserProfile, false);

    // Login AGP immediately
    // Do nothing if there's a current authError - the user must click Login to try again
    useEffect(() => {
        if (!authError && !validUser(agpUser)) {
            getAgpUser();
        }
    }, [agpUser, authError, getAgpUser]);

    // Set agpUser in capabilities context, which will be used to determine tenting
    useEffect(() => {
        if (!authError && validUser(agpUser)) {
            capabilityDispatch({
                type: CapabilityActionType.setAgpUser,
                user: agpUser,
            });
        }
    }, [agpUser, authError, capabilityDispatch]);

    // emit metric for first time user visits page
    useEffect(() => {
        if (isFirstLoad && validUser(agpUser) && props.pageLoadTime !== -1) {
            //emit metric for load time
            //emit metric for user visit
            const dimensions = {
                Browser: window.navigator.userAgent,
                BrowserVersion: window.navigator.appVersion,
                PortalVersion: process.env.REACT_APP_BUILD_VERSION,
                PageName: 'Home',
            };

            sendMetric(
                props.pageLoadTime,
                'PageVisit',
                'AzureBridgeHub',
                dimensions
            );
            setIsFirstLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agpUser, props.pageLoadTime]);

    // Grab user profile data
    useEffect(() => {
        if (validUser(agpUser)) {
            start();
        }

        if (error) {
            console.error(error);
        }
    }, [agpUser]);

    const logout = async () => {
        // Clear every cached token we have on logout
        await new UserManager(getGraphOidcConfig()).removeUser();
        await new UserManager(getAgpOidcConfig()).signoutRedirect(); // sign out AG-STS and AAD
    };

    const [authContext, setAuthContext] = useState<IAuthContext>({
        login: async () => {
            await resetAgpUser(); // Force new signin
            await getAgpUser();
        },
        logout: logout,
        user: agpUser,
        isUserAuthorized: isUserAuthorized,
        authorizedServiceIds: authorizedServiceIds,
        userProfile: user,
    });

    useEffect(() => {
        function fetchData() {
            setAuthContext({
                login: async () => {
                    await resetAgpUser(); // Force new signin
                    await getAgpUser();
                },
                logout: logout,
                user: agpUser,
                isUserAuthorized: isUserAuthorized,
                authorizedServiceIds: authorizedServiceIds,
                userProfile: user,
            });
        }

        if (isLoaded) {
            fetchData();
            setIsUpdated(true);
        }
    }, [agpUser, user, isLoaded]);

    // If UI sent updates to the database, update it immediately
    useEffect(() => {
        if (dbUpdated) {
            setIsUpdated(false);

            start();
            setDbUpdated(false);

            if (isLoaded) {
                setAuthContext({ ...authContext, userProfile: user });
                setIsUpdated(true);
            }
        }
    }, [dbUpdated, authContext, user, isLoaded]);

    return (
        <AuthContext.Provider value={{ authContext, setDbUpdated, isUpdated }}>
            {authError &&
            !authErrorMessagesToIgnore.includes(authError.message) ? (
                <UnauthorizedPage error={authError} />
            ) : isUserAuthorized ? (
                <HubApp />
            ) : (
                <LoadingPage />
            )}
        </AuthContext.Provider>
    );
};
