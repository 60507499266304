import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardPreview,
    DocumentCardTitle,
    DocumentCardType,
    IStackTokens,
    Stack,
} from '@fluentui/react';
import React, { useContext } from 'react';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { convertToUserFriendlyByteString } from '../../../../Common/util/tableUtils';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { Build } from '../../../models/Build';
import {
    documentCardStyles,
    previewPropsUsingIcon,
} from '../../../util/StyleUtils';

interface IBuildMetricsProps {
    data: Build | undefined;
}

export const BuildMetrics: React.FC<IBuildMetricsProps> = (
    props: IBuildMetricsProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const sectionStackTokens: IStackTokens = { childrenGap: 20 };

    return (
        <div className="BuildMetrics-root">
            {props.data && (
                <Stack wrap horizontal tokens={sectionStackTokens}>
                    <DocumentCard
                        aria-label="Total Files"
                        title="Total Files"
                        type={DocumentCardType.normal}
                        className={
                            documentCardStyles(theme, themeContext.themeName)
                                .card
                        }
                    >
                        <DocumentCardPreview
                            {...previewPropsUsingIcon(
                                'Documentation',
                                theme,
                                themeContext.themeName
                            )}
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title={
                                    'Total Files: ' +
                                    props.data.numReplicationPlanFiles
                                }
                                className={
                                    documentCardStyles(
                                        theme,
                                        themeContext.themeName
                                    ).title
                                }
                            />
                        </DocumentCardDetails>
                    </DocumentCard>
                    <DocumentCard
                        aria-label="Total Bytes"
                        title="Total Bytes"
                        type={DocumentCardType.normal}
                        className={
                            documentCardStyles(theme, themeContext.themeName)
                                .card
                        }
                    >
                        <DocumentCardPreview
                            {...previewPropsUsingIcon(
                                'PieDouble',
                                theme,
                                themeContext.themeName
                            )}
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title={
                                    'Total Bytes: ' +
                                    convertToUserFriendlyByteString(
                                        props.data.totalArtifactBytes
                                    )
                                }
                                className={
                                    documentCardStyles(
                                        theme,
                                        themeContext.themeName
                                    ).title
                                }
                            />
                        </DocumentCardDetails>
                    </DocumentCard>
                </Stack>
            )}
        </div>
    );
};
