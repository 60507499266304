import React, { useCallback, useEffect, useState } from 'react';
import { Stack, TextField } from '@fluentui/react';
import { usePromise } from '../../../../Common/hooks/usePromise';
import { DefaultLoadingHubButton } from '../../../../HubLayout/components/Utilities/DefaultLoadingHubButton';
import { JsonResult } from '../../../../HubLayout/components/Utilities/JsonResult';
import { deactivateArtifact } from '../../../../AGSS/api/AGSSApi';

interface IDeactivateArtifactProps {}

export const DeactivateArtifact: React.FC<IDeactivateArtifactProps> = (
    props: IDeactivateArtifactProps
) => {
    const stackTokens = { childrenGap: 10 };

    const [artifactIdValue, setArtifactIdValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleArtifactIdChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined
    ) => {
        setArtifactIdValue(newValue ?? '');
    };

    const deaActivateArtifactCallback = useCallback(async () => {
        if (artifactIdValue) {
            const result = await deactivateArtifact(artifactIdValue);
            setIsLoading(false);
            return result;
        }
    }, [artifactIdValue]);

    const [result, error, isLoaded, start, cancel] = usePromise(
        deaActivateArtifactCallback,
        false
    );

    const handleActivateArtifactClick = () => {
        setIsLoading(true);
        start();
    };

    return (
        <Stack tokens={stackTokens}>
            <TextField
                label="Artifact ID"
                placeholder="Enter the artifact ID"
                value={artifactIdValue}
                onChange={handleArtifactIdChange}
            />

            <DefaultLoadingHubButton
                horizontal
                buttonText="Deactivate Artifact"
                icon="ReceiptUndelivered"
                result={result}
                error={error}
                isLoading={isLoading}
                disabled={!artifactIdValue}
                onClick={handleActivateArtifactClick}
            />

            <JsonResult data={result} />
        </Stack>
    );
};
