import React, { useCallback, useEffect, useState, RefObject } from 'react';
import { BuildList } from './Display/BuildList';
import { usePromise } from '../../../Common/hooks/usePromise';
import { usePrevious } from '../../../Common/hooks/usePrevious';
import { CancelToken } from 'axios';
import { getBuildList } from '../../api/AGSSApi';
import { BuildSearchResult } from '../../models/Build';
import { ErrorPage } from '../../../HubLayout/components/ErrorPage';
import { emitPageLoadMetric } from '../../../Common/util/metricsUtil';
import { ServiceType } from '../../../ABSM/models/ServiceType';

interface IBuildListPageProps {
    scrollBarRef?: RefObject<HTMLDivElement>;
    scrollTop: number;
    serviceType?: ServiceType;
}
export const BuildListPage: React.FC<IBuildListPageProps> = (
    props: IBuildListPageProps
) => {
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hideButton, setHideButton] = useState<boolean>(false);
    const prevPageNumber = usePrevious(pageNumber);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const getData = useCallback(
        async (cancelToken?: CancelToken) => {
            const builds: BuildSearchResult[] = await getBuildList(
                selectedServices,
                //selectedEnvironments,
                selectedProjects,
                searchValue,
                pageNumber,
                cancelToken
            );
            // don't reorder, the server orders depending on params
            return builds;
        },
        // When dependency array changes, usePromise will clear data and restart API.
        [
            selectedServices,
            //selectedEnvironments,
            selectedProjects,
            searchValue,
            pageNumber,
        ]
    );
    const [heightDepth, setHeightDepth] = useState<number>(0);
    const [result, error, isLoaded] = usePromise(getData, true);
    // const data = result || [];
    const [data, setData] = useState<BuildSearchResult[]>([]);

    useEffect(() => {
        if (pageNumber === 1 && result) {
            if (prevPageNumber !== pageNumber) {
            } else {
                if (result.length !== 0) {
                    setData(result);
                }
                //otherwise return an empty array
                else {
                    result.splice(0, result.length);
                    setData(result);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);

    useEffect(() => {
        setHeightDepth(0);
    }, [selectedServices, selectedProjects, searchValue]);

    useEffect(() => {
        if (pageNumber > 1 && result) {
            if (result.length === 0) {
                setHideButton(true);
            } else {
                const newList = data.concat(result);
                setData(newList);
            }
            setHideButton(false);
        }
    }, [pageNumber && result]);

    const scrollMultiplier = 1.1;

    useEffect(() => {
        setTimeout(() => {
            if (props.scrollBarRef && props.scrollBarRef.current) {
                props.scrollBarRef.current.scrollTo(
                    0,
                    scrollMultiplier * heightDepth
                );
            }
        }, 50);
    }, [data]);

    useEffect(() => {
        if (props.scrollTop > heightDepth) {
            setHeightDepth(props.scrollTop);
        }
    }, [props.scrollTop]);

    // metric to measure inital page load time
    useEffect(() => {
        var emittedSuccessfully = emitPageLoadMetric(
            'Build',
            process.env.REACT_APP_BUILD_VERSION,
            isFirstLoad
        );

        setIsFirstLoad(emittedSuccessfully);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return error ? (
        <ErrorPage error={error} />
    ) : (
        <div
            className="BuildListPage-root"
            style={{
                padding: '2px 2px 0px 2px',
                border: 'none',
            }}
        >
            <BuildList
                list={data}
                isLoaded={isLoaded}
                hideButton={hideButton}
                setSearchValue={setSearchValue}
                setSelectedServices={setSelectedServices}
                setSelectedProjects={setSelectedProjects}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                //serviceType={'Service'}
            />
        </div>
    );
};
