import {
    DocumentCardActivity,
    Stack,
    Text,
    IDocumentCardActivityStyles,
    IStackTokens,
    IconButton,
} from '@fluentui/react';
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
    useContext,
    CSSProperties,
} from 'react';
import { BuildInstanceList } from '../../Build/Display/BuildInstanceList';
import { ReleaseStage } from '../../../models/ReleaseStage';
import { DeploymentList } from './DeploymentList';
import { Deployment } from '../../../models/Deployment';
import { ServiceType } from '../../../../ABSM/models/ServiceType';
import { getStatusIconProperties } from '../../../../AGSS/models/Timeline';
import { Guid } from '../../../../Common/models/Guid';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { StatusTracker } from './Status/StatusTracker';
import { CompletionIndicatorIcon } from './Status/CompletionIndicatorIcon';
import { sectionStyle, stackStyles } from '../../../util/StyleUtils';
import { ReleaseStageCompactDetails } from './ReleaseStageCompactDetails';
import { Build } from '../../../models/Build';
import { emitUserActionMetric } from '../../../../Common/util/metricsUtil';
import {
    ActionResult,
    ToggleMode,
    UserAction,
} from '../../../../Common/Enums/Metrics/MetricEnums';

interface IReleaseStageDetails {
    isLoaded: boolean;
    stage: ReleaseStage | undefined;
    setSelected: Dispatch<SetStateAction<Deployment | Build | undefined>>;
    showPanel: () => void;
    isPanelOpen: boolean;
    serviceType?: ServiceType;
    serviceId?: Guid;
}

export const ReleaseStageDetails: React.FC<IReleaseStageDetails> = (
    props: IReleaseStageDetails
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const [stage, setStage] = useState<ReleaseStage>();
    const [expanded, setExpanded] = useState<boolean>(props.isPanelOpen);

    const divStyles = (status: string | undefined): CSSProperties => {
        let color = getStatusIconProperties(status, theme).iconColor;
        return {
            borderLeft: 'solid 1px ' + theme.palette.neutralLight,
            borderRight: 'solid 1px ' + theme.palette.neutralLight,
            borderBottom: 'solid 1px ' + theme.palette.neutralLight,
            boxShadow: expanded
                ? '0 -10px 0 0 ' + color
                : '-10px 0 0 0 ' + color,
            backgroundColor: theme.palette.white,
            height: 'fit-content',
            padding: 16,
            margin: expanded ? '20px 10px 5px 0px' : '10px 10px 5px 10px',
        };
    };

    const activityStyles: Partial<IDocumentCardActivityStyles> = {
        root: { width: 200, backgroundColor: theme.palette.white },
    };

    const stackTokens: IStackTokens = { childrenGap: 10 };
    const sectionStackTokens: IStackTokens = { childrenGap: 20 };

    // When parent component sends new data, refresh my state accordingly
    useEffect(() => {
        if (props.stage) {
            //set stage
            setStage(props.stage);

            if (props.stage.deployments.length > 0) {
                props.stage.deployments.map((deployment) => {
                    deployment.showMetadata = true;
                });
            }

            if (props.stage.builds.length > 0) {
                props.stage.builds.map((build) => {
                    build.showMetadata = true;
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stage, props.isLoaded]);

    const expandRelease = () => {
        setExpanded(!expanded);
        emitUserActionMetric(
            UserAction.STAGE_TOGGLE,
            expanded ? ToggleMode.COLLAPSE : ToggleMode.EXPAND,
            ActionResult.SUCCESS,
            process.env.REACT_APP_BUILD_VERSION
        );
    };

    return (
        <div
            className="ReleaseStageDetails-root"
            style={divStyles(stage?.completionIndicator)}
        >
            {/* PageTitle with flow name, submitter, and timestamp */}
            {stage && (
                <Stack
                    horizontal
                    style={{ alignItems: expanded ? undefined : 'center' }}
                >
                    <IconButton
                        styles={{
                            root: {
                                paddingRight: '5px',
                            },
                        }}
                        iconProps={{
                            iconName: expanded
                                ? 'ChevronDownMed'
                                : 'ChevronRightMed',
                        }}
                        onClick={expandRelease}
                    />
                    {expanded ? (
                        <Stack tokens={stackTokens}>
                            <Text variant="large">
                                {stage.stageName ||
                                    'Stage ' + stage.releaseStageCorrelationId}
                            </Text>
                            {stage.completionIndicator && (
                                <Stack
                                    horizontal
                                    className={
                                        stackStyles.paddedAndCenteredStack
                                    }
                                >
                                    <CompletionIndicatorIcon
                                        completionIndicator={
                                            stage.completionIndicator
                                        }
                                        theme={theme}
                                        showText={true}
                                    />
                                    <StatusTracker
                                        completionStatusTracker={
                                            stage.completionStatusTracker
                                        }
                                        textColor={
                                            getStatusIconProperties(
                                                stage.completionIndicator,
                                                theme
                                            ).iconColor || ''
                                        }
                                    />
                                </Stack>
                            )}
                            <Stack tokens={sectionStackTokens}>
                                {stage.deployments.length > 0 && (
                                    <Stack
                                        className={stackStyles.paddedStack}
                                        style={sectionStyle(theme)}
                                    >
                                        <Text variant={'mediumPlus'}>
                                            Deployments
                                        </Text>
                                        <DeploymentList
                                            list={stage.deployments}
                                            isLoaded={props.isLoaded}
                                            //serviceType={props.serviceType}
                                            //serviceId={props.serviceId}
                                            setSelectedDeployment={
                                                props.setSelected
                                            }
                                            showPanel={props.showPanel}
                                            backgroundColor={
                                                sectionStyle(theme)
                                                    .backgroundColor
                                            }
                                        />
                                    </Stack>
                                )}
                                <Stack
                                    className={stackStyles.paddedStack}
                                    style={sectionStyle(theme)}
                                >
                                    <Text variant={'mediumPlus'}>Builds</Text>
                                    <BuildInstanceList
                                        list={stage.builds}
                                        isLoaded={props.isLoaded}
                                        //serviceType={props.serviceType}
                                        //serviceId={props.serviceId}
                                        setSelectedBuild={props.setSelected}
                                        showPanel={props.showPanel}
                                        backgroundColor={
                                            sectionStyle(theme).backgroundColor
                                        }
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal>
                                {stage.submittedBy && (
                                    <DocumentCardActivity
                                        styles={activityStyles}
                                        activity={'Submitted'}
                                        people={[
                                            {
                                                name: stage.submittedBy,
                                                profileImageSrc: '', //get from graph?
                                            },
                                        ]}
                                    />
                                )}
                                {stage.approvedBy && (
                                    <DocumentCardActivity
                                        styles={activityStyles}
                                        activity={'Approved'}
                                        people={[
                                            {
                                                name: stage.approvedBy,
                                                profileImageSrc: '', //get from graph?
                                            },
                                        ]}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    ) : (
                        <ReleaseStageCompactDetails stage={stage} />
                    )}
                </Stack>
            )}
        </div>
    );
};
