import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    CheckboxVisibility,
    DetailsListLayoutMode,
    IColumn,
    Selection,
} from '@fluentui/react';
import { ShimmeredDetailsList } from '@fluentui/react';
import { IStackTokens, Stack, Text } from '@fluentui/react';
import { dateLocaleStringWithTimeZone } from '../../../Common/util/DateUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import {
    columnSort,
    commonGridStyle,
    filterList,
} from '../../../Common/util/tableUtils';
import { Replication } from '../../models/Replication';
import { CompletionIndicatorIcon } from '../MultistageReleaseData/Display/Status/CompletionIndicatorIcon';
import { ReplicationStatusDisplay } from './ReplicationStatusDisplay';
import { TimelineItem } from '../../models/Timeline';
import { getCurrentReplicationTimeline } from '../../util/ReleaseUtils';

export interface IReplicationListProps {
    replications: Replication[];
    isLoaded: boolean;
    setCurrentFlightTimeline: Dispatch<
        SetStateAction<TimelineItem[] | undefined>
    >;
    backgroundColor?: string;
    showTitle?: boolean;
}

export const ReplicationList: React.FC<IReplicationListProps> = (
    props: IReplicationListProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [columnsList, setColumnsList] = useState<IColumn[]>([]);
    const [items, setItems] = useState<Replication[]>([]); // current version of the list - filtered or sorted
    const [selectedItem, setSelectedItem] = useState<Replication | undefined>(
        undefined
    );
    const selection = new Selection({
        onSelectionChanged: () => {
            if (selection.getSelection()[0]) {
                setSelectedItem(selection.getSelection()[0] as Replication);
            }
        },
        onItemsChanged: () => {
            //set default selection to first item in list
            selection.setIndexSelected(0, true, true);
        },
    });

    const stackTokens: IStackTokens = { childrenGap: 0 };

    //need this because the onColumnClick function would only use the filterValue
    //from when it was instantiated
    const filterValueRef = useRef<string>();

    const onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn
    ): void => {
        // Sort the entire list, then reapply the filter
        const sortedEntries = columnSort(column, columns, props.replications);
        setColumnsList(sortedEntries.newColumns);
        filterList(
            filterItems,
            setItems,
            sortedEntries.newItems,
            filterValueRef.current || ''
        );
    };

    const columns: IColumn[] = [
        {
            key: 'statusIndicator',
            name: '',
            minWidth: 20,
            maxWidth: 20,
        },
        {
            key: 'environmentId',
            name: 'Environment',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            fieldName: 'environmentId',
            onColumnClick: onColumnClick,
        },
        {
            key: 'sourceType',
            name: 'Source',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            fieldName: 'sourceType',
            onColumnClick: onColumnClick,
        },
    ];

    const renderColumn = (
        item: Replication,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[
            column?.fieldName as keyof Replication
        ] as string;

        switch (column?.key) {
            case 'statusIndicator':
                return (
                    <CompletionIndicatorIcon
                        completionIndicator={item.completionIndicator}
                        completionIndicatorString={
                            item.completionIndicatorString
                        }
                        theme={theme}
                    />
                );

            default:
                return <span>{fieldContent}</span>;
        }
    };

    useEffect(() => {
        //set selection items when items list changes
        selection.setItems(
            items.map((replication) => {
                return { ...replication, key: replication.id };
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        // Set Current Replication Timeline
        if (selectedItem) {
            props.setCurrentFlightTimeline(
                getCurrentReplicationTimeline(selectedItem)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);

    //filter function
    const filterItems = (i: Replication, newValue: string) => {
        return (
            i.sourceType.toLowerCase().indexOf(newValue) > -1 ||
            (i.environmentId || '').toLowerCase().indexOf(newValue) > -1
        );
    };

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        // Default sort by latest timestamp
        let replications = props.replications;
        setColumnsList(columns);
        setItems(replications);
        // needs columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.replications, props.isLoaded]);

    return (
        <Stack className="ReplicationList-root" tokens={stackTokens}>
            {props.showTitle && <Text variant="large">Replications</Text>}
            <ShimmeredDetailsList
                setKey="single"
                className={commonGridStyle(theme, props.backgroundColor)}
                items={items}
                columns={columnsList}
                onRenderItemColumn={renderColumn}
                selection={selection}
                enableShimmer={!props.isLoaded}
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                layoutMode={DetailsListLayoutMode.justified}
            />
            {props.isLoaded && !props.replications.length && (
                <p>No data found.</p>
            )}
        </Stack>
    );
};
