import React, { PropsWithChildren, useContext } from 'react';
import { DefaultEffects } from '@fluentui/react';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

export interface IPageContentProps extends PropsWithChildren<any> {}

export const PageContent: React.FC<IPageContentProps> = (
    props: IPageContentProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    return (
        <div
            className="PageContent-root"
            style={{
                backgroundColor: theme.palette.white,
                boxShadow: DefaultEffects.elevation8,
                padding: 5,
            }}
        >
            <div
                className="PageContent-root"
                style={{
                    margin: '15px 20px',
                }}
            >
                {props.children}
            </div>
        </div>
    );
};
