import { useEffect, useState } from 'react';

/* Converts a changing value into state.
   This is useful to sync routing param changes with state changes -
   without it, the param change happens 'faster' than other state changes, causing bugs. */
export const useAsState = <T>(value: T): T => {
    const [state, setState] = useState<T>(value);

    useEffect(() => {
        setState(value);
    }, [value]);

    return state;
};
