import React, { useContext } from 'react';
import { Icon, IconButton, Stack, Text } from '@fluentui/react';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { TextWithTooltip } from '../../../../../Common/components/TextWithTooltip';
import { getStatusIconProperties } from '../../../../models/Timeline';

interface IFlightDataDisplay {
    name: string;
    cloud: string;
    description: string;
    timestamp: string;
    status: string;
    statusDetails: string;
    isProjected?: boolean;
    isValid?: boolean;
    shouldDisplay: boolean;
}

export const FlightDataDisplay: React.FC<IFlightDataDisplay> = (
    props: IFlightDataDisplay
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const maxStatusDetailsLength = 30;
    const truncateStatusDetails = () => {
        return props.statusDetails.length > maxStatusDetailsLength ? props.statusDetails.substring(0, maxStatusDetailsLength) + "..." : props.statusDetails;
    };

    const copyToClipboard = (data?: string) => {
        if (data) {
            navigator.clipboard.writeText(data);
        }
    };

    return (
        <div className="FlightDataDisplay-root">
            {props.shouldDisplay && (
                <Stack horizontal style={{alignItems:'top'}}>
                    <Icon
                        style={{
                            padding: '5px 10px 0 0',
                            color: getStatusIconProperties(props.status, theme)
                                .iconColor,
                        }}
                        iconName={
                            getStatusIconProperties(props.status, theme)
                                .iconName
                        }
                        title={props.status}
                        ariaLabel={'Status icon'}
                    />
                    <Stack>
                        <TextWithTooltip
                            tooltip={props.description}
                            data={
                                props.name +
                                (props.cloud ? ' (' + props.cloud + ')' : '')
                            }
                        />
                        {
                            props.statusDetails != null ?
                                <Stack horizontal style={{display: 'inline-flex', alignItems: 'center'}}>
                                    <Text style={{ color: 'rgb(0, 120, 212)' }}>{truncateStatusDetails()}</Text>
                                    <IconButton
                                        iconProps={{ iconName: 'Copy' }}
                                        onClick={() => copyToClipboard(props.statusDetails)}
                                        title={'Copy to clipboard'}
                                    />
                                </Stack> :
                                null
                        }
                        <Text variant="medium">
                            {props.isProjected ? '' : props.timestamp}
                        </Text>
                    </Stack>
                </Stack>
            )}
        </div>
    );
};
