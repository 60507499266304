import React from 'react';
import {
    ITooltipHostStyles,
    TooltipHost,
    Icon,
    Text,
    IFontStyles,
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';

export interface ITextWithTooltipProps {
    tooltip: string;
    data: string;
    variant?: keyof IFontStyles | undefined;
}

export const TextWithTooltip: React.FC<ITextWithTooltipProps> = (
    props: ITextWithTooltipProps
) => {
    const tooltipId = useId('status-tooltip');
    const hostStyles: Partial<ITooltipHostStyles> = {
        root: {
            display: 'inline-block',
        },
    };
    const infoButtonStyles = {
        root: {
            paddingLeft: '5px',
        },
    };

    if (!props.tooltip) {
        return <div>{props.data}</div>;
    } else {
        return (
            <TooltipHost
                content={props.tooltip}
                id={tooltipId}
                styles={hostStyles}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Text variant={props.variant ?? 'medium'}>
                        {props.data}
                    </Text>
                    <Icon iconName="Info" styles={infoButtonStyles} />
                </div>
            </TooltipHost>
        );
    }
};
