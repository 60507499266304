import React, { useContext } from 'react';
import {
    Breadcrumb,
    FontSizes,
    IBreadcrumbItem,
    IBreadcrumbStyles,
    Icon,
    IIconStyles,
    IStackTokens,
    Stack,
    Text,
} from '@fluentui/react';
import { LinkItem } from '../../models/LinkItem';
import { PagePersona } from './PagePersona';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { emitUserActionMetric } from '../../../Common/util/metricsUtil';
import {
    ActionResult,
    UserAction,
} from '../../../Common/Enums/Metrics/MetricEnums';

interface IPageBreadcrumbTrailProps {
    inputItems: LinkItem[];
    label?: string;
}

export const PageBreadcrumbTrail: React.FC<IPageBreadcrumbTrailProps> = (
    props: IPageBreadcrumbTrailProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
        itemLink: {
            fontSize: FontSizes.size14,
        },
    };

    const iconStyles: Partial<IIconStyles> = {
        root: {
            paddingRight: '10px',
            color: theme.palette.themePrimary,
        },
    };

    // Tokens definition
    const stackTokens: IStackTokens = { childrenGap: 5 };

    const handleClick = () => {
        emitUserActionMetric(
            UserAction.EXTERNAL_LINK,
            props.label,
            ActionResult.SUCCESS,
            process.env.REACT_APP_BUILD_VERSION
        );
    };

    const items: IBreadcrumbItem[] = props.inputItems.map((item) => {
        return {
            text: item.text,
            key: item.text,
            href: item.href,
            title: item.title,
            target: item.iconName ? '_blank' : '_self',
            onClick: handleClick,
            onRenderContent: () => (
                <Stack horizontal verticalAlign="center" tokens={stackTokens}>
                    {item.serviceType && (
                        <PagePersona initial={item.serviceType.charAt(0)} />
                    )}
                    {item.iconName && (
                        <Icon iconName={item.iconName} styles={iconStyles} />
                    )}
                    {item.text}
                    {item.iconName && <Icon iconName="OpenInNewWindow" />}
                </Stack>
            ),
        };
    });

    return props.inputItems.length > 0 ? (
        <div className="PageBreadcrumbTrail-root">
            <Stack
                horizontal
                verticalAlign="center"
                tokens={stackTokens}
                style={{ height: '30px', marginBottom: '20px' }}
            >
                <Text variant="medium" style={{ paddingTop: '10px' }}>
                    {props.label !== '' ? props.label + ':' : ''}
                </Text>
                <Breadcrumb
                    items={items}
                    focusZoneProps={{ handleTabKey: 1 }}
                    ariaLabel="Page breadcrumb trail"
                    overflowAriaLabel="More links"
                    styles={breadcrumbStyles}
                />
            </Stack>
        </div>
    ) : null;
};
