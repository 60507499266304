import {
    IColumn,
    Icon,
    IconButton,
    Modal,
    SelectionMode,
    ShimmeredDetailsList,
    Spinner,
    Stack,
} from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DefaultHubButton } from '../../../HubLayout/components/Utilities/DefaultHubButton';
import { CancelToken } from 'axios';
import { Banner } from '../../../Common/models/Banner';
import { usePromise } from '../../../Common/hooks/usePromise';
import {
    deleteBanner,
    getBannerData,
} from '../../../Common/api/ABHub/BannerApi';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { BannerAddModal } from './BannerAddModal';
import { dateLocaleStringWithTimeZone } from '../../../Common/util/DateUtils';

interface IBannerProps {}

export const BannerList: React.FC<IBannerProps> = (props: IBannerProps) => {
    const stackTokens = { childrenGap: 10 };
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const [bannerList, setBannerList] = useState<Banner[]>([]);

    const getBannerList = useCallback(
        async (cancelToken?: CancelToken) => {
            const banners: Banner[] = await getBannerData(cancelToken);
            return banners;
        },
        [bannerList]
    );

    const [list, error, isLoaded, startBannerList] = usePromise(
        getBannerList,
        true
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState<Banner | undefined>(
        undefined
    );
    const [deleteCofirmationModalOpen, setDeleteConfirmationModalOpen] =
        useState(false);

    const columns: IColumn[] = [
        {
            key: 'title',
            name: 'Title',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'title',
        },
        {
            key: 'message',
            name: 'Message',
            minWidth: 150,
            maxWidth: 300,
            isResizable: true,
            fieldName: 'message',
        },
        {
            key: 'createdBy',
            name: 'Author',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            fieldName: 'createdBy',
        },
        {
            key: 'startDate',
            name: 'Start Date',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'startDate',
        },
        {
            key: 'endDate',
            name: 'End Date',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'endDate',
        },
        {
            key: 'type',
            name: 'Message Type',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'typeString',
        },
        {
            key: 'isActive',
            name: 'Is Active',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'isActive',
        },
        {
            key: 'edit',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            isResizable: true,
        },
        {
            key: 'delete',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            isResizable: true,
        },
    ];

    const onRenderColumn = (item: Banner, index?: number, column?: IColumn) => {
        const fieldContent = item[column?.fieldName as keyof Banner] as string;
        switch (column?.key) {
            case 'startDate':
                return dateLocaleStringWithTimeZone(item.startDate, 'UTC');
            case 'endDate':
                return dateLocaleStringWithTimeZone(item.endDate, 'UTC');
            case 'isActive':
                return (
                    <Icon
                        iconName={
                            item.isActive
                                ? 'SkypeCircleCheck'
                                : 'StatusErrorFull'
                        }
                        style={{
                            color: item.isActive
                                ? theme.semanticColors.successIcon
                                : theme.semanticColors.errorIcon,
                        }}
                    />
                );
            case 'edit':
                return (
                    <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        onClick={() => handleEdit(item)}
                    />
                );
            case 'delete':
                return (
                    <Stack>
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => handleDelete(item)}
                        />
                    </Stack>
                );
            default:
                return <span>{fieldContent}</span>;
        }
    };

    const deleteBannerCallback = useCallback(
        async (id: string, cancelToken?: CancelToken) => {
            const response = await deleteBanner(id, cancelToken);
            startBannerList();
            setDeleteConfirmationModalOpen(false);
            return response;
        },
        [deleteCofirmationModalOpen, list]
    );

    useEffect(() => {
        if (list) {
            setBannerList(list);
        }
    }, [list]);

    const handleDelete = (item: Banner) => {
        setSelectedBanner(item);
        setDeleteConfirmationModalOpen(true);
    };

    const handleDeleteConfirmation = (id: string | undefined) => {
        if (id) {
            deleteBannerCallback(id);
        }
    };

    const handleEdit = (item: Banner) => {
        setSelectedBanner(item);
        setShowEditModal(true);
        setIsModalOpen(true);
    };

    const handleClick = () => {
        setSelectedBanner(undefined);
        setShowEditModal(false);
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="Banner-root">
            <Stack tokens={stackTokens}>
                <ShimmeredDetailsList
                    items={bannerList}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onRenderItemColumn={onRenderColumn}
                />
                {isLoaded && bannerList.length < 1 && <p>No banners found</p>}
                <DefaultHubButton
                    buttonText="Add Banner"
                    onClick={handleClick}
                />
                <BannerAddModal
                    banner={selectedBanner}
                    isModalOpen={isModalOpen}
                    editMode={showEditModal}
                    setIsModalOpen={setIsModalOpen}
                    refreshBannerList={startBannerList}
                />

                <Modal isOpen={deleteCofirmationModalOpen}>
                    <Stack style={{ padding: '20px' }}>
                        <h1>Are you sure you want to delete this banner?</h1>
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultHubButton
                                buttonText="Delete"
                                fontColor={theme.semanticColors.errorText}
                                borderColor={theme.semanticColors.errorText}
                                onClick={() =>
                                    handleDeleteConfirmation(
                                        selectedBanner?.id ?? undefined
                                    )
                                }
                            />
                            <DefaultHubButton
                                buttonText="Cancel"
                                onClick={() =>
                                    setDeleteConfirmationModalOpen(false)
                                }
                            />
                        </Stack>
                    </Stack>
                </Modal>
            </Stack>
        </div>
    );
};
