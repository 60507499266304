import { IconButton } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Build, BuildMetadataItem } from '../../../models/Build';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { useIsMobile } from '../../../../Common/hooks/useIsMobile';

interface IBuildMetadataProps {
    item: Build;
}

export const BuildMetadata: React.FC<IBuildMetadataProps> = (
    props: IBuildMetadataProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const isMobile = useIsMobile();

    // Temporary hotfix to be removed once build path is fixed
    const [filteredMetadataMap, setFilteredMetadataMap] = useState<
        Map<string, BuildMetadataItem>
    >(new Map<string, BuildMetadataItem>());

    const metadataFieldNameStyles = {
        width: '10%',
        paddingRight: '5px',
    };

    const metadataValueStyles = {
        display: 'flex',
        width: '90%',
        border: '1px gray solid',
        paddingLeft: '5px',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const metadataContainerStyles = {
        display: 'flex',
        paddingLeft: '16px',
        paddingBottom: '5px',
        paddingTop: '10px',
        alignItems: 'center',
    };

    const mobileMetadataContainerStyles: React.CSSProperties = {
        padding: '6px 0px',
    };

    const mobileMetadataValueStyles: React.CSSProperties = {
        whiteSpace: 'normal',
        wordBreak: 'break-all',
        display: 'inline-block',
    };

    const mobileMetadataValueContainerStyles: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
        border: '1px gray solid',
        justifyContent: 'space-between',
        margin: '6px 0px',
        padding: '6px',
    };

    const copyToClipboard = (metadata: BuildMetadataItem) => {
        navigator.clipboard.writeText(metadata.value);
    };

    // Temporary hotfix to be removed once build path is fixed
    useEffect(() => {
        const filteredMap = new Map(
            [...props.item.buildMetadataItems].filter(
                ([key, value]) => key !== 'buildPath'
            )
        );
        setFilteredMetadataMap(filteredMap);
    }, [props]);

    return props.item.showMetadata ? (
        <div
            style={
                isMobile
                    ? {}
                    : {
                          backgroundColor: theme.palette.neutralLighter,
                          paddingLeft: '40px',
                      }
            }
        >
            {/* REPLACE WITH THIS AFTER BUILD PATH IS FIXED: {Array.from(props.item.buildMetadataItems.values()).map( */}
            {Array.from(filteredMetadataMap.values()).map((metadata) => {
                return (
                    <div
                        key={metadata.fieldName}
                        style={
                            isMobile
                                ? mobileMetadataContainerStyles
                                : metadataContainerStyles
                        }
                    >
                        <strong style={metadataFieldNameStyles}>
                            {metadata.fieldName}
                        </strong>
                        <div
                            style={
                                isMobile
                                    ? mobileMetadataValueContainerStyles
                                    : metadataValueStyles
                            }
                        >
                            <div
                                style={
                                    isMobile
                                        ? mobileMetadataValueStyles
                                        : undefined
                                }
                            >
                                {metadata.value}
                            </div>
                            <IconButton
                                iconProps={{ iconName: 'Copy' }}
                                onClick={() => copyToClipboard(metadata)}
                                title={'Copy to clipboard'}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    ) : (
        <div></div>
    );
};
