import { IMessageBarStyles, MessageBar, MessageBarType } from '@fluentui/react';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../models/ThemeContext';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../Common/api/ABHub/ABHubGlobalConfig';

interface IMessageBarProps extends PropsWithChildren<any> {}

export const ServiceTreeMessageBar: React.FC<IMessageBarProps> = (
    props: IMessageBarProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const serviceTreeNameConfig = getConfig(
        GlobalConfigProperties.UseServiceTreeIdAsLookupValue
    );

    const [showServiceTreeId, setShowServiceTreeId] = useState<boolean>(
        serviceTreeNameConfig.toLowerCase() === 'true' ? true : false
    );

    const messageBarStyles: Partial<IMessageBarStyles> = {
        innerText: {
            fontSize: theme.fonts.medium.fontSize,
            color: theme.palette.blueDark,
        },
        icon: { color: theme.palette.blueDark },
        dismissal: { color: theme.palette.blueDark },
    };

    const dismissGeneralEgressMessage = () => {
        setShowServiceTreeId(false);
    };

    const getServiceTreeMessage = () => {
        return (
            <div>
                ServiceTree names are not available. ServiceTree Id's will be
                shown instead.
            </div>
        );
    };

    return (
        <div className="MessageBar-root" style={{ zIndex: 1 }}>
            {showServiceTreeId && (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={true}
                    onDismiss={dismissGeneralEgressMessage}
                    dismissButtonAriaLabel="Close"
                    styles={messageBarStyles}
                >
                    {getServiceTreeMessage()}
                </MessageBar>
            )}
        </div>
    );
};
