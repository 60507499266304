import {
    DetailsRow,
    IColumn,
    IconButton,
    IDetailsRowProps,
    IGroup,
    IStackTokens,
    Stack,
    Text,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Guid } from '../../../Common/models/Guid';
import { ServiceType } from '../../../ABSM/models/ServiceType';
import { Release } from '../../models/Release';
import { ReleaseStage } from '../../models/ReleaseStage';
import { DetailsPanel } from './Display/DetailsPanel';
import { Deployment } from '../../models/Deployment';
import { ReleaseStageDetails } from './Display/ReleaseStageDetails';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { Build } from '../../models/Build';
import { useIsMobile } from '../../../Common/hooks/useIsMobile';
import { MobileReleaseStageDetails } from './Display/Mobile/MobileReleaseStageDetails';
import { MetadataDetailsPanel } from './MetadataDetailsPanel';

interface IReleaseFlow {
    release: Release | undefined;
    isLoaded: boolean;
    serviceType?: ServiceType;
    serviceId?: Guid;
}

export const ReleaseFlow: React.FC<IReleaseFlow> = (props: IReleaseFlow) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const sectionStackTokens: IStackTokens = { childrenGap: 20 };
    const [stages, setStages] = useState<ReleaseStage[]>([]);
    const [selected, setSelected] = useState<Deployment | Build | undefined>();
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const [isMetadataPanelOpen, setIsMetadataPanelOpen] =
        useState<boolean>(false);
    const [collapsedState, setCollapsedState] = useState<{
        [key: string]: boolean;
    }>({});
    const showPanel = () => setIsPanelOpen(true);
    const hidePanel = () => setIsPanelOpen(false);
    const showMetadataPanel = () => setIsMetadataPanelOpen(true);
    const hideMetadataPanel = () => setIsMetadataPanelOpen(false);
    const isMobile = useIsMobile();

    // When parent component sends new data, refresh my state accordingly
    useEffect(() => {
        if (props.release && props.release.stages) {
            // Stages should be sorted before being grouped
            const sortedStagesByEnvironment = props.release.stages.sort(
                (s1, s2) => {
                    if (s1.environment && s2.environment) {
                        return s1.environment > s2.environment ? -1 : 1;
                    }

                    return 0;
                }
            );

            setStages(sortedStagesByEnvironment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.release, props.isLoaded]);

    // Toggle collapse for a specific cloud
    const toggleCollapse = (cloud: string) => {
        setCollapsedState((prevState) => ({
            ...prevState,
            [cloud]: !prevState[cloud],
        }));
    };

    type GroupedClouds = { [key: string]: ReleaseStage[] };

    const groupedItems: GroupedClouds = stages.reduce((acc, item) => {
        const { environment } = item;

        if (environment) {
            if (!acc[environment]) {
                acc[environment] = [];
            }
            acc[environment].push(item);
            return acc;
        }

        return acc;
    }, {} as GroupedClouds);

    return (
        <div className="ReleaseFlow-root">
            <Stack horizontal tokens={sectionStackTokens}>
                {/* PageTitle with flow name, submitter, and timestamp */}
                <Stack
                    tokens={sectionStackTokens}
                    style={{
                        width: '100%',
                    }}
                >
                    <div>
                        {Object.keys(groupedItems).map((cloud) => {
                            const isCollapsed = collapsedState[cloud] || false;
                            return (
                                <Stack key={cloud}>
                                    <Stack horizontal>
                                        <IconButton
                                            iconProps={{
                                                iconName: isCollapsed
                                                    ? 'ChevronRight'
                                                    : 'ChevronDown',
                                            }}
                                            onClick={() =>
                                                toggleCollapse(cloud)
                                            }
                                        />
                                        <Text variant="xLarge">{`${cloud} (${groupedItems[cloud].length})`}</Text>
                                    </Stack>

                                    {isCollapsed ? (
                                        <></>
                                    ) : (
                                        <div>
                                            {groupedItems[cloud].map((item) =>
                                                isMobile ? (
                                                    <MobileReleaseStageDetails
                                                        key={
                                                            item.releaseCorrelationId
                                                        }
                                                        isLoaded={
                                                            props.isLoaded
                                                        }
                                                        stage={item}
                                                        setSelected={
                                                            setSelected
                                                        }
                                                        showPanel={showPanel}
                                                        showMetadataPanel={
                                                            showMetadataPanel
                                                        }
                                                        isPanelOpen={
                                                            stages.length === 1
                                                        }
                                                    />
                                                ) : (
                                                    <ReleaseStageDetails
                                                        key={
                                                            item.releaseCorrelationId
                                                        }
                                                        isLoaded={
                                                            props.isLoaded
                                                        }
                                                        stage={item}
                                                        setSelected={
                                                            setSelected
                                                        }
                                                        showPanel={showPanel}
                                                        isPanelOpen={
                                                            stages.length === 1
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    )}
                                </Stack>
                            );
                        })}
                    </div>
                </Stack>
            </Stack>
            <DetailsPanel
                isOpen={isPanelOpen}
                dismissPanel={hidePanel}
                selected={selected}
                isLoaded={props.isLoaded}
            />
            <MetadataDetailsPanel
                isOpen={isMetadataPanelOpen}
                dismissPanel={hideMetadataPanel}
                entity={selected}
                isLoaded={props.isLoaded}
            />
        </div>
    );
};
