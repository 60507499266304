import { createContext } from 'react';
import { Capability, CapabilityState } from './capability';

export interface ICapabilityContext {
    get: (capability: Capability) => CapabilityState;
    check: (capability: Capability) => boolean;
}

export const CapabilityContext = createContext<ICapabilityContext>({
    get: () => CapabilityState.unknown,
    check: () => false,
});
