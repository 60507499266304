import React, { useContext } from 'react';
import { IStackStyles, IStackTokens, Stack, Text } from '@fluentui/react';
import { Replication } from '../../models/Replication';
import { TextWithTooltip } from '../../../Common/components/TextWithTooltip';
import { StatusTracker } from '../MultistageReleaseData/Display/Status/StatusTracker';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { getStatusIconProperties } from '../../models/Timeline';
import { dateLocaleStringWithTimeZone } from '../../../Common/util/DateUtils';

interface IReplicationStatusDisplayProps {
    replication?: Replication;
}

export const ReplicationStatusDisplay: React.FC<IReplicationStatusDisplayProps> =
    (props: IReplicationStatusDisplayProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);
        const stackTokens: IStackTokens = { childrenGap: 5 };
        const stackStyles: Partial<IStackStyles> = {
            root: {
                alignItems: 'center',
            },
        };

        const fontStyle = props.replication
            ? props.replication.isUnavailable
                ? 'italic'
                : ''
            : '';

        return props.replication ? (
            <div style={{ fontStyle: fontStyle }}>
                <Stack>
                    <Stack horizontal styles={stackStyles} tokens={stackTokens}>
                        <TextWithTooltip
                            tooltip={
                                props.replication.currentStatus?.description ||
                                ''
                            }
                            data={props.replication.currentStatus?.name}
                        />
                    </Stack>
                    {props.replication.failedMessage && (
                        <Text
                            style={{
                                whiteSpace: 'normal',
                                color: theme.semanticColors.errorText,
                            }}
                            variant="medium"
                        >
                            {props.replication.failedMessage}
                        </Text>
                    )}
                    <div>
                        {dateLocaleStringWithTimeZone(
                            props.replication.failedOn ??
                                props.replication.lastUpdated
                        )}
                    </div>
                </Stack>
            </div>
        ) : null;
    };
