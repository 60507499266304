import React, { useState } from 'react';
import {
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    Stack,
} from '@fluentui/react';
import { ResetFileHash } from './Endpoints/ResetFileHash';
import { ActivateArtifact } from './Endpoints/ActivateArtifact';
import { DeactivateArtifact } from './Endpoints/DeactivateArtifact';
import { RequeueDeadLetterMessages } from './Endpoints/RequeueDeadLetterMessages';

interface IHydrationEndpointsProps {}

export const HydrationEndpoints: React.FC<IHydrationEndpointsProps> = (
    props: IHydrationEndpointsProps
) => {
    const [selectedOption, setSelectedOption] = useState<
        IDropdownOption | undefined
    >(undefined);

    const stackTokens = { childrenGap: 10 };

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 },
    };

    const options: IDropdownOption[] = [
        {
            key: 'resetFileHash',
            text: 'Reset a file hash',
        },
        {
            key: 'activateArtifact',
            text: 'Activate Artifact',
        },
        {
            key: 'deactivateArtifact',
            text: 'Deactive Artifact',
        },
        {
            key: 'requeueDeadLetterMessages',
            text: 'Requeue Dead Letter Messages',
        },
    ];

    const handleOptionChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ) => {
        setSelectedOption(option);
    };

    return (
        <div className="HydrationEndpoints-root">
            <Stack tokens={stackTokens}>
                <Dropdown
                    placeholder="Select an option"
                    label="Endpoint"
                    options={options}
                    styles={dropdownStyles}
                    selectedKey={selectedOption?.key}
                    onChange={handleOptionChange}
                />

                {selectedOption?.key === 'resetFileHash' && <ResetFileHash />}

                {selectedOption?.key === 'activateArtifact' && (
                    <ActivateArtifact />
                )}

                {selectedOption?.key === 'deactivateArtifact' && (
                    <DeactivateArtifact />
                )}

                {selectedOption?.key === 'requeueDeadLetterMessages' && (
                    <RequeueDeadLetterMessages />
                )}
            </Stack>
        </div>
    );
};
