import { IColumn } from '@fluentui/react';

export const BuildColumns: IColumn[] = [
    {
        key: 'buildBranch',
        name: 'Build Branch',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        fieldName: 'buildBranch',
    },
    {
        key: 'buildNumber',
        name: 'Build Number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        fieldName: 'buildNumber',
    },
    //{
    //    key: 'completionIndicator',
    //    name: 'Status',
    //    minWidth: 120,
    //    maxWidth: 120,
    //    isResizable: true,
    //    fieldName: 'completionIndicatorString',
    //},
    {
        key: 'componentName',
        name: 'Component Name',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        fieldName: 'buildComponentName',
    },
    {
        key: 'service',
        name: 'Service',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        fieldName: 'serviceName',
    },
    {
        key: 'repo',
        name: 'Repo',
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        fieldName: 'repoName',
    },
    {
        key: 'project',
        name: 'Project',
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        fieldName: 'projectName',
    },
    {
        key: 'createdOn',
        name: 'Created On',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        fieldName: 'createdOn',
    },
];
