import React, { useState, useEffect, useContext } from 'react';
import { ReleaseSearchResult } from '../../../AGSS/models/Release';
import {
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    ShimmeredDetailsList,
} from '@fluentui/react';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { CapabilityContext } from '../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../Common/components/Capabilities/capability';
import { CompletionStatusDisplay } from '../../../AGSS/components/MultistageReleaseData/Display/Status/CompletionStatusDisplay';
import { getReleaseDetailsPath } from '../../../AGSS/util/ReleaseUtils';
import { ExternalLink, InternalLink } from '../../../Common/components/Links';
import { dateLocaleStringWithTimeZone } from '../../../Common/util/DateUtils';
import {
    getServiceTreeUrl,
    getAdoReleaseUrl,
    getNewYamlReleaseUrl,
} from '../../../Common/util/RouteUtils';
import { commonGridStyle } from '../../../Common/util/tableUtils';
import { ReleasePipelineSource } from '../../../AGSS/models/ReleasePipelineSource';
import { ReleaseColumns } from '../ReleaseColumns';
import { getReleasePipelineSourceTag } from '../../../AGSS/components/MultistageReleaseData/Display/Status/ReleasePipelineSourceTag';

interface IHomeReleaseListProps {
    list: ReleaseSearchResult[];
    isLoaded: boolean;
}

export const HomeReleaseList: React.FC<IHomeReleaseListProps> = (
    props: IHomeReleaseListProps
) => {
    const [items, setItems] = useState<ReleaseSearchResult[]>([]);
    const [columnsList, setColumnsList] = useState<IColumn[]>([]);

    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const capabilities = useContext(CapabilityContext);
    const includeExternalLink = capabilities.check(Capability.public);

    const columns: IColumn[] = ReleaseColumns;

    const renderReleaseColumn = (
        item: ReleaseSearchResult,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[
            column?.fieldName as keyof ReleaseSearchResult
        ] as string;

        const getAdoReleaseItem = (): string => {
            return getAdoReleaseUrl(
                item.accountName || item.adoInstance || '',
                item.projectName || '',
                '',
                item.releaseId
            );
        };

        const getNewYAMLLink = (): string => {
            return getNewYamlReleaseUrl(
                item?.accountName || item?.adoInstance || '',
                item?.projectName || '',
                '',
                item?.releaseId || 0
            );
        };

        switch (column?.key) {
            case 'service':
                return (
                    <div>
                        {item.serviceTreeName || item.serviceTreeId}
                        <ExternalLink
                            value="Service Tree"
                            url={getServiceTreeUrl(item.serviceTreeId || '')}
                            title="Open Service Tree"
                        />
                    </div>
                );

            case 'releaseId':
                return (
                    <div>
                        <InternalLink
                            value={
                                item.releaseName
                                    ? item.releaseName
                                    : 'Release-' + item.releaseId
                            }
                            url={
                                '#' +
                                getReleaseDetailsPath(
                                    item.releaseCorrelationId || ''
                                )
                            }
                            title="View release details"
                        />
                        {includeExternalLink && (
                            <ExternalLink
                                value="ADO Release"
                                url={
                                    item.releasePipelineSource !==
                                    ReleasePipelineSource.YAML
                                        ? getAdoReleaseItem()
                                        : getNewYAMLLink()
                                }
                                title="Open Azure DevOps Release Pipeline"
                            />
                        )}
                        <div
                            style={{
                                color: theme.palette.neutralTertiary,
                                fontSize: 11, //FontSizes.size12 looks too big
                            }}
                        >
                            <div>{'Id: ' + item.releaseId}</div>
                            <div>
                                {getReleasePipelineSourceTag(
                                    item.releasePipelineSource
                                )}
                            </div>
                        </div>
                    </div>
                );

            case 'completionIndicator':
                return (
                    <CompletionStatusDisplay
                        completionIndicator={item.completionIndicator || ''}
                        completionStatusTracker={item.completionStatusTracker}
                    />
                );
            case 'createdOn':
                return dateLocaleStringWithTimeZone(item.createdOn);
            case 'updatedOn':
                return dateLocaleStringWithTimeZone(item.updatedOn);

            default:
                return <span>{fieldContent}</span>;
        }
    };

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        setColumnsList(columns);
        setItems(props.list);
    }, [props.list, props.isLoaded]);

    return (
        <div className="HomeReleaseList-root">
            <ShimmeredDetailsList
                className={commonGridStyle(theme)}
                items={items}
                columns={columnsList}
                onRenderItemColumn={renderReleaseColumn}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={!props.isLoaded}
                shimmerLines={10}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Releases"
            />
            {props.isLoaded && (props.list.length < 1 || items.length < 1) && (
                <p>You have not viewed any releases recently.</p>
            )}
        </div>
    );
};
