import { Panel, PanelType } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Deployment } from '../../../models/Deployment';
import { CancelToken } from 'axios';
import {
    AgrmType,
    getAgrmData,
    getAgrmOrchestrations,
    getAgrmStates,
} from '../../../api/AGRMApi';
import { usePromise } from '../../../../Common/hooks/usePromise';
import { AGRMDataType } from '../../../models/AGRMDataType';

interface IAgrmDetailsPanelProps {
    deployment?: Deployment;
    isOpen: boolean;
    dismissPanel:
        | ((
              ev?:
                  | React.SyntheticEvent<HTMLElement, Event>
                  | KeyboardEvent
                  | undefined
          ) => void)
        | undefined;
    selectedOption: AGRMDataType;
}

export const AgrmDetailsPanel: React.FC<IAgrmDetailsPanelProps> = (
    props: IAgrmDetailsPanelProps
) => {
    const getAgrmApiCall = async (
        apiCall: Function,
        cancelToken?: CancelToken
    ) => {
        if (props.deployment?.uniqueIdentifier) {
            const result = await apiCall(
                props.deployment.uniqueIdentifier,
                AgrmType.Deployment,
                cancelToken
            );
            const stringified = JSON.stringify(result, null, 2);
            return stringified;
        } else {
            return 'Failed to get Agrm Information. Invalid Unique Identifier.';
        }
    };

    const setGeneralData = useCallback(
        () => getAgrmApiCall(getAgrmData),
        [props.deployment?.uniqueIdentifier]
    );
    const setAgrmOrchestrations = useCallback(
        () => getAgrmApiCall(getAgrmOrchestrations),
        [props.deployment?.uniqueIdentifier]
    );
    const setAgrmStates = useCallback(
        () => getAgrmApiCall(getAgrmStates),
        [props.deployment?.uniqueIdentifier]
    );

    const [
        generalData,
        generalDataError,
        generalDataIsLoaded,
        generalDataStart,
    ] = usePromise(setGeneralData, false);

    const [
        orchestrationData,
        orchestrationDataError,
        orchestrationDataIsLoaded,
        orchestrationDataStart,
    ] = usePromise(setAgrmOrchestrations, false);

    const [stateData, stateDataError, stateDataIsLoaded, stateDataStart] =
        usePromise(setAgrmStates, false);

    const resultStyle: React.CSSProperties = {
        padding: '10px',
        borderRadius: '5px',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    };

    useEffect(() => {
        switch (props.selectedOption) {
            case AGRMDataType.General:
                generalDataStart();
                break;
            case AGRMDataType.Orchestrations:
                orchestrationDataStart();
                break;
            case AGRMDataType.States:
                stateDataStart();
                break;
            default:
                break;
        }
    }, [generalData, orchestrationData, stateData]);

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div>
            <Panel
                isLightDismiss
                isOpen={props.isOpen}
                onDismiss={props.dismissPanel}
                closeButtonAriaLabel="Close"
                headerText={
                    capitalizeFirstLetter(props.selectedOption) + ' Data'
                }
                type={PanelType.custom}
            >
                {generalData &&
                    props.selectedOption === AGRMDataType.General && (
                        <pre style={resultStyle}>{generalData}</pre>
                    )}
                {orchestrationData &&
                    props.selectedOption === AGRMDataType.Orchestrations && (
                        <pre style={resultStyle}>{orchestrationData}</pre>
                    )}
                {stateData && props.selectedOption === AGRMDataType.States && (
                    <pre style={resultStyle}>{stateData}</pre>
                )}
            </Panel>
        </div>
    );
};
