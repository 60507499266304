import axios from 'axios';

export async function fetchConfig() {
    // Get config - see PortalUiOptions.cs
    const response = await axios.get<any>('/API/PortalConfig/Config');
    setConfig(response.data);
}

export function setConfig(config: any) {
    window.sessionStorage.setItem('portalConfig', JSON.stringify(config));
}

export function getConfig(configName: GlobalConfigProperties) {
    let data = sessionStorage.getItem('portalConfig');
    if (data === null || data === undefined) {
        return '';
    } else {
        return JSON.parse(data)[configName] ? JSON.parse(data)[configName] : '';
    }
}

// See PortalUiOptions.cs
export enum GlobalConfigProperties {
    Environment = 'environment',
    Cloud = 'cloud',
    StsUrl = 'sts_url',
    AadUrl = 'aad_url',
    AadTenant = 'aad_tenant',
    AadClient = 'aad_client',
    GraphUrl = 'graph_url',
    ServiceTreeUrl = 'service_tree_url',
    AgssUrl = 'agss_url',
    AgcpUrl = 'agcp_url',
    AgrmUrl = 'agrm_url',
    Privacy_url = 'privacy_url',
    AbsmUrl = 'absm_url',
    GeneralMessage = 'general_message',
    UseServiceTreeIdAsLookupValue = 'use_service_tree_id_as_lookup_value',
    RestartAgrmActive = 'feature_restart_agrm',
}
