import React from 'react';
import { DefaultButton, FontSizes, IButtonStyles } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { IIconStyles } from 'office-ui-fabric-react';

interface IBackButtonProps {
    buttonText?: string;
}

export const BackButton: React.FC<IBackButtonProps> = (
    props: IBackButtonProps
) => {
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    const backButtonStyles: Partial<IButtonStyles> = {
        root: {
            minWidth: 'fitContent',
            borderRadius: '5px',
            fontSize: FontSizes.size14,
            paddingLeft: '5px',
            paddingRight: '5px',
            marginBottom: '10px',
            borderWidth: '2px',
        },
    };

    const iconStyles: Partial<IIconStyles> = {
        root: {
            fontSize: FontSizes.size14,
        },
    };

    return (
        <div className="BackButton-root">
            {history?.length > 1 && (
                <DefaultButton
                    styles={backButtonStyles}
                    text={props.buttonText ? props.buttonText : 'Back'}
                    onClick={goBack}
                    iconProps={{
                        iconName: 'Back',
                        styles: iconStyles,
                    }}
                />
            )}
        </div>
    );
};
