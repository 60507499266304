import { CancelToken } from 'axios';
import { UserProfile, UserProfileResponse } from '../../models/UserProfile';
import { agpAxiosClient } from './ABHubTokenConfig';

export const getUserProfileData = async (
    alias: string,
    cancelToken?: CancelToken
): Promise<UserProfile> => {
    const response = await agpAxiosClient.get<UserProfileResponse>(
        `api/userProfile/${alias}`,
        {
            cancelToken,
        }
    );

    return response.data.data;
};

export const setUserProfileData = async (
    alias: string,
    recentlyViewedRelease?: string,
    cancelToken?: CancelToken
): Promise<UserProfile> => {
    var url = `api/userProfile/${alias}`;

    if (recentlyViewedRelease) {
        url += `?recentlyViewedRelease=${recentlyViewedRelease}`;
    }

    const response = await agpAxiosClient.put<UserProfileResponse>(url, {
        cancelToken,
    });

    return response.data.data;
};

export const clearUserProfileRecents = async (
    alias: string,
    clearRecentlyViewedReleases: boolean,
    cancelToken?: CancelToken
) => {
    var url = `api/userProfile/${alias}`;

    if (clearRecentlyViewedReleases) {
        url += `?clearRecentlyViewedReleases=${clearRecentlyViewedReleases}`;
    }
    const response = await agpAxiosClient.put<UserProfileResponse>(url, {
        cancelToken,
    });

    return response.data;
};
