import { Stack, TextField } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { usePromise } from '../../../../Common/hooks/usePromise';
import { reqeueDeadLetterMessages } from '../../../../AGCP/api/AGCPApi';
import { DefaultLoadingHubButton } from '../../../../HubLayout/components/Utilities/DefaultLoadingHubButton';
import { JsonResult } from '../../../../HubLayout/components/Utilities/JsonResult';

interface IRequeueDeadLetterMessagesProps {}

export const RequeueDeadLetterMessages: React.FC<IRequeueDeadLetterMessagesProps> =
    (props: IRequeueDeadLetterMessagesProps) => {
        const [queueName, setQueueName] = useState<string>('');
        const [messageCount, setMessageCount] = useState<string>('10');
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const stackTokens = { childrenGap: 10 };

        const handleQueueNameChange = (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
        ) => {
            setQueueName(newValue ?? '');
        };

        const handleMessageCountChange = (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
        ) => {
            if (!newValue) {
                newValue = '';
            }

            const re = /^\d+$/;
            if (re.test(newValue)) {
                setMessageCount(newValue);
            } else {
                setMessageCount('');
            }
        };

        const requeueCallback = useCallback(async () => {
            if (queueName && messageCount) {
                const result = await reqeueDeadLetterMessages(
                    queueName,
                    parseInt(messageCount)
                );
                setIsLoading(false);
                return result;
            }
        }, [queueName, messageCount]);

        const [result, error, isLoaded, start, cancel] = usePromise(
            requeueCallback,
            false
        );

        const handleRequeueClick = () => {
            setIsLoading(true);
            start();
        };

        return (
            <Stack tokens={stackTokens}>
                <TextField
                    label="Queue Name"
                    placeholder="Enter the queue name"
                    value={queueName}
                    onChange={handleQueueNameChange}
                />
                <TextField
                    label="Message Count (max 1000)"
                    placeholder="Enter the message count"
                    maxLength={4}
                    value={messageCount}
                    onChange={handleMessageCountChange}
                />

                <DefaultLoadingHubButton
                    horizontal
                    buttonText="Requeue Dead Letter Messages"
                    icon="Refresh"
                    result={result}
                    error={error}
                    isLoading={isLoading}
                    disabled={!queueName || !messageCount}
                    onClick={handleRequeueClick}
                />

                <JsonResult data={result} />
            </Stack>
        );
    };
