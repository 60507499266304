import { ThemeProvider } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { getThemeFromString } from './Common/util/localStorageUtils';
import { AuthRequired } from './HubLayout/components/AuthRequired/AuthRequired';
import { ThemeContext } from './HubLayout/models/ThemeContext';

export const App: React.FC = () => {
    //user's preferred theme
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [pageLoadTime, setPageLoadTime] = useState<number>(-1);

    // metric to measure inital portal load time
    useEffect(() => {
        const perfObserver = new PerformanceObserver((observedEntries) => {
            const entry: PerformanceEntry =
                observedEntries.getEntriesByType('navigation')[0];

            if (entry.duration > 0) {
                setPageLoadTime(entry.duration);
            }
        });

        perfObserver.observe({
            type: 'navigation',
            buffered: true,
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <AuthRequired pageLoadTime={pageLoadTime} />
        </ThemeProvider>
    );
};
