import React, { useContext } from 'react';
import { Link, Text } from '@fluentui/react';
import { linkStyle } from '../../../Common/util/CommonStyles';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

export interface IPageTitleProps {
    title: string;
    subTitle?: string;
    subTitleIsLink?: boolean;
    subTitleLinkUrl?: string;
}

export const PageTitle: React.FC<IPageTitleProps> = (
    props: IPageTitleProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    return (
        <div className="PageTitle-root" style={{ marginBottom: 20 }}>
            <div>
                <Text className="PageTitle-title" variant="xLarge">
                    {props.title}
                </Text>
            </div>
            {props.subTitle ? (
                <div>
                    {props.subTitleIsLink && (
                        <Link
                            className={'link ' + linkStyle(theme)}
                            variant="medium"
                            href={props.subTitleLinkUrl}
                        >
                            {'< ' + props.subTitle}
                        </Link>
                    )}
                    {!props.subTitleIsLink && (
                        <Text className="PageTitle-subTitle" variant="medium">
                            {props.subTitle}
                        </Text>
                    )}
                </div>
            ) : null}
        </div>
    );
};
