import { agpAxiosClient } from '../../Common/api/ABHub/ABHubTokenConfig';
import { CancelToken } from 'axios';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../Common/api/ABHub/ABHubGlobalConfig';
import { RequestResult } from '../../AGCP/models/AirGapMessage';

// DEPLOYMENTS /BUILDS------------------------------------------------------
export const checkAgrmRestartPossible = async (
    uniqueIdentifier: string,
    type: AgrmType,
    cancelToken?: CancelToken
): Promise<RequestResult<boolean>> => {
    const agrmUrl = getConfig(GlobalConfigProperties.AgrmUrl);
    const cloud = getConfig(GlobalConfigProperties.Cloud);
    const environment = getConfig(GlobalConfigProperties.Environment);

    checkValidCloudOrEnvironment(cloud, environment);

    const response = await agpAxiosClient.get<RequestResult<boolean>>(
        agrmUrl +
            `/api/${type.toLowerCase()}/restartcheck/${encodeURIComponent(
                uniqueIdentifier
            )}`,
        { cancelToken }
    );

    return response.data;
};

export const restartAgrmProcess = async (
    uniqueIdentifier: string,
    type: string,
    cancelToken?: CancelToken
): Promise<RequestResult<boolean>> => {
    const agrmUrl = getConfig(GlobalConfigProperties.AgrmUrl);
    const cloud = getConfig(GlobalConfigProperties.Cloud);
    const environment = getConfig(GlobalConfigProperties.Environment);

    checkValidCloudOrEnvironment(cloud, environment);

    const response = await agpAxiosClient.post<RequestResult<boolean>>(
        agrmUrl +
            `/api/${type.toLowerCase()}/restart/${encodeURIComponent(
                uniqueIdentifier
            )}`,
        { cancelToken }
    );

    return response.data;
};

export const getAgrmData = async (
    uniqueIdentifier: string,
    type: AgrmType,
    cancelToken?: CancelToken
): Promise<RequestResult<boolean>> => {
    const agrmUrl = getConfig(GlobalConfigProperties.AgrmUrl);
    const cloud = getConfig(GlobalConfigProperties.Cloud);
    const environment = getConfig(GlobalConfigProperties.Environment);
    const convertedType =
        type == AgrmType.Deployment ? type : type.toLowerCase();

    checkValidCloudOrEnvironment(cloud, environment);

    const response = await agpAxiosClient.get<RequestResult<boolean>>(
        agrmUrl +
            `/api/${convertedType}/${encodeURIComponent(uniqueIdentifier)}`,
        { cancelToken }
    );

    return response.data;
};

export const getAgrmOrchestrations = async (
    uniqueIdentifier: string,
    type: string,
    cancelToken?: CancelToken
): Promise<RequestResult<boolean>> => {
    const agrmUrl = getConfig(GlobalConfigProperties.AgrmUrl);
    const cloud = getConfig(GlobalConfigProperties.Cloud);
    const environment = getConfig(GlobalConfigProperties.Environment);
    const convertedType =
        type == AgrmType.Deployment ? type : type.toLowerCase();

    checkValidCloudOrEnvironment(cloud, environment);

    const response = await agpAxiosClient.get<RequestResult<boolean>>(
        agrmUrl +
            `/api/${convertedType}/orchestration/${encodeURIComponent(
                uniqueIdentifier
            )}`,
        { cancelToken }
    );

    return response.data;
};

export const getAgrmStates = async (
    uniqueIdentifier: string,
    type: string,
    cancelToken?: CancelToken
): Promise<RequestResult<boolean>> => {
    const agrmUrl = getConfig(GlobalConfigProperties.AgrmUrl);
    const cloud = getConfig(GlobalConfigProperties.Cloud);
    const environment = getConfig(GlobalConfigProperties.Environment);
    const convertedType =
        type == AgrmType.Deployment ? type : type.toLowerCase();

    checkValidCloudOrEnvironment(cloud, environment);

    const response = await agpAxiosClient.get<RequestResult<boolean>>(
        agrmUrl +
            `/api/${convertedType}/states/${encodeURIComponent(
                uniqueIdentifier
            )}`,
        { cancelToken }
    );

    return response.data;
};

//----------------------------------------------------------------------

// HELPERS---------------------------------------------------------------

//-----------------------------------------------------------------------

export const checkValidCloudOrEnvironment = (
    cloud: string,
    environment: string
): void => {
    if (
        cloud !== 'USNat' &&
        cloud !== 'USSec' &&
        environment !== 'Development' &&
        environment !== 'Test'
    ) {
        throw new Error(
            'Not a valid environment. Cloud: ' +
                cloud +
                ' Environment: ' +
                environment
        );
    }
};

export enum AgrmType {
    Deployment = 'Deployment',
    Build = 'Build',
}
