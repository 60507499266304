import axios, { CancelToken, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

// Manages an asynchronous call - allows the call to be canceled, and automatically
// unsubscribes/cancels when the component unmounts to avoid errors.
export const usePromise = <T>(
    funct: (cancelToken?: CancelToken) => Promise<T>,
    startImmediately: boolean = false
): [T | undefined, Error | undefined, boolean, () => void, () => void] => {
    const [data, setData] = useState<T | undefined>();
    const [error, setError] = useState<Error | undefined>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isRunning, setIsRunning] = useState<boolean>(false); // tracks when we've started the API call

    useEffect(() => {
        const cancelSource: CancelTokenSource = axios.CancelToken.source();
        let isSubscribed = false; // tracks if we care about the outcome (vs if we've canceled/unmounted)

        const subscribe = async () => {
            isSubscribed = true;

            try {
                //reset data
                setData(undefined);
                setError(undefined);
                setIsLoaded(false);

                //fetch data
                const dataSet = await funct(cancelSource.token);
                if (isSubscribed) {
                    //set data
                    setData(dataSet);
                    setError(undefined);
                    setIsLoaded(true);
                }
            } catch (error) {
                if (isSubscribed) {
                    setError(error);
                }
            } finally {
                if (isSubscribed) {
                    setIsRunning(false);
                }
            }
        };

        const unsubscribe = () => {
            if (isSubscribed) {
                isSubscribed = false;
                cancelSource.cancel();
            }
        };

        // Start when isRunning is turned on, cancel when it's turned off
        if (isRunning) {
            subscribe();
        } else {
            unsubscribe();
        }

        return unsubscribe;
    }, [isRunning]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (startImmediately) {
            setIsRunning(true);
        }
    }, [funct, startImmediately]);

    const start = () => {
        setIsRunning(true);
    };
    const cancel = () => {
        setIsRunning(false);
    };

    return [data, error, isLoaded, start, cancel];
};
