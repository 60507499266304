import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DefaultHubButton } from '../../../HubLayout/components/Utilities/DefaultHubButton';
import { getReleaseExport } from '../../../AGSS/api/AGSSApi';
import { CancelToken } from 'axios';
import { usePromise } from '../../../Common/hooks/usePromise';
import { IStackTokens, Icon, Spinner, Stack } from '@fluentui/react';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

interface IReleaseExporterProps {
    releaseCorrelationIds: string[];
}

export const ReleaseExporter: React.FC<IReleaseExporterProps> = (
    props: IReleaseExporterProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const stackTokens: IStackTokens = { childrenGap: 10 };

    const exportRelease = useCallback(
        async (cancelToken?: CancelToken) => {
            return await getReleaseExport(
                props.releaseCorrelationIds,
                cancelToken
            );
        },
        [props]
    );

    const [result, error, isLoaded, start] = usePromise(exportRelease, false);

    const handleExportClick = () => {
        setIsLoading(true);
        start();
    };

    useEffect(() => {
        if (result || error) {
            setIsLoading(false);
        }
    }, [result, error]);

    return (
        <div className="ReleaseExporter-root">
            <Stack
                horizontal
                tokens={stackTokens}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {!isLoading ? (
                    <DefaultHubButton
                        buttonText="Export Release"
                        icon="Installation"
                        onClick={handleExportClick}
                    />
                ) : (
                    <Spinner />
                )}

                {(result || error) && !isLoading && (
                    <Icon
                        iconName={
                            result ? 'SkypeCircleCheck' : 'StatusErrorFull'
                        }
                        style={{
                            color: result
                                ? theme.semanticColors.successIcon
                                : theme.semanticColors.errorIcon,
                        }}
                    />
                )}
            </Stack>
        </div>
    );
};
