import React, { PropsWithChildren, useEffect } from 'react';
import { Icon, Modal, Spinner, SpinnerSize } from '@fluentui/react';
import { useState } from 'react';

interface IJsonModalProps extends PropsWithChildren<any> {
    jsonData: string;
    isOpen: boolean;
    header: string;
    closeModal: (
        ev?: React.MouseEvent<
            | HTMLButtonElement
            | HTMLDivElement
            | HTMLSpanElement
            | HTMLAnchorElement
        >
    ) => any;
    dataLoaded: boolean;
}

export const JsonModal: React.FC<IJsonModalProps> = (
    props: IJsonModalProps
) => {
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState('black');

    const modalStyle = {
        backgroundColor: 'black',
        color: 'white',
        height: '80vh',
        width: '80vw',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '10px' /* sets the border radius of the modal */,
        scrollbarWidth: 'thin' /* sets the width of the scrollbar */,
        scrollbarColor:
            '#888 rgba(0, 0, 0, 0.5)' /* sets the color of the scrollbar thumb and background */,
    };

    const dividerStyles = {
        borderBottom: '1px solid #ccc',
        width: '98%',
    };

    const headerStyles = {
        paddingLeft: '10px',
        paddingTop: '6px',
        paddingBottom: '4px',
        fontSize: '18px',
        fontWeight: 'bold',
        paddingRight:
            '50px' /* creates space on the right side of the header for the close button */,
    };

    function generateSpaces(num: number): string {
        const numDigits = num.toString().length;
        const numSpaces = 6 - numDigits;
        return ' '.repeat(numSpaces);
    }

    function removeEscapes(str: string): string {
        return str.replace(/\\(["\\/bfrtn])/g, '$1');
    }

    return (
        <div id="modal-div">
            <Modal
                isOpen={props.isOpen}
                onDismiss={props.closeModal}
                className="modal"
                styles={{ main: modalStyle }}
            >
                <div className="modal-header" style={headerStyles}>
                    {props.header}
                    <button
                        style={{
                            color: 'white',
                            backgroundColor: buttonBackgroundColor,
                            border: 'none',
                            position: 'absolute',
                            top: 10,
                            right: 25,
                        }}
                        onClick={props.closeModal}
                        onMouseEnter={() => {
                            setButtonBackgroundColor('#5A5A5A');
                        }}
                        onMouseLeave={() => {
                            setButtonBackgroundColor('black');
                        }}
                    >
                        <Icon iconName="ChromeClose" />
                    </button>
                </div>
                <hr style={dividerStyles} />
                <div className="modal-content">
                    {props.dataLoaded ? (
                        <div className="modal-textarea">
                            {removeEscapes(props.jsonData)
                                .split('\n')
                                .map((line, index) => (
                                    <pre
                                        key={index}
                                        style={{
                                            paddingLeft: '5px',
                                            lineHeight: '16px',
                                            margin: '2px',
                                        }}
                                    >
                                        {index + 1}
                                        {generateSpaces(index)}
                                        {unescape(line)}
                                    </pre>
                                ))}
                        </div>
                    ) : props.jsonData === '' ? (
                        <Spinner
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                            size={SpinnerSize.large}
                        />
                    ) : (
                        <div>
                            {props.jsonData}. Please contact the Sovereign
                            Release Management Team for support.
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

JsonModal.defaultProps = {
    jsonData: '',
    isOpen: false,
};
