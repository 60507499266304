import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { PagePersona } from './PagePersona';

export interface IPageHeaderProps {
    title: string;
    subTitle?: string;
    initial?: string;
}

export const PageHeader: React.FC<IPageHeaderProps> = (
    props: IPageHeaderProps
) => {
    return (
        <div className="PageHeader-root">
            {/* Service tree hierarchy breadcrumb trail goes here */}
            <Stack horizontal style={{ alignItems: 'center' }}>
                {props.initial && <PagePersona initial={props.initial} />}
                <Stack>
                    <Text className="PageTitle-title" variant="xLarge">
                        {props.title}
                    </Text>
                    {props.subTitle ? (
                        <div>
                            <Text
                                className="PageTitle-subTitle"
                                variant="medium"
                            >
                                {props.subTitle}
                            </Text>
                        </div>
                    ) : null}
                </Stack>
            </Stack>
        </div>
    );
};
