import React, {
    CSSProperties,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';

import { SearchBox, ISearchBoxStyles } from '@fluentui/react';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import {
    getMultiStageBuildSearchValueFromLocalStorage,
    getThemeFromString,
    setMultiStageBuildSearchValueInLocalStorage,
} from '../../../Common/util/localStorageUtils';

interface IMultiStageBuildSearchProps extends PropsWithChildren<any> {
    setSearchValue?: Dispatch<SetStateAction<string | undefined>>;
    setPageNumber?: Dispatch<SetStateAction<number>>;
    isLoaded: boolean;
    isMobile?: boolean;
}

export const MultiStageBuildSearch: React.FC<IMultiStageBuildSearchProps> = (
    props: IMultiStageBuildSearchProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');

    const divStyles: CSSProperties = {
        display: 'flex',
        margin: '6px',
        flexGrow: 1,
        width: props.isMobile ? '100%' : undefined,
    };

    const searchBoxStyles: Partial<ISearchBoxStyles> = {
        root: {
            border: 'none',
            width: '100%',
            backgroundColor: theme.palette.neutralLighter,
            fontWeight: searchValue === '' ? 'normal' : 'bold',
        },
    };

    const onClear = () => {
        if (props.setSearchValue && props.setPageNumber) {
            props.setSearchValue(undefined);
            props.setPageNumber(1);
            setSearchValue('');
            setMultiStageBuildSearchValueInLocalStorage('');
        }
    };

    const onChange = (
        event?: React.ChangeEvent<HTMLInputElement> | undefined,
        newValue?: string | undefined
    ) => {
        if (!newValue) {
            //if value is empty, refresh list
            if (props.setSearchValue && props.setPageNumber) {
                props.setSearchValue(undefined);
                props.setPageNumber(1);
            }

            setSearchValue('');
            setMultiStageBuildSearchValueInLocalStorage('');
        } else {
            setSearchValue(newValue);
        }
    };

    const onSearch = (newValue: string) => {
        if (props.setSearchValue && props.setPageNumber) {
            props.setSearchValue(newValue);
            props.setPageNumber(1);
            setMultiStageBuildSearchValueInLocalStorage(newValue);
        }
    };

    useEffect(() => {
        setDisabled(!props.isLoaded);
        const searchInStorage = getMultiStageBuildSearchValueFromLocalStorage();

        if (searchInStorage && props.setSearchValue && props.setPageNumber) {
            setSearchValue(searchInStorage);
            props.setPageNumber(1);
            props.setSearchValue(searchInStorage);
        }
    }, [props]);

    return (
        <div style={divStyles}>
            <SearchBox
                styles={searchBoxStyles}
                placeholder={'Search All Builds by Branch or Build Number'}
                onClear={onClear}
                onChange={onChange}
                onSearch={onSearch}
                value={searchValue}
                disableAnimation={true}
                showIcon={true}
                disabled={disabled}
            />
        </div>
    );
};
