import { fetchConfig } from './Common/api/ABHub/ABHubGlobalConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import { mergeStyles, getTheme, initializeIcons } from '@fluentui/react';
import { GlobalConfigErrorPage } from './HubLayout/components/ErrorPages/GlobalConfigErrorPage';
import { SigninPopup } from './HubLayout/components/AuthRequired/SigninPopup';
import * as serviceWorker from './serviceWorker';
import { CapabilityProvider } from './Common/components/Capabilities/CapabilityProvider';
import { HashRouter } from 'react-router-dom';
import { App } from './App';
import { ThemeContextProvider } from './HubLayout/components/ThemeContextProvider';

// Inject some global styles
const _theme = getTheme();
mergeStyles({
    selectors: {
        ':global(body), :global(html), :global(#root), :global(.HubApp-root), :global(.Page-root)':
            {
                margin: 0,
                padding: 0,
                height: '100vh',
            },
        ':global(body)': {
            fontFamily: _theme.fonts.medium.fontFamily,
            fontSize: _theme.fonts.medium.fontSize,
        },
        ':global(.HubApp-content), :global(.Page-content), ': {
            padding: '15px 30px',
            height: 'calc(100% - 84px)' /* 100% - (44px header + 20px top padding + 20px bottom padding) */,
            overflowY: 'auto',
        },
    },
});

// Explicitly use the Fluent UI icon font files that are
// copied into /public/icons/fonts/ (via build task in
// Azure.Bridge.Hub.AppService.csproj).  Placing these icon
// font files under the /public/ directory allows the
// icons to be packaged and useable inside the AGCs
// (instead of defaulting to fetching the icon font
// files from the public CDN).
initializeIcons('/icons/fonts/');
// NOTE: We're currently using Fluent UI 7.x, but the source
// location of the icon font files under /npm_modules/ will
// change for Fluent UI 8+.  So the build task that copies
// them into /public/ will need to be modified whenever we
// update to Fluent UI 8+.

function clearOldTokensFromLocalStorage() {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (
            key &&
            (key.includes('AG-Portal') ||
                key.includes('oidc') ||
                key.includes('agp'))
        ) {
            if (key.includes('agp') || key.includes('AG-Portal')) {
                localStorage.removeItem(key);
            } else {
                const value = localStorage.getItem(key);

                if (value && value.includes('AG-Portal')) {
                    localStorage.removeItem(key);
                }
            }
        }
    }
}

// Handle login popup
const root = document.getElementById('root');
if (window.location.hash.startsWith('#/signin-popup')) {
    ReactDOM.render(<SigninPopup />, root);
} else {
    clearOldTokensFromLocalStorage();

    // Fetch config from server
    fetchConfig()
        .then(() => {
            ReactDOM.render(
                <HashRouter>
                    <CapabilityProvider>
                        <ThemeContextProvider>
                            <App />
                        </ThemeContextProvider>
                    </CapabilityProvider>
                </HashRouter>,
                root
            );
        })
        .catch((err: Error | undefined) => {
            ReactDOM.render(<GlobalConfigErrorPage error={err} />, root);
        });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
