import { CancelToken } from 'axios';
import { agpAxiosClient } from './ABHubTokenConfig';
import {
    Banner,
    BannerGetResponse,
    BannerPutResponse,
} from '../../models/Banner';

export const getBannerData = async (
    cancelToken?: CancelToken
): Promise<Banner[]> => {
    const response = await agpAxiosClient.get<BannerGetResponse>(`api/banner`, {
        cancelToken,
    });

    return response.data.data;
};

export const setBannerData = async (
    banner: Banner,
    cancelToken?: CancelToken
): Promise<Banner> => {
    var url = `api/banner`;

    const response = await agpAxiosClient.put<BannerPutResponse>(url, banner);

    return response.data.data;
};

export const deleteBanner = async (
    id: string,
    cancelToken?: CancelToken
): Promise<boolean> => {
    var url = `api/banner/${id}`;

    const response = await agpAxiosClient.delete(url);

    return response.data.data;
};
