import { Timestamp } from '../models/Timestamp';
import moment from 'moment';

export const calculateTimeDifference = (start: Timestamp, end: Timestamp) => {
    const startTime = moment(start);
    const endTime = moment(end);
    const diff = endTime.diff(startTime);
    const duration = moment.duration(diff);

    //Get Days and subtract from duration
    const days = parseInt(duration.asDays().toString());
    duration.subtract(moment.duration(days, 'days'));

    //Get hours and subtract from duration
    const hours = parseInt(duration.hours().toString());
    duration.subtract(moment.duration(hours, 'hours'));

    //Get Minutes and subtract from duration
    const minutes = parseInt(duration.minutes().toString());
    duration.subtract(moment.duration(minutes, 'minutes'));

    if (days < 30) {
        return days > 0
            ? days + 'd ' + hours + 'h ' + minutes + 'm'
            : hours + 'h ' + minutes + 'm';
    } else {
        return '30d+';
    }
};

export const dateLocaleStringWithTimeZone = (
    inputDate: string | Date | undefined,
    timeZone?: string
): string => {
    if (inputDate) {
        return new Date(inputDate).toLocaleString(['en-US'], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
            timeZone: timeZone,
        });
    }
    return '';
};
