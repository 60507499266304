import { Spinner, Stack, Icon, Text } from '@fluentui/react';
import React, { useContext } from 'react';
import { DefaultHubButton } from './DefaultHubButton';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

interface IDefaultLoadingHubButtonProps {
    buttonText: string;
    icon: string;
    result?: any;
    error?: any;
    isLoading: boolean;
    disabled?: boolean;
    horizontal?: boolean;
    onClick?: () => void;
}

export const DefaultLoadingHubButton: React.FC<IDefaultLoadingHubButtonProps> =
    (props: IDefaultLoadingHubButtonProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);
        const stackTokens = { childrenGap: 10 };

        return (
            <div>
                {!props.isLoading ? (
                    <DefaultHubButton
                        buttonText={props.buttonText}
                        icon={props.icon}
                        disabled={props.disabled}
                        onClick={props.onClick}
                    />
                ) : (
                    <Spinner />
                )}

                {(props.result || props.error) && !props.isLoading && (
                    <Stack
                        horizontal={props.horizontal}
                        tokens={stackTokens}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Icon
                            iconName={
                                props.result
                                    ? 'SkypeCircleCheck'
                                    : 'StatusErrorFull'
                            }
                            style={{
                                color: props.result
                                    ? theme.semanticColors.successIcon
                                    : theme.semanticColors.errorIcon,
                            }}
                        />
                        {props.error && <Text>{props.error.message}</Text>}
                        {props.result && (
                            <Text>
                                Succeed with status code:{' '}
                                {props.result.statusCode}
                            </Text>
                        )}
                    </Stack>
                )}
            </div>
        );
    };
