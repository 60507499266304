import { createContext, Dispatch, Reducer } from 'react';
import { User } from 'oidc-client';

// Manage state that's used to make capability determinations
export interface CapabilityReducerState {
    agpUser?: User;
}

export enum CapabilityActionType {
    setAgpUser = 'setAgpUser',
}

export interface CapabilityReducerAction {
    type: CapabilityActionType;
    user?: User;
}

// Use a reducer to organize state change updates. The dispatch can be provided in the CapabilityDispatch
// context to allow any component to update capability state.
export const capabilityReducer: Reducer<
    CapabilityReducerState,
    CapabilityReducerAction
> = (
    state: CapabilityReducerState,
    action: CapabilityReducerAction
): CapabilityReducerState => {
    switch (action.type) {
        case CapabilityActionType.setAgpUser:
            return {
                ...state,
                agpUser: action.user,
            };
    }
};

export const CapabilityDispatch = createContext<
    Dispatch<CapabilityReducerAction>
>(() => {});
