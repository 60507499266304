export interface IJwtHeader {
    alg?: string;
    typ?: string;
    kid?: string;
    jku?: string;
    x5u?: string;
    x5t?: string;
}

export interface IJwtPayload {
    iss?: string; //	Issuer
    sub?: string; //	Subject
    aud?: string; //	Audience
    exp?: number; //	Expiration
    nbf?: number; //	Not Before
    iat?: number; //	Issued At
    jti?: string; //	JWT ID
    name?: string; //   Name
    upn?: string; //    UPN
    [x: string]: any; // Allows other indexes
}

export interface IJwt {
    header: IJwtHeader;
    payload: IJwtPayload;
    signature: string;
}

export const decodeJwt = (token: string): IJwt => {
    const splitToken = token.split('.');
    if (splitToken.length !== 3) {
        throw new Error('Malformed token.');
    }

    const tokenHeader = splitToken[0];
    const tokenPayload = splitToken[1];

    let jwtHeader: IJwtHeader = {};
    let jwtPayload: IJwtPayload = {};

    try {
        jwtHeader = JSON.parse(window.atob(tokenHeader));
    } catch {
        throw new Error('Error decoding token header.');
    }

    try {
        jwtPayload = JSON.parse(window.atob(tokenPayload));
    } catch {
        throw new Error('Error decoding token payload.');
    }

    const jwt: IJwt = {
        header: jwtHeader,
        payload: jwtPayload,
        signature: splitToken[2],
    };

    return jwt;
};

export const jwtExpAsDate = (exp: number): Date => {
    const d = new Date(0); // The '0' is important here: it instantiates it at epoch-start (00:00:00 Jan 1, 1970)
    d.setUTCSeconds(exp); // Add the epoch seconds
    return d; // Return the date value
};

export const getExpirationFromJwt = (jwt: IJwt): Date => {
    if (!jwt.payload.exp && jwt.payload.exp !== 0) {
        throw new Error("Invalid 'exp' claim in JWT.");
    }
    return jwtExpAsDate(jwt.payload.exp);
};

export const isJwtExpired = (jwt: IJwt): boolean => {
    return getExpirationFromJwt(jwt) < new Date();
};

export const getExpirationFromToken = (token: string): Date => {
    return getExpirationFromJwt(decodeJwt(token));
};

export const isTokenExpired = (token: string): boolean => {
    return getExpirationFromToken(token) < new Date();
};

export const getClaimFromJwt = (jwt: IJwt, claimType: string): any => {
    return jwt.payload[claimType];
};

export const jwtHasClaim = (
    jwt: IJwt,
    claimType: string,
    claimValue?: any
): boolean => {
    const claim = jwt.payload[claimType];
    if (!claim) {
        return false;
    } else if (!claimValue) {
        return true; // No claimValue to check, any value is ok
    } else {
        if (Array.isArray(claim)) {
            return claim.includes(claimValue);
        }
        return claim === claimValue;
    }
};
