import React, { PropsWithChildren, useEffect, useState } from 'react';
import { ThemeContext } from '../models/ThemeContext';
import {
    getThemeFromLocalStorage,
    setThemeInLocalStorage,
} from '../../Common/util/localStorageUtils';

interface IThemeContextProviderProps extends PropsWithChildren<any> {}

export const ThemeContextProvider: React.FC<IThemeContextProviderProps> = (
    props: IThemeContextProviderProps
) => {
    const [themeName, setTheme] = useState(getThemeFromLocalStorage());

    //change theme in localStorage if user changes it
    useEffect(() => {
        if (getThemeFromLocalStorage() !== themeName) {
            setThemeInLocalStorage(themeName);
        }
    }, [themeName]);

    return (
        <ThemeContext.Provider
            value={{ themeName: themeName, setTheme: setTheme }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
};
