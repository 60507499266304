import { sendMetric } from '../../Common/api/ABHub/MetricsSendApi';

export const emitPageLoadMetric = (
    pageName: string,
    portalVersion: string | undefined,
    isFirstLoad: boolean
): boolean => {
    const perfObserver = new PerformanceObserver((observedEntries) => {
        const entry: PerformanceEntry =
            observedEntries.getEntriesByType('navigation')[0];

        if (entry.duration > 0 && isFirstLoad) {
            const dimensions = {
                Browser: window.navigator.userAgent,
                BrowserVersion: window.navigator.appVersion,
                PortalVersion: portalVersion,
                PageName: pageName,
            };

            sendMetric(
                entry.duration,
                'PageVisit',
                'AzureBridgeHub',
                dimensions
            );

            return false;
        }
    });

    perfObserver.observe({
        type: 'navigation',
        buffered: true,
    });

    return true;
};

export const emitUserActionMetric = (
    actionName: string,
    actionTarget: string | undefined,
    actionResult: string,
    portalVersion: string | undefined
) => {
    const dimensions = {
        ActionName: actionName,
        ActionTarget: actionTarget,
        ActionResult: actionResult,
        Browser: window.navigator.userAgent,
        BrowserVersion: window.navigator.appVersion,
        PortalVersion: portalVersion,
    };

    sendMetric(1, 'UserAction', 'AzureBridgeHub', dimensions);
};
