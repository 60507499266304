import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { BuildSearchResult } from '../../../../models/Build';
import {
    DefaultButton,
    IButtonStyles,
    IStackStyles,
    IStackTokens,
    IconButton,
    Link,
    Panel,
    PanelType,
    Stack,
    Text,
} from '@fluentui/react';
import { getBuildDetailsPath } from '../../../../util/BuildUtils';
import { CancelToken } from 'axios';
import { AuthContext } from '../../../../../HubLayout/models/AuthContext';
import { setUserProfileData } from '../../../../../Common/api/ABHub/UserProfileApi';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { dateLocaleStringWithTimeZone } from '../../../../../Common/util/DateUtils';
import { MultiStageBuildSearch } from '../../MultiStageBuildSearch';
import { ServiceNameDropDown } from '../../../../../Common/components/ServiceNameDropDown';
import { ProjectNameDropDown } from '../../../../../Common/components/ProjectNameDropDown';

interface IMobileBuildListProps {
    list: BuildSearchResult[];
    isLoaded: boolean;
    pageNumber?: number;
    hideButton?: boolean;
    setSearchValue?: Dispatch<SetStateAction<string | undefined>>;
    setSelectedServices?: Dispatch<SetStateAction<string[]>>;
    setSelectedEnvironments?: Dispatch<SetStateAction<string[]>>;
    setSelectedDeploymentTypes?: Dispatch<SetStateAction<string[]>>;
    setSelectedProjects?: Dispatch<SetStateAction<string[]>>;
    setPageNumber?: Dispatch<SetStateAction<number>>;
    isHomePage?: boolean;
}

export const MobileBuildList: React.FC<IMobileBuildListProps> = (
    props: IMobileBuildListProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [items, setItems] = useState<BuildSearchResult[]>([]);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const { authContext, setDbUpdated } = useContext(AuthContext);
    const stackTokens: IStackTokens = { childrenGap: 20 };

    const stackStyles: Partial<IStackStyles> = {
        root: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.neutralLighter,
            width: '100%',
        },
    };

    //const setRecentlyViewedBuild = useCallback(
    //    async (buildCorrelationId: string, cancelToken?: CancelToken) => {
    //        if (authContext.userProfile) {
    //            const response = await setUserProfileData(
    //                authContext.userProfile?.alias,
    //                buildCorrelationId,
    //                cancelToken
    //            );

    //            if (setDbUpdated) {
    //                setDbUpdated(true);
    //            }

    //            return response;
    //        }
    //    },
    //    [authContext]
    //);

    const loadMoreButtonStyles: IButtonStyles = {
        root: {
            display: props.hideButton ? 'none' : 'block',
            width: '100%',
            marginTop: '10px',
            background: theme.palette.neutralLighter,
            color: theme.palette.neutralPrimary,
        },
        rootHovered: {
            color: theme.palette.neutralPrimaryAlt,
            backgroundColor: theme.palette.neutralLight,
        },
    };

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        setItems(props.list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.list, props.isLoaded]);

    const loadMore = () => {
        if (props.setPageNumber && props.pageNumber) {
            props.setPageNumber(props.pageNumber + 1);
        }
    };

    const showFilterComponents = () => {
        setShowFilters(!showFilters);
    };

    return (
        <div className="MobileBuildList-root">
            {!props.isHomePage && (
                <IconButton
                    iconProps={{ iconName: 'Filter' }}
                    style={{
                        display: 'flex',
                        color: theme.palette.neutralPrimary,
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                    onClick={showFilterComponents}
                />
            )}

            <Panel
                isLightDismiss
                isOpen={showFilters}
                onDismiss={showFilterComponents}
                closeButtonAriaLabel="Close"
                headerText="Filters"
                type={PanelType.custom}
            >
                <Stack tokens={stackTokens} styles={stackStyles}>
                    <MultiStageBuildSearch
                        setSearchValue={props.setSearchValue}
                        setPageNumber={props.setPageNumber}
                        isLoaded={props.isLoaded}
                        isMobile
                    />
                    <ServiceNameDropDown
                        setSelectedServices={props.setSelectedServices}
                        setPageNumber={props.setPageNumber}
                        isLoaded={props.isLoaded}
                        isMobile
                    />
                    {/*<ProjectNameDropDown*/}
                    {/*    setSelectedKeys={props.setSelectedProjects}*/}
                    {/*    setPageNumber={props.setPageNumber}*/}
                    {/*    isLoaded={props.isLoaded}*/}
                    {/*    isMobile*/}
                    {/*/>*/}
                </Stack>
            </Panel>

            {items.map((item) => {
                return (
                    //<Link
                    //    href={
                    //        '#' +
                    //        getBuildDetailsPath(
                    //            item.buildCorrelationId || ''
                    //        )
                    //    }
                    //    /*onClick={() => {*/
                    //    /*    setRecentlyViewedBuild(item.buildCorrelationId);*/
                    //    /*}}*/
                    //>
                    <Stack
                        key={item.buildCorrelationId}
                        tokens={stackTokens}
                        horizontal
                        style={{
                            backgroundColor: theme.palette.neutralQuaternaryAlt,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px 10px 10px 20px',
                            marginBottom: '10px',
                            boxShadow: 'inset 6px 0 0 0 ',
                            //getStatusIconProperties(
                            //    item.completionIndicator,
                            //    theme
                            //).iconColor,
                        }}
                    >
                        <Stack
                            className="TextStack"
                            tokens={{ childrenGap: 8 }}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <div>
                                <Text
                                    variant="medium"
                                    style={{
                                        float: 'right',
                                        paddingTop: '6px',
                                        paddingLeft: '6px',
                                        maxWidth: '20%',
                                    }}
                                >
                                    {/*{item.environments}*/}
                                </Text>

                                <Stack>
                                    <Text variant="xLarge">
                                        {item.buildBranch}
                                    </Text>
                                    <Text variant="xSmall">
                                        {'Build Number: ' + item.buildNumber}
                                    </Text>
                                    <Text variant="xSmall">
                                        {item.projectName}
                                    </Text>
                                </Stack>
                            </div>
                            <Text
                                variant="medium"
                                style={{ fontWeight: 'bolder' }}
                            >
                                {item.serviceTreeName || item.serviceTreeId}
                            </Text>

                            <div>
                                <Text
                                    variant="medium"
                                    style={{
                                        float: 'right',
                                        color: theme.palette.neutralPrimaryAlt,
                                        paddingLeft: '6px',
                                        maxWidth: '60%',
                                    }}
                                >
                                    {dateLocaleStringWithTimeZone(
                                        item.createdOn
                                    )}
                                </Text>

                                <Text
                                    variant="medium"
                                    style={{
                                        color: theme.palette.neutralTertiary,
                                    }}
                                >
                                    {item.serviceTreeName}
                                </Text>
                            </div>
                        </Stack>

                        {/*<Icon*/}
                        {/*    iconName="ChevronRight"*/}
                        {/*    style={{*/}
                        {/*        color: theme.palette.neutralPrimary,*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Stack>
                    /* </Link>*/
                );
            })}

            {!props.isHomePage && (
                <DefaultButton
                    styles={loadMoreButtonStyles}
                    text={'Load More'}
                    onClick={loadMore}
                />
            )}

            {props.isLoaded && (props.list.length < 1 || items.length < 1) && (
                <p>No data found.</p>
            )}
        </div>
    );
};
