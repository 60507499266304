import { Stack, TextField } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { usePromise } from '../../../../Common/hooks/usePromise';
import { resetFileHash } from '../../../../AGSS/api/AGSSApi';
import { DefaultLoadingHubButton } from '../../../../HubLayout/components/Utilities/DefaultLoadingHubButton';
import { JsonResult } from '../../../../HubLayout/components/Utilities/JsonResult';

interface IResetFileHashProps {}

export const ResetFileHash: React.FC<IResetFileHashProps> = (
    props: IResetFileHashProps
) => {
    const [hashValue, setHashValue] = useState<string>('');
    const [envValue, setEnvValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const stackTokens = { childrenGap: 10 };

    const handleHashChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined
    ) => {
        setHashValue(newValue ?? '');
    };

    const handleEnvChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined
    ) => {
        setEnvValue(newValue ?? '');
    };

    const resetFileHashCallback = useCallback(async () => {
        if (hashValue && envValue) {
            const result = await resetFileHash(hashValue, envValue);
            setIsLoading(false);
            return result;
        }
    }, [hashValue, envValue]);

    const [result, error, isLoaded, start, cancel] = usePromise(
        resetFileHashCallback,
        false
    );

    const handleResetHashClick = () => {
        setIsLoading(true);
        start();
    };

    return (
        <Stack tokens={stackTokens}>
            <TextField
                label="Hash"
                placeholder="Enter the hash value"
                value={hashValue}
                onChange={handleHashChange}
            />
            <TextField
                label="Environment"
                placeholder="Enter the environment"
                value={envValue}
                onChange={handleEnvChange}
            />

            <DefaultLoadingHubButton
                horizontal
                buttonText="Reset File Hash"
                icon="Refresh"
                result={result}
                error={error}
                isLoading={isLoading}
                disabled={!hashValue || !envValue}
                onClick={handleResetHashClick}
            />

            <JsonResult data={result} />
        </Stack>
    );
};
