import { routeKeys } from '../../../Common/util/RouteUtils';

export interface SearchType {
    key: number;
    text: string;
    placeholder: string;
    searchUrl: string;
    baseUrl: string; // navigating to a url matching this will swap the search to this type
}

export const SearchTypes: SearchType[] = [
    {
        text: 'Artifacts',
        placeholder: 'Search by Artifact ID',
        searchUrl: routeKeys.Features.Releases.ArtifactSearch,
        baseUrl: routeKeys.Features.Releases.Base,
    },
    // {
    //     text: 'Releases',
    //     placeholder: 'Search by Release ID',
    //     searchUrl: routeKeys.Features.Releases.Search,
    //     baseUrl: routeKeys.Features.Base,
    // },
].map((s, i) => ({ ...s, key: i })); // set key=index
