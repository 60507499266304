import React, { CSSProperties, useEffect, useState } from 'react';
import {
    FontSizes,
    Icon,
    IStackStyles,
    IStackTokens,
    Stack,
    Text,
    Theme,
} from '@fluentui/react';
import {
    CompletionStatus,
    CompletionStatusInfo,
    getStatusIconProperties,
} from '../../../../models/Timeline';

interface ICompletionIndicatorIconProps {
    theme: Theme;
    completionIndicator: string;
    completionIndicatorString?: string;
    showText?: boolean;
}

export const CompletionIndicatorIcon: React.FC<ICompletionIndicatorIconProps> =
    (props: ICompletionIndicatorIconProps) => {
        const [completionStatusInfo, setCompletionStatusInfo] =
            useState<CompletionStatusInfo>(CompletionStatus['NotStarted']);
        const statusStackTokens: IStackTokens = { childrenGap: 5 };
        const statusStackStyles: Partial<IStackStyles> = {
            root: {
                alignItems: 'center',
            },
        };

        const textStyle: CSSProperties = {
            color: props.theme.palette.neutralDark,
        };

        useEffect(() => {
            let info = getStatusIconProperties(
                props.completionIndicator,
                props.theme
            );
            setCompletionStatusInfo(info);
        }, [props.completionIndicator, props.theme]);

        return (
            <Stack
                horizontal
                styles={statusStackStyles}
                tokens={statusStackTokens}
            >
                <Icon
                    style={{
                        fontSize: FontSizes.size16,
                        color: completionStatusInfo.iconColor,
                    }}
                    iconName={completionStatusInfo.iconName}
                    title={completionStatusInfo.tooltip}
                    ariaLabel={'Status icon'}
                />
                {props.showText && (
                    <Text variant={'medium'} style={textStyle}>
                        {completionStatusInfo.name}
                    </Text>
                )}
            </Stack>
        );
    };
