import {
    IMessageBarStyles,
    Link,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../models/ThemeContext';
import { getBannerData } from '../../../Common/api/ABHub/BannerApi';
import { CancelToken } from 'axios';
import { usePromise } from '../../../Common/hooks/usePromise';
import { Banner } from '../../../Common/models/Banner';

interface IMessageBarProps extends PropsWithChildren<any> {}

export const GeneralMessageBar: React.FC<IMessageBarProps> = (
    props: IMessageBarProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const fetchMessages = useCallback(async (cancelToken?: CancelToken) => {
        const bannerMessages = await getBannerData(cancelToken);

        return bannerMessages;
    }, []);

    const [bannerMessages, error, isLoaded] = usePromise(fetchMessages, true);

    const [banners, setBanners] = useState<Banner[]>([]);

    const dismissGeneralEgressMessage = (banner: Banner) => {
        if (banners && banners.length > 0) {
            const updatedBannerMessages = banners.map((message) => {
                if (message.id === banner.id) {
                    return {
                        ...message,
                        isDismissed: true,
                    };
                } else {
                    return message;
                }
            });

            setBanners(updatedBannerMessages);
        }
    };

    const getMessageBarType = (type: string) => {
        switch (type) {
            case 'General':
                return MessageBarType.info;
            case 'Warning':
                return MessageBarType.warning;
            case 'Error':
                return MessageBarType.error;
        }
    };

    const getMessageBarStyle = (type: string): IMessageBarStyles => {
        const commonStyles = {
            innerText: {
                fontSize: theme.fonts.medium.fontSize,
                color: theme.semanticColors.bodyText,
            },
            icon: {
                color: theme.palette.black,
            },
            dismissal: { color: theme.palette.black },
        };

        switch (type) {
            case 'General':
                return commonStyles;
            case 'Warning':
                return {
                    ...commonStyles,
                    innerText: {
                        ...commonStyles.innerText,
                        color: theme.semanticColors.messageText,
                    },
                    icon: {
                        ...commonStyles.icon,
                        color:
                            themeContext.themeName === 'dark'
                                ? theme.palette.white
                                : theme.palette.black,
                    },
                };
            case 'Error':
                return {
                    ...commonStyles,
                    innerText: {
                        ...commonStyles.innerText,
                        color:
                            themeContext.themeName === 'dark'
                                ? theme.palette.white
                                : theme.palette.black,
                    },
                    icon: {
                        ...commonStyles.icon,
                        color: theme.palette.redDark,
                    },
                };
            default:
                return commonStyles;
        }
    };

    const isValidDate = (startDate: Date, endDate: Date) => {
        const now = new Date();
        const converetedStartDate = new Date(startDate); // Convert to proper format
        const convertedEndDate = new Date(endDate); // Convert to proper format

        return (
            now.getTime() > converetedStartDate.getTime() &&
            now.getTime() < convertedEndDate.getTime()
        );
    };

    useEffect(() => {
        if (bannerMessages && bannerMessages.length > 0) {
            setBanners(bannerMessages);
        }
    }, [bannerMessages, error]);

    return (
        <div className="MessageBar-root" style={{ zIndex: 1 }}>
            {banners.map(
                (line, index) =>
                    line.isActive &&
                    !line.isDismissed &&
                    isValidDate(line.startDate, line.endDate) && (
                        <MessageBar
                            key={index}
                            messageBarType={getMessageBarType(
                                line.typeString ?? 'General'
                            )}
                            isMultiline={true}
                            onDismiss={() => dismissGeneralEgressMessage(line)}
                            dismissButtonAriaLabel="Close"
                            styles={getMessageBarStyle(
                                line.typeString ?? 'General'
                            )}
                        >
                            <div>
                                {line.message
                                    .split(
                                        /((?:https?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)/g
                                    )
                                    .map((word, index) => {
                                        if (/^https?:\/\//.test(word)) {
                                            return (
                                                <Link
                                                    style={{
                                                        color: theme
                                                            .semanticColors
                                                            .bodyText,
                                                    }}
                                                    key={index}
                                                    href={word}
                                                    target="_blank" // opens in a new tab or window
                                                >
                                                    {word}
                                                </Link>
                                            );
                                        } else {
                                            return (
                                                <span key={index}>{word} </span>
                                            );
                                        }
                                    })}
                            </div>
                        </MessageBar>
                    )
            )}
        </div>
    );
};
