import React, { useContext } from 'react';
import { SiteHeader } from '../PageLayout/SiteHeader';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { PageTitle } from '../PageLayout/PageTitle';
import { PageContent } from '../PageLayout/PageContent';
import { TokenRefreshError } from '../../../Common/errors/TokenRefreshError';
import { AuthorizationFailureError } from '../../../Common/errors/AuthorizationFailureError';
import { UnauthorizedError } from '../../../Common/errors/UnauthorizedError';

interface IUnauthorizedProps {
    error?: Error;
}

export const UnauthorizedPage: React.FC<IUnauthorizedProps> = (
    props: IUnauthorizedProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    var message =
        'There was an error logging you in' +
        (props.error ? `: ${props.error?.message}` : '.');

    if (props.error instanceof TokenRefreshError) {
        message = 'Your access has expired. Please click Login.';
    } else if (props.error instanceof AuthorizationFailureError) {
        message =
            'Sovereign Release Manager is for tented individuals only. Our system is currently unable to determine your tenting status. You may click Login to try again.';
    } else if (props.error instanceof UnauthorizedError) {
        message =
            'Sovereign Release Manager is for tented individuals only. Please work with your manager to verify your tenting status and try again.';
    } else if (props.error?.message === 'Network Error') {
        message = 'There was an error contacting the authorization service.';
    }

    return (
        <div
            className="Page-root"
            style={{
                backgroundColor: theme.palette.neutralLighter,
            }}
        >
            <SiteHeader showSearch={false} />

            <div className="Page-content">
                <PageTitle title="Unauthorized" />

                <PageContent>{message}</PageContent>
            </div>
        </div>
    );
};
