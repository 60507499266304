import {
    getConfig,
    GlobalConfigProperties,
} from '../../api/ABHub/ABHubGlobalConfig';
import { CapabilityReducerState } from './capabilityReducer';
import {
    convertTrueFalseValueToCapabilityState,
    getSpecificClaimValue,
    getTrueFalseClaimValue,
} from './capabilityUtils';

export enum CapabilityState {
    unavailable = -1,
    unknown = 0,
    available = 1,
}

export enum Capability {
    // env/cloud
    dev = 'Development',
    test = 'Test',
    ppe = 'PPE',
    prod = 'Production',
    public = 'Public',
    ussec = 'USSec',
    usnat = 'USNat',

    // user claims
    usnatLogicalAccess = 'usnatLogicalAccess',
    ussecLogicalAccess = 'ussecLogicalAccess',
    ex_tent = 'ex_tent',
    rx_tent = 'rx_tent',
    sawAccess = 'sawAccess',
    artifactRereplicateRole = 'artifactRereplicateRole',
    artifactActivateRole = 'artifactActivateRole',
    betaUIFeaturesRole = 'betaUIFeaturesRole',
    bridgeAdminRole = 'bridgeAdminRole',
    srmAdminRole = 'srmAdminRole',
    srmReaderRole = 'srmReaderRole',
}

// Define how to get capability state for a given capability.
// To track info (eg: logged-in user) as state, add it to capabilityReducer.ts.
export const getCapabilityState = (
    capability: Capability,
    state: CapabilityReducerState
): CapabilityState => {
    switch (capability) {
        case Capability.dev:
        case Capability.test:
        case Capability.ppe:
        case Capability.prod:
            return getConfig(GlobalConfigProperties.Environment) === capability
                ? CapabilityState.available
                : CapabilityState.unavailable;
        case Capability.public:
        case Capability.ussec:
        case Capability.usnat:
            return getConfig(GlobalConfigProperties.Cloud) === capability
                ? CapabilityState.available
                : CapabilityState.unavailable;
        case Capability.usnatLogicalAccess:
            return getTrueFalseClaimValue(
                state.agpUser,
                'usnat_logical_access'
            );
        case Capability.ussecLogicalAccess:
            return getTrueFalseClaimValue(
                state.agpUser,
                'ussec_logical_access'
            );
        case Capability.ex_tent:
            return getTrueFalseClaimValue(state.agpUser, 'ex_tent');
        case Capability.rx_tent:
            return getTrueFalseClaimValue(state.agpUser, 'rx_tent');
        case Capability.artifactRereplicateRole:
            return getSpecificClaimValue(
                state.agpUser,
                'roles',
                'Artifact.Rereplicate'
            );
        case Capability.artifactActivateRole:
            return getSpecificClaimValue(
                state.agpUser,
                'roles',
                'Artifact.Activate'
            );
        case Capability.betaUIFeaturesRole:
            return getSpecificClaimValue(state.agpUser, 'roles', 'Beta.UI');
        case Capability.bridgeAdminRole:
            return getSpecificClaimValue(
                state.agpUser,
                'roles',
                'Bridge.Admin'
            );
        case Capability.srmAdminRole:
            return getSpecificClaimValue(state.agpUser, 'roles', 'Srm.Admin');
        case Capability.srmReaderRole:
            return getSpecificClaimValue(state.agpUser, 'roles', 'Srm.Reader');
        default:
            return CapabilityState.unknown;
    }
};
