import React, { PropsWithChildren } from 'react';
import {
    CommandBar,
    ICommandBarItemProps,
    ICommandBarStyles,
} from '@fluentui/react';

interface ICBFilterProps extends PropsWithChildren<any> {
    filter: any;
}

export const CBFilter: React.FC<ICBFilterProps> = (props: ICBFilterProps) => {
    const items: ICommandBarItemProps[] = [
        {
            key: 'filter',
            onRender: () => props.filter,
        },
    ];
    const farItems: ICommandBarItemProps[] = [];

    const commandBarStyles: Partial<ICommandBarStyles> = {
        root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            paddingLeft: 5,
        },
    };

    return (
        <CommandBar
            styles={commandBarStyles}
            items={items}
            farItems={farItems}
        />
    );
};
