import axios from 'axios';
import { UserManagerSettings } from 'oidc-client';
import { getConfig, GlobalConfigProperties } from './ABHubGlobalConfig';

export const agpAxiosClient = axios.create();

export function getAgpOidcConfig() {
    let agpOidcConfig: UserManagerSettings = {
        authority: getConfig(GlobalConfigProperties.StsUrl),
        client_id: getConfig(GlobalConfigProperties.AadClient),
        scope: 'openid profile release.read release.sign offline_access',
        acr_values: 'extIdp:AAD', // use AAD as external IDP

        response_type: 'code',
        loadUserInfo: false,
        automaticSilentRenew: true,
        monitorSession: false, // don't monitor for logouts (this was causing a refresh loop with login_required responses)
        redirect_uri: window.location.origin,
        post_logout_redirect_uri: window.location.origin,
    };
    return agpOidcConfig;
}
