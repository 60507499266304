import React, { PropsWithChildren, useMemo, useReducer } from 'react';
import { CapabilityContext } from './CapabilityContext';
import { getCapabilityState, Capability, CapabilityState } from './capability';
import { CapabilityDispatch, capabilityReducer } from './capabilityReducer';

interface ICapabilityProviderProps extends PropsWithChildren<any> {}

export const CapabilityProvider: React.FC<ICapabilityProviderProps> = (
    props: ICapabilityProviderProps
) => {
    // Track state we need for capability determination using a reducer
    const [state, dispatch] = useReducer(capabilityReducer, {});

    const capabilityContextValue = useMemo(() => {
        //get capability state
        const get = (capability: Capability): CapabilityState => {
            return getCapabilityState(capability, state);
        };

        //check if capability is available
        const check = (capability: Capability): boolean => {
            return get(capability) === CapabilityState.available;
        };

        return { get, check };
    }, [state]);

    return (
        // By providing the dispatch to update reducer state separately from the capabilities themselves,
        // we can avoid unnecessarily rerendering components that want to update the state.
        <CapabilityDispatch.Provider value={dispatch}>
            <CapabilityContext.Provider value={capabilityContextValue}>
                {props.children}
            </CapabilityContext.Provider>
        </CapabilityDispatch.Provider>
    );
};
