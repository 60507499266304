import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { Deployment } from '../../models/Deployment';
import { Build } from '../../models/Build';
import { IStackTokens, Panel, PanelType, Stack } from '@fluentui/react';
import { useIsMobile } from '../../../Common/hooks/useIsMobile';
import { DeploymentMetadata } from './Display/DeploymentMetadata';
import { BuildMetadata } from '../Build/Display/BuildMetadata';

interface IMetadataDetailsPanel {
    isOpen: boolean;
    dismissPanel:
        | ((
              ev?:
                  | React.SyntheticEvent<HTMLElement, Event>
                  | KeyboardEvent
                  | undefined
          ) => void)
        | undefined;
    entity: Deployment | Build | undefined;
    isLoaded: boolean;
}

export const MetadataDetailsPanel: React.FC<IMetadataDetailsPanel> = (
    props: IMetadataDetailsPanel
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const isMobile = useIsMobile();
    const stackTokens: IStackTokens = { childrenGap: 10 };

    // JavaScript/TypeScript's weird way of type checking
    const isDeployment = (
        selectedItem: Deployment | Build | undefined
    ): selectedItem is Deployment => {
        // Have to use a unique property that deployment has in order to check if it is a deployment or not
        return (selectedItem as Deployment)?.deploymentGuid !== undefined;
    };

    return (
        <div className="DetailsPanel-root">
            <Panel
                isLightDismiss
                isOpen={props.isOpen}
                onDismiss={props.dismissPanel}
                closeButtonAriaLabel="Close"
                headerText={
                    isDeployment(props.entity)
                        ? 'Deployment Metadata'
                        : 'Build Metadata'
                }
                type={PanelType.custom}
                customWidth={!isMobile ? '400px' : undefined}
            >
                <Stack tokens={stackTokens}>
                    {isDeployment(props.entity) ? (
                        <DeploymentMetadata item={props.entity} />
                    ) : (
                        <BuildMetadata item={props.entity!} />
                    )}
                </Stack>
            </Panel>
        </div>
    );
};
