import React, { PropsWithChildren, useContext } from 'react';
import { Modal, SpinnerSize } from '@fluentui/react';
import { Spinner } from '@fluentui/react';
import { commonModalStyles } from '../../../Common/util/CommonStyles';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

interface ILoadingProps extends PropsWithChildren<any> {}

export const LoadingPage: React.FC<ILoadingProps> = () => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const layerStyles = {
        root: {
            selectors: {
                '.ms-Modal > .ms-Overlay': {
                    backgroundColor:
                        themeContext.themeName === 'dark'
                            ? theme.palette.neutralTertiaryAlt
                            : theme.palette.blackTranslucent40,
                },
            },
        },
    };

    return (
        <Modal
            titleAriaId={'login-modal'}
            isOpen={true}
            isBlocking={false}
            containerClassName={commonModalStyles(theme).container}
            layerProps={{ styles: layerStyles }}
            isDarkOverlay={true}
        >
            <div className={commonModalStyles(theme).header}>
                <span id={'login-modal'}>Logging you in...</span>
            </div>
            <div className={commonModalStyles(theme).body}>
                <Spinner size={SpinnerSize.large} />
            </div>
        </Modal>
    );
};
