import { IStackTokens, Stack, Text } from '@fluentui/react';
import React from 'react';

interface IDataLabelProps {
    label: string;
    value: string | undefined;
}

export const DataLabel: React.FC<IDataLabelProps> = (
    props: IDataLabelProps
) => {
    const stackTokens: IStackTokens = { childrenGap: 10 };

    return (
        <Stack
            horizontal
            tokens={stackTokens}
            style={{
                margin: '6px 0px',
            }}
        >
            <strong>{props.label}</strong>
            <Text variant="medium">{props.value}</Text>
        </Stack>
    );
};
