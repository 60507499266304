import React from 'react';
import { ITextStyles, Text } from '@fluentui/react';
import { CompletionStatusTracker } from '../../../../models/Timeline';

interface IStatusTrackerProps {
    completionStatusTracker: CompletionStatusTracker | undefined;
    textColor: string;
}

export const StatusTracker: React.FC<IStatusTrackerProps> = (
    props: IStatusTrackerProps
) => {
    const textStyles: Partial<ITextStyles> = {
        root: {
            color: props.textColor,
            fontWeight: 'bold',
        },
    };

    const getCompletionStatusTracker = (
        completionStatusTracker: CompletionStatusTracker | undefined
    ): string => {
        if (completionStatusTracker) {
            return (
                '(' +
                completionStatusTracker.currentStep +
                '/' +
                completionStatusTracker.totalSteps +
                ')'
            );
        }
        return '';
    };

    return (
        <Text variant={'mediumPlus'} styles={textStyles}>
            {getCompletionStatusTracker(props.completionStatusTracker)}
        </Text>
    );
};
