import {
    IconButton,
    IContextualMenuItem,
    IStackTokens,
    Stack,
    Text,
} from '@fluentui/react';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { CompletionIndicatorIcon } from '../Status/CompletionIndicatorIcon';
import { Ellipsis } from '../../../../../HubLayout/components/Utilities/Ellipsis';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { Build } from '../../../../models/Build';
import {
    calculateTimeDifference,
    dateLocaleStringWithTimeZone,
} from '../../../../../Common/util/DateUtils';
import { Deployment } from '../../../../models/Deployment';
import { RestartAgrmProcessButton } from '../../../Deployment/RestartAgrmProcessButton';
import { AgrmType } from '../../../../api/AGRMApi';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../../../Common/api/ABHub/ABHubGlobalConfig';
import { InternalLink } from '../../../../../Common/components/Links';
import { getBuildDetailsPath } from '../../../../util/BuildUtils';

interface IMobileReleaseStageEntityInfoProps {
    entity: Deployment | Build;
    setSelected: Dispatch<SetStateAction<Deployment | Build | undefined>>;
    showPanel: () => void;
    menuItems: IContextualMenuItem[];
}

export const MobileReleaseStageEntityInfo: React.FC<IMobileReleaseStageEntityInfoProps> =
    (props: IMobileReleaseStageEntityInfoProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);

        const stackTokens: IStackTokens = { childrenGap: 10 };

        const restartAgrmProcessFlag = getConfig(
            GlobalConfigProperties.RestartAgrmActive
        );

        // JavaScript/TypeScript's weird way of type checking
        const isDeployment = (
            selectedItem: Deployment | Build | undefined
        ): selectedItem is Deployment => {
            // Have to use a unique property that deployment has in order to check if it is a deployment or not
            return (selectedItem as Deployment)?.deploymentGuid !== undefined;
        };

        const onViewDetailsClick = () => {
            props.setSelected(props.entity);
            props.showPanel();
        };

        const calculateDuration = () => {
            if (props.entity.currentReplication) {
                const currentRep = props.entity.currentReplication;

                if (
                    currentRep.createdOn &&
                    currentRep.currentStatus?.updatedOn
                ) {
                    return currentRep.completionIndicatorString !== 'Processing'
                        ? calculateTimeDifference(
                              currentRep.createdOn,
                              currentRep.currentStatus.updatedOn
                          )
                        : calculateTimeDifference(
                              currentRep.createdOn,
                              new Date().toUTCString()
                          );
                }
            }

            return '';
        };

        const getUniqueIdentifier = (
            branch: string | undefined,
            buildNumber: string | undefined,
            componentName: string | undefined
        ) => {
            if (componentName !== undefined && componentName.trim()) {
                return branch + '/' + buildNumber + '/' + componentName;
            } else {
                return branch + '/' + buildNumber;
            }
        };

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    border: 'solid 1px ' + theme.palette.neutralLighterAlt,
                    margin: '5px 0px',
                }}
            >
                <Stack
                    tokens={stackTokens}
                    style={{
                        width: '100%',
                    }}
                >
                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Stack tokens={stackTokens}>
                            <Text variant="medium">
                                {isDeployment(props.entity) ? (
                                    props.entity.displayNameParsed
                                ) : (
                                    <InternalLink
                                        value={
                                            props.entity.buildNumber || 'Build'
                                        }
                                        url={
                                            '#' +
                                            getBuildDetailsPath(
                                                props.entity
                                                    .buildInstanceCorrelationId
                                            )
                                        }
                                        title="View build details"
                                    />
                                )}
                            </Text>
                            <Stack horizontal tokens={{ childrenGap: 6 }}>
                                <CompletionIndicatorIcon
                                    completionIndicator={
                                        props.entity.currentReplication
                                            ?.completionIndicator ?? 'Unknown'
                                    }
                                    theme={theme}
                                    showText
                                />
                            </Stack>
                        </Stack>

                        <Stack
                            horizontal
                            tokens={{ childrenGap: 6 }}
                            style={{
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                iconProps={{ iconName: 'AirplaneSolid' }}
                                onClick={onViewDetailsClick}
                            />

                            {restartAgrmProcessFlag && (
                                <RestartAgrmProcessButton
                                    uniqueIdentifier={
                                        isDeployment(props.entity)
                                            ? props.entity.uniqueIdentifier
                                            : getUniqueIdentifier(
                                                  props.entity.buildBranch,
                                                  props.entity.buildNumber,
                                                  props.entity
                                                      .buildComponentName
                                              )
                                    }
                                    type={
                                        isDeployment(props.entity)
                                            ? AgrmType.Deployment
                                            : AgrmType.Build
                                    }
                                    highSideReset={
                                        props.entity.currentReplication
                                            ?.highSideReset
                                    }
                                />
                            )}

                            <Ellipsis menuItems={props.menuItems} />
                        </Stack>
                    </Stack>

                    <Text variant="small">
                        {isDeployment(props.entity)
                            ? props.entity.deploymentType
                            : props.entity.buildBranch}
                    </Text>

                    {!isDeployment(props.entity) &&
                        props.entity.buildComponentName && (
                            <Text variant="small">
                                {props.entity.buildComponentName}
                            </Text>
                        )}

                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text variant="small">
                            Started:{' '}
                            {dateLocaleStringWithTimeZone(
                                props.entity.currentReplication?.createdOn
                            )}
                        </Text>

                        <Text variant="small">
                            {props.entity.currentReplication?.environmentId}
                        </Text>
                    </Stack>

                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text variant="small">
                            Attempts: {props.entity.replications.length}
                        </Text>
                        <Text variant="small">
                            Duration: {calculateDuration()}
                        </Text>
                    </Stack>

                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text variant="small">
                            Status:{' '}
                            {
                                props.entity.currentReplication?.currentStatus
                                    .name
                            }
                        </Text>
                        <Text variant="small">
                            {dateLocaleStringWithTimeZone(
                                props.entity.currentReplication?.failedOn ??
                                    props.entity.currentReplication?.lastUpdated
                            )}
                        </Text>
                    </Stack>
                </Stack>
            </div>
        );
    };
