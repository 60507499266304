import { Icon, Stack, Text } from '@fluentui/react';
import React, { useEffect, useState, useContext } from 'react';
import { ReleaseStage } from '../../../models/ReleaseStage';
import { stackStyles } from '../../../util/StyleUtils';
import { CompletionIndicatorIcon } from './Status/CompletionIndicatorIcon';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { StatusTracker } from './Status/StatusTracker';
import { getStatusIconProperties } from '../../../models/Timeline';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';

interface IReleaseStageCompactDetails {
    stage: ReleaseStage | undefined;
}

export const ReleaseStageCompactDetails: React.FC<IReleaseStageCompactDetails> =
    (props: IReleaseStageCompactDetails) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);
        const [stage, setStage] = useState<ReleaseStage>();

        useEffect(() => {
            if (props.stage) {
                setStage(props.stage);
            }
        }, [props.stage]);

        const iconStyles = {
            paddingRight: '4px',
        };

        const generateTitle = (): string => {
            if (stage && stage.stageName) {
                var strLength = 100;
                if (stage.stageName.length > strLength) {
                    return stage.stageName.substring(0, strLength) + '...';
                } else {
                    return stage.stageName;
                }
            }

            return 'Stage ' + stage?.releaseStageCorrelationId;
        };

        return stage ? (
            <div style={{ width: '100%' }}>
                <Stack
                    style={{
                        display: 'flex',
                        height: '50px',
                    }}
                >
                    <Text variant="large">{generateTitle()}</Text>
                    <Stack
                        horizontal
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        {stage.completionIndicator && (
                            <Stack
                                horizontal
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Stack
                                    horizontal
                                    className={
                                        stackStyles.paddedAndCenteredStack
                                    }
                                >
                                    <CompletionIndicatorIcon
                                        completionIndicator={
                                            stage.completionIndicator
                                        }
                                        theme={theme}
                                        showText={true}
                                    />
                                    <StatusTracker
                                        completionStatusTracker={
                                            stage.completionStatusTracker
                                        }
                                        textColor={
                                            getStatusIconProperties(
                                                stage.completionIndicator,
                                                theme
                                            ).iconColor || ''
                                        }
                                    />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </div>
        ) : (
            <></>
        );
    };
