import { FontSizes, IColumn, mergeStyles, Theme } from '@fluentui/react';
import { Dispatch, SetStateAction } from 'react';

export const columnSort = <T>(
    column: IColumn,
    columns: IColumn[],
    items: T[]
): { newColumns: IColumn[]; newItems: T[] } => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
    )[0];

    newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
    });

    const newItems = copyAndSort(
        items,
        currColumn.fieldName!,
        currColumn.isSortedDescending
    );

    return {
        newColumns: newColumns,
        newItems: newItems,
    };
};

const copyAndSort = <T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
): T[] => {
    const key = columnKey as keyof T;

    return items.slice(0).sort((a: T, b: T) => {
        // Return -1 if a should come before b, 1 if b should come before a
        let result = 0;

        // Sort nulls to the bottom (?)
        if (!a[key] && (a[key] as any) !== 0) {
            result = 1;
        } else if (!b[key] && (b[key] as any) !== 0) {
            result = -1;
        } else {
            result = a[key] > b[key] ? 1 : -1; // this is case-sensitive for strings, I'm not sure that's what we want
        }

        return isSortedDescending ? result * -1 : result;
    });
};

export const commonGridStyle = (theme: Theme, backgroundColor?: string) =>
    mergeStyles({
        selectors: {
            '.ms-DetailsList': {
                backgroundColor: backgroundColor
                    ? backgroundColor
                    : theme.palette.white,
            },
            '.ms-DetailsRow': {
                borderBottomColor: theme.semanticColors.bodyDivider,
                backgroundColor: backgroundColor
                    ? backgroundColor
                    : theme.palette.white,
            },
            '.ms-DetailsRow:hover': {
                backgroundColor: backgroundColor
                    ? theme.palette.neutralLighterAlt
                    : theme.palette.neutralLighter,
            },
            '.is-selected.ms-DetailsRow': {
                backgroundColor: backgroundColor
                    ? theme.palette.neutralLighterAlt
                    : theme.palette.neutralLighter,
            },
            '.ms-DetailsHeader': {
                backgroundColor: backgroundColor
                    ? backgroundColor
                    : theme.palette.white,
            },
            '.ms-DetailsHeader-cellName': { fontSize: FontSizes.size14 },
            '.ms-DetailsRow-cell': {
                whiteSpace: 'normal',
                alignSelf: 'center',
                fontSize: FontSizes.size14,
                color: theme.palette.neutralDark,
            },
            '.is-selected.ms-DetailsRow .ms-DetailsRow-cell > .ms-Link': {
                color: theme.palette.neutralDark,
            },
            '.ms-Link:focus': {
                color: theme.palette.neutralDark,
            },
            '.ms-DetailsRow:hover .link, .ms-DetailsRow:hover .ms-DetailsRow-cell > .link, .ms-DetailsRow:hover .ms-Link, .ms-DetailsRow:hover .ms-DetailsRow-cell > .ms-Link':
                {
                    textDecoration: 'underline',
                    color: theme.palette.neutralDark,
                },
            '.ms-DetailsRow-cell:hover .link:hover, .ms-DetailsRow:hover .ms-DetailsRow-cell:hover > .link:hover, .ms-DetailsRow-cell:hover .ms-Link:hover, .ms-DetailsRow:hover .ms-DetailsRow-cell:hover > .ms-Link:hover':
                {
                    color: theme.palette.themePrimary,
                },
            '.external.link': {
                whiteSpace: 'nowrap',
            },
            // Only show external link and button when hovering over the row
            '.external.link, .viewDetailsButton': { visibility: 'hidden' },
            '.ms-DetailsRow:hover .external.link, .ms-DetailsRow:focus .external.link, .ms-DetailsRow:hover .viewDetailsButton, .ms-DetailsRow:focus .viewDetailsButton':
                {
                    visibility: 'visible',
                },
        },
    });

export const filterList = <T>(
    filterFunc: (i: T, newValue: string) => boolean,
    setItems: Dispatch<SetStateAction<T[]>>,
    fullSortedList: T[],
    filterValue: string
) => {
    setItems(
        fullSortedList.filter((i) => filterFunc(i, filterValue.toLowerCase()))
    );
};

export const convertToUserFriendlyByteString = (
    bytes: number | undefined
): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
