import React, { PropsWithChildren } from 'react';
import {
    IPersonaSharedProps,
    Persona,
    PersonaPresence,
    PersonaSize,
} from '@fluentui/react';
import { useIsMobile } from '../../../../Common/hooks/useIsMobile';

interface IUserPersonaProps extends PropsWithChildren<any> {
    imageUrl: string | undefined;
    name?: string | undefined;
    username?: string | undefined;
}

export const UserPersona: React.FC<IUserPersonaProps> = (
    props: IUserPersonaProps
) => {
    const isMobile = useIsMobile();

    const persona: IPersonaSharedProps = {
        imageUrl: props.imageUrl,
        text: props.name,
        secondaryText: props.username,
    };

    return (
        <div>
            <Persona
                style={{
                    maxWidth: '40px',
                }}
                {...persona}
                hidePersonaDetails={isMobile}
                size={PersonaSize.size40}
                presence={PersonaPresence.none}
                imageAlt="Profile pic"
            />
        </div>
    );
};
