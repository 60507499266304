import {
    FontSizes,
    IDocumentCardPreviewProps,
    ISpinnerStyles,
    ITextStyles,
    mergeStyleSets,
    Theme,
} from '@fluentui/react';
import { CSSProperties } from 'react';

export const previewPropsUsingIcon = (
    iconName: string,
    theme: Theme,
    themeName: string | undefined,
    backgroundColor?: string
): IDocumentCardPreviewProps => {
    return {
        previewImages: [
            {
                previewIconProps: {
                    iconName: iconName,
                    styles: {
                        root: {
                            fontSize: FontSizes.size24,
                            color:
                                themeName && themeName === 'dark'
                                    ? theme.palette.black
                                    : theme.palette.white,
                        },
                    },
                },
                height: 60,
            },
        ],
        styles: {
            previewIcon: {
                backgroundColor: backgroundColor
                    ? backgroundColor
                    : theme.palette.themePrimary,
            },
            root: {
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
    };
};
export const documentCardStyles = (
    theme: Theme,
    themeName: string | undefined,
    backgroundColor?: string
) =>
    mergeStyleSets({
        title: {
            fontSize: theme.fonts.mediumPlus.fontSize,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '0px',
        },
        subTitle: {
            fontSize: theme.fonts.medium.fontSize,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '0px',
        },
        card: {
            backgroundColor: backgroundColor
                ? backgroundColor
                : themeName && themeName === 'dark'
                ? theme.palette.neutralLighter
                : theme.palette.themeLighterAlt,
            width: 'fit-content',
            height: 'fit-content',
        },
        details: {
            backgroundColor:
                themeName && themeName === 'dark'
                    ? theme.palette.neutralLighter
                    : theme.palette.themeLighterAlt,
        },
    });

export const stackStyles = mergeStyleSets({
    paddedStack: {
        padding: '12px 20px',
    },
    centeredStack: {
        alignItems: 'center',
        padding: '0px',
    },
    paddedAndCenteredStack: {
        padding: '8px 2px',
        alignItems: 'center',
    },
    overallStack: {
        height: 'fitContent',
    },
});

export const boldTextStyles: Partial<ITextStyles> = {
    root: {
        fontWeight: 'bold',
    },
};

export const sectionStyle = (theme: Theme): CSSProperties => {
    return {
        backgroundColor: theme.palette.neutralLighter,
    };
};

export const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
        alignItems: 'start',
        paddingTop: '10px',
    },
};
