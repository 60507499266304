import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '../../../HubLayout/components/ErrorPage';
import { ArtifactRouteParams } from '../../../Common/util/RouteUtils';
import { CancelToken } from 'axios';
import { getArtifactList } from '../../api/AGSSApi';
import { usePromise } from '../../../Common/hooks/usePromise';
import { PageTitle } from '../../../HubLayout/components/PageLayout/PageTitle';
import { PageContent } from '../../../HubLayout/components/PageLayout/PageContent';
import { Redirect } from '../../../Common/components/Redirect';
import { ArtifactSearchList } from './ArtifactSearchList';
import { emitPageLoadMetric } from '../../../Common/util/metricsUtil';

export const ArtifactSearchResults: React.FC = () => {
    const params = useParams<ArtifactRouteParams>();

    const getData = useCallback(
        async (cancelToken?: CancelToken) => {
            if (params.artifactId) {
                return await getArtifactList(cancelToken, params.artifactId);
            } else {
                throw new Error('Invalid artifact ID provided.');
            }
        },
        [params.artifactId] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const [result, error, isLoaded] = usePromise(getData, true);
    const data = result || [];
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const showSingleArtifactPage = (): boolean => {
        return isLoaded && data.length === 1;
    };

    // metric to measure inital page load time
    useEffect(() => {
        var emittedSuccessfully = emitPageLoadMetric(
            'ArtifactSearch',
            process.env.REACT_APP_BUILD_VERSION,
            isFirstLoad
        );

        setIsFirstLoad(emittedSuccessfully);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return error ? (
        <ErrorPage error={error} />
    ) : showSingleArtifactPage() ? (
        <Redirect pathname={`/ReleaseStatus/Artifact/${data[0].artifactId}`} />
    ) : (
        <div>
            <PageTitle title="Artifact Search Results" />

            <PageContent>
                {isLoaded && data.length === 0 ? (
                    <span>
                        No artifact was found with ID {params.artifactId}.
                    </span>
                ) : (
                    <ArtifactSearchList
                        list={data}
                        isLoaded={isLoaded}
                        //serviceType={'Service'}
                    />
                )}
            </PageContent>
        </div>
    );
};
