import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    Dispatch,
    SetStateAction,
} from 'react';
import {
    ShimmeredDetailsList,
    DetailsListLayoutMode,
    IColumn,
    CheckboxVisibility,
} from '@fluentui/react';
import {
    columnSort,
    commonGridStyle,
    filterList,
} from '../../../Common/util/tableUtils';
import { InternalLink } from '../../../Common/components/Links';
import { FilterBox } from '../../../Common/components/FilterBox';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { getArtifactDetailsPath } from '../../util/BuildUtils';
import { Artifact } from '../../models/Artifact';
import { ServiceType } from '../../../ABSM/models/ServiceType';
import { TimelineItem } from '../../models/Timeline';
interface IArtifactReleaseListProps {
    list: Artifact[];
    isLoaded: boolean;
    setCurrentFlightTimeline?: Dispatch<
        SetStateAction<TimelineItem[] | undefined>
    >;
    backgroundColor?: string;
    serviceType?: ServiceType;
    showTitle?: boolean;
    showFilterBox?: boolean;
}

export const ArtifactSearchList: React.FC<IArtifactReleaseListProps> = (
    props: IArtifactReleaseListProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [columnsList, setColumnsList] = useState<IColumn[]>([]);
    const [items, setItems] = useState<Artifact[]>([]); // current version of the list - filtered or sorted
    const [sortedItems, setSortedItems] = useState<Artifact[]>([]);

    //need this because the onColumnClick function would only use the filterValue
    //from when it was instantiated
    const filterValueRef = useRef<string>();

    const onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn
    ): void => {
        // Sort the entire list, then reapply the filter
        const sortedEntries = columnSort(column, columns, props.list);
        setColumnsList(sortedEntries.newColumns);
        setSortedItems(sortedEntries.newItems);
        filterList(
            filterItems,
            setItems,
            sortedEntries.newItems,
            filterValueRef.current || ''
        );
    };

    const columns: IColumn[] = [
        {
            key: 'artifactId',
            name: 'Artifact Id',
            minWidth: 170,
            maxWidth: 300,
            isResizable: true,
            fieldName: 'artifactId',
            onColumnClick: onColumnClick,
        },
        {
            key: 'environmentId',
            name: 'Environment',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            fieldName: 'environmentId',
            onColumnClick: onColumnClick,
        },
    ];

    const renderArtifactColumn = (
        item: Artifact,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[
            column?.fieldName as keyof Artifact
        ] as string;

        switch (column?.key) {
            case 'artifactId':
                return (
                    <InternalLink
                        value={item.artifactId || ''}
                        url={
                            '#' +
                            getArtifactDetailsPath(
                                item.artifactId || ''
                                //item.serviceTreeId || '',
                                //props.serviceType
                            )
                        }
                        title="View artifact details"
                    />
                );
            default:
                return <span>{fieldContent}</span>;
        }
    };

    //filter function
    const filterItems = (i: Artifact, newValue: string) => {
        return (
            (i.environmentId || '').toLowerCase().indexOf(newValue) > -1 ||
            (i.artifactId || '').toLowerCase().indexOf(newValue) > -1
        );
    };

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        setColumnsList(columns);
        setItems(props.list);
        setSortedItems(props.list);
    }, [props.list, props.isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="ArtifactSearchList-root">
            <FilterBox
                style={{ backgroundColor: props.backgroundColor }}
                items={sortedItems}
                setItems={setItems}
                filterFunc={filterItems}
                filterValueRef={filterValueRef}
            />
            <ShimmeredDetailsList
                setKey="single"
                className={commonGridStyle(theme, props.backgroundColor)}
                items={items}
                columns={columnsList}
                onRenderItemColumn={renderArtifactColumn}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={!props.isLoaded}
                shimmerLines={10}
                checkboxVisibility={CheckboxVisibility.hidden}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Artifacts"
            />
            {props.isLoaded && !props.list.length && <p>No data found.</p>}
        </div>
    );
};
