import React from 'react';
import { Redirect as RouterRedirect } from 'react-router-dom';
import { removeUrlFromRecentPagesListCookie } from '../../HubLayout/util/RecentPagesUtils';

interface IRedirectProps {
    pathname: string;
    state?: any;
}

export const Redirect: React.FC<IRedirectProps> = (props: IRedirectProps) => {
    // Because we're redirecting, we shouldn't include our brief
    // visit to this URL on the recent pages list. Remove it.
    removeUrlFromRecentPagesListCookie(window.location.hash);

    // Then use normal Redirect from react-router.
    return (
        <RouterRedirect
            to={{
                pathname: props.pathname,
                state: props.state,
            }}
        />
    );
};
