import { createTheme } from '@fluentui/react';

export const darkTheme = createTheme({
    palette: {
        themePrimary: '#0078d4',
        themeLighterAlt: '#eff6fc',
        themeLighter: '#deecf9',
        themeLight: '#c7e0f4',
        themeTertiary: '#71afe5',
        themeSecondary: '#2b88d8',
        themeDarkAlt: '#106ebe',
        themeDark: '#005a9e',
        themeDarker: '#004578',
        neutralLighterAlt: '#151515',
        neutralLighter: '#1d1d1d',
        neutralLight: '#252525',
        neutralQuaternaryAlt: '#2f2f2f',
        neutralQuaternary: '#373737',
        neutralTertiaryAlt: '#595959',
        neutralTertiary: '#c8c8c8',
        neutralSecondaryAlt: '#cacaca',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        accent: '#0078d4',
        black: '#f8f8f8',
        blackTranslucent40: 'rgba(248,248,248,.4)',
        white: '#252525',
        whiteTranslucent40: 'rgba(37,37,37,.4)',
    },
    semanticColors: {
        infoBackground: '#004578',
        errorText: '#e3323a',
        bodyBackground: '#252525',
        bodyBackgroundChecked: '#1d1d1d',
        listItemBackgroundChecked: '#1d1d1d',
        bodyDivider: '#2f2f2f',
        successBackground: 'rgb(16,124,16,.2)',
    },
});
