import { Stack, IconButton, IStackTokens, Text } from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { Capability } from '../../Common/components/Capabilities/capability';
import { AdminComponentCompact } from './AdminComponentCompact';
import { ThemeContext } from '../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../Common/util/localStorageUtils';
import { CapabilityContext } from '../../Common/components/Capabilities/CapabilityContext';

interface IAdminComponent {
    title: string;
    component: JSX.Element;
}

export const AdminComponent: React.FC<IAdminComponent> = (
    props: IAdminComponent
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const capabilities = useContext(CapabilityContext);
    const stackTokens: IStackTokens = { childrenGap: 10 };

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const expandSection = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="AdminComponent-root">
            <Stack
                horizontal
                style={{ alignItems: isExpanded ? undefined : 'center' }}
            >
                <IconButton
                    styles={{
                        root: {
                            paddingRight: '5px',
                        },
                    }}
                    iconProps={{
                        iconName: isExpanded
                            ? 'ChevronDownMed'
                            : 'ChevronRightMed',
                    }}
                    onClick={expandSection}
                />

                {isExpanded ? (
                    capabilities.check(Capability.srmAdminRole) && (
                        <Stack tokens={stackTokens}>
                            <Text variant="large">{props.title}</Text>
                            {props.component}
                        </Stack>
                    )
                ) : (
                    <AdminComponentCompact title={props.title} />
                )}
            </Stack>
        </div>
    );
};
