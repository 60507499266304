import React, { useContext, useState } from 'react';
import { Replication } from '../../../models/Replication';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { IconButton, IStackTokens, Stack, Text } from '@fluentui/react';
import { CompletionIndicatorIcon } from '../../MultistageReleaseData/Display/Status/CompletionIndicatorIcon';
import { Ellipsis } from '../../../../HubLayout/components/Utilities/Ellipsis';
import { MobileReplicationDetailsPanel } from './MobileReplicationDetailsPanel';

interface IMobileReplicationInfoProps {
    replication: Replication;
}

export const MobileReplicationInfo: React.FC<IMobileReplicationInfoProps> = (
    props: IMobileReplicationInfoProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const stackTokens: IStackTokens = { childrenGap: 10 };
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

    const showPanel = () => setIsPanelOpen(true);
    const hidePanel = () => setIsPanelOpen(false);

    return (
        <div className="MobileReplicationInfo-root">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    border: 'solid 1px ' + theme.palette.neutralLighterAlt,
                    margin: '5px 0px',
                }}
            >
                <Stack
                    tokens={stackTokens}
                    style={{
                        width: '100%',
                    }}
                >
                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* Replication Id and Status */}
                        <Stack tokens={stackTokens}>
                            <Text variant="medium">
                                {props.replication.environmentId}
                            </Text>

                            <Stack horizontal tokens={{ childrenGap: 6 }}>
                                <CompletionIndicatorIcon
                                    completionIndicator={
                                        props.replication.completionIndicator
                                    }
                                    theme={theme}
                                    showText
                                />
                            </Stack>
                        </Stack>

                        <Stack
                            horizontal
                            tokens={{ childrenGap: 6 }}
                            style={{
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                iconProps={{ iconName: 'AirplaneSolid' }}
                                onClick={showPanel}
                            />
                        </Stack>
                    </Stack>

                    {/* Replication Details */}
                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text variant="small">
                            Source: {props.replication.sourceType}
                        </Text>
                    </Stack>
                </Stack>
                <MobileReplicationDetailsPanel
                    replication={props.replication}
                    isOpen={isPanelOpen}
                    dismissPanel={hidePanel}
                />
            </div>
        </div>
    );
};
