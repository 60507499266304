import React from 'react';
import { Replication } from '../../../models/Replication';
import { Panel, PanelType } from '@fluentui/react';
import { CurrentReplicationTimeline } from '../../MultistageReleaseData/Display/Status/CurrentReplicationTimeline';
import { getCurrentReplicationTimeline } from '../../../util/ReleaseUtils';

interface IMobileReplicationDetailsPanelProps {
    replication: Replication;
    isOpen: boolean;
    dismissPanel: () => void;
}

export const MobileReplicationDetailsPanel: React.FC<IMobileReplicationDetailsPanelProps> =
    (props: IMobileReplicationDetailsPanelProps) => {
        return (
            <div className="MobileReplicationDetailsPanel-root">
                <Panel
                    isLightDismiss
                    isOpen={props.isOpen}
                    onDismiss={props.dismissPanel}
                    closeButtonAriaLabel="Close"
                    headerText={'Replication Details'}
                    type={PanelType.custom}
                >
                    <CurrentReplicationTimeline
                        currentFlightTimeline={getCurrentReplicationTimeline(
                            props.replication
                        )}
                    />
                </Panel>
            </div>
        );
    };
