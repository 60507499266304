import React from 'react';
import { SiteHeader } from '../PageLayout/SiteHeader';
import { PageTitle } from '../PageLayout/PageTitle';
import { PageContent } from '../PageLayout/PageContent';
import { HashRouter } from 'react-router-dom';

interface IConfigErrorProps {
    error?: Error;
}

export const GlobalConfigErrorPage: React.FC<IConfigErrorProps> = (
    props: IConfigErrorProps
) => {
    var message =
        'There was an error getting the configuration from the server.  Please contact azure-bridge-web@microsoft.com to report ' +
        (props.error ? `: ${props.error?.message}` : '.');

    return (
        // need HashRouter wrapper since SiteHeader tries to use location from a router
        <HashRouter>
            <div className="Page-root">
                <SiteHeader showSearch={false} />

                <div className="Page-content">
                    <PageTitle title="Configuration Error" />

                    <PageContent>{message}</PageContent>
                </div>
            </div>
        </HashRouter>
    );
};
