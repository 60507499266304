import axios from 'axios';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { getConfig, GlobalConfigProperties } from '../ABHub/ABHubGlobalConfig';

export const graphAxiosClient = axios.create();

export function getGraphOidcConfig() {
    let graphOidcConfig: UserManagerSettings = {
        authority:
            getConfig(GlobalConfigProperties.AadUrl) +
            getConfig(GlobalConfigProperties.AadTenant),
        client_id: getConfig(GlobalConfigProperties.AadClient),
        scope: `${getConfig(GlobalConfigProperties.GraphUrl)}/User.Read`,
        extraQueryParams: {
            resource: getConfig(GlobalConfigProperties.GraphUrl),
        }, // set the audience for AAD

        response_type: 'code',
        automaticSilentRenew: true,
        monitorSession: false,
        loadUserInfo: false, // this is blocked by AAD, request profile scope instead
        redirect_uri: window.location.origin,
        post_logout_redirect_uri: window.location.origin,

        userStore: new WebStorageStateStore({
            prefix: 'graph.',
            store: localStorage,
        }), // add prefix to distinguish between multiple userManagers' state
    };
    return graphOidcConfig;
}
